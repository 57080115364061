import instance from "../../../api";
export const UserGuideService = {
  async getUserGuide(filters: Object) {
    return instance
      .get("todos/", { params: filters })
      .then((response) => response.data);
  },
  async markItAsComplete(payload: any, id: string) {
    return instance
      .patch(`todos/${id}/`, payload)
      .then((response) => response.data);
  },

  async getListOfTasks(filters: Object) {
    return instance
      .get("todos/todos-count/", { params: filters })
      .then((response) => response.data);
  },
};
