import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";
import InfoSlider from "../../../components/sliders/InfoSlider";
import { primary } from "../../../theme/themeColors";
import PasswordResetMessage from "./PasswordResetMessage";

export const ResetPasswordPage = () => {
  return (
    <WrapperMain id="login" className="flexSpaceCenter">
      <LeftSide className="redBg flexCenter flexColumn animate poka_pattern_dark">
        <div className="x100">
          <InfoSlider />
        </div>
      </LeftSide>
      <RightSide className="whiteBg">
        <PasswordResetMessage />
      </RightSide>
    </WrapperMain>
  );
};

const WrapperMain = styled.section`
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 60%;
  background-color: ${primary.main};
  gap: 20px;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
  }
`;

const RightSide = styled.div`
  width: 40%;
  height: 100%;
  padding: 5vh 5vw;
  overflow: auto;
  display: inline-grid;
  @media (max-width: 960px) {
    width: 100%;
    height: auto;
    order: 1;
    text-align: center;
    padding: 40px;
    overflow: inherit;
  }
`;
