import React from "react";
import { Box, ListItemButton, Typography, styled } from "@mui/material";
import { useState, useEffect } from "react";
import { Paragraph } from "../../components/Typography";
import { useDrawerContext } from "../../contexts/DrawerContext";
import { UserGuide } from "../../pages/UserGuide/UserGuide";
import { PERMISSIONS } from "../../constants/permissions";
import { UserGuideService } from "../../pages/UserGuide/services/UserGuide.Service";
import CircularProgressWithLabel from "../../pages/UserGuide/Circular";
import { IsGranted } from "../../guards/IsGranted";
import DrawerBox from "../../components/UI/DrawerBox";
import { isGranted } from "../../utils/is_granted";
import { IStylesType } from "../../components/Layouts/sidemenu/styles";
import useAuth from "../../hooks/useAuth";
import UserDailyOperations from "./UserDailyOperations";

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  marginBottom: "1rem",
  justifyContent: "flex-start",
  "&:hover": {
    backgroundColor: "transparent",
    "& .MuiTypography-body2": {
      color: "#ffd700",
    },
    "& .expand-arrow": {
      color: "#ffd700",
    },
  },
  "& .MuiTypography-body2": {
    color: "#fff",
    fontWeight: "bold",
  },
  "& .MuiSvgIcon-root": {
    color: "#fff",
    marginRight: theme.spacing(1),
  },
  "& .expand-arrow": {
    color: "#fff",
    marginLeft: "auto",
    marginRight: theme.spacing(1),
    transform: "rotate(90deg)",
  },
}));

const UserInfo = () => {
  const [taskListCount, setTaskListCount] = useState<number>(0);
  const [progressValue, setProgressValue] = useState<number>(0);
  const { showDrawer, setShowDrawer, setDrawerId } = useDrawerContext();
  const [totalTasks, setTotalTasks] = useState(0);
  const [taskStatusZero, setTaskStatusZero] = useState<{}[]>([]);
  const [taskStatusOne, setTaskStatusOne] = useState<{}[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const auth: any = useAuth();

  const handleOpenDrawer = (drawerIdInfo: string) => {
    setDrawerId(drawerIdInfo);
    setShowDrawer({ ...showDrawer, ...{ right: true } });
  };

  const closeDrawer = () => {
    setDrawerId(" ");
    setShowDrawer({ ...showDrawer, ...{ right: false } });
  };

  useEffect(() => {
    const getListOfTasks = async () => {
      try {
        if (isGranted(auth.user, [PERMISSIONS.VIEW_TODOS])) {
          const { count, total }: any = await UserGuideService.getListOfTasks({
            category: "set_up",
          });
          setTaskListCount(count);
          setTotalTasks(total);
        }
        return;
      } catch (error) {
        setTaskListCount(0);
      }
    };

    getListOfTasks();
  }, [auth.user]);

  useEffect(() => {
    const getAlistOfNonCompletedTodos = async () => {
      try {
        if (isGranted(auth.user, [PERMISSIONS.VIEW_TODOS])) {
          const { results }: any = await UserGuideService.getUserGuide({
            page_size: 50,
            status: 0,
            category: "set_up",
          });
          setTaskStatusZero(results);
        }
        return;
      } catch (error) {
        setTaskStatusZero([]);
      }
    };

    getAlistOfNonCompletedTodos();
  }, [auth.user]);

  useEffect(() => {
    const getAlistOfCompletedTodos = async () => {
      try {
        if (isGranted(auth.user, [PERMISSIONS.VIEW_TODOS])) {
          const { results }: any = await UserGuideService.getUserGuide({
            page_size: 50,
            status: 1,
            category: "set_up",
          });
          setTaskStatusOne(results);
        }
        return;
      } catch (error) {
        setTaskStatusOne([]);
      }
    };

    getAlistOfCompletedTodos();
  }, [auth.user]);

  useEffect(() => {
    setTaskListCount(taskStatusZero.length);
  }, [taskStatusZero.length, setTaskListCount]);

  useEffect(() => {
    const calculateProgressValue = (taskWithStatusOne: {}[]) => {
      const completedTaskProgress =
        (taskWithStatusOne.length / totalTasks) * 100;
      setProgress(completedTaskProgress);
    };
    calculateProgressValue(taskStatusOne);
  }, [totalTasks, taskStatusOne]);

  useEffect(() => {
    setProgressValue(progress);
  }, [progress, setProgressValue]);

  const referenceData = async () => {
    try {
      if (isGranted(auth.user, [PERMISSIONS.VIEW_TODOS])) {
        const { results }: any = await UserGuideService.getUserGuide({
          page_size: 50,
          status: 0,
          category: "set_up",
        });
        setTaskStatusZero(results);
      }
      return;
    } catch (error) {
      setTaskStatusZero([]);
    }
  };

  const refreshOnes = async () => {
    try {
      if (isGranted(auth.user, [PERMISSIONS.VIEW_TODOS])) {
        const { results }: any = await UserGuideService.getUserGuide({
          page_size: 50,
          status: 1,
          category: "set_up",
        });
        setTaskStatusOne(results);
      }
      return;
    } catch (error) {
      setTaskStatusOne([]);
    }
  };

  return (
    <Box>
      <IsGranted permissions={[PERMISSIONS.VIEW_TODOS]}>
        {taskListCount > 0 && (
          <ListItemButton
            sx={[
              styles.menuItemButton,
              {
                display: "flex",
                gap: "0.5rem",
                alignItems: "center",
                marginBottom: "0.5rem",
              },
            ]}
            onClick={() => handleOpenDrawer("user-guide-request")}
          >
            <CircularProgressWithLabel
              value={progressValue}
              text={taskListCount}
              color={"secondary"}
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography color={"#fff"} variant="body2">
                Quick Start
              </Typography>
              <Paragraph
                sx={{ color: "white", fontSize: "10px" }}
              >{`${taskListCount} Remaining Tasks`}</Paragraph>
            </Box>
          </ListItemButton>
        )}
      </IsGranted>
      <DrawerBox
        minWidth={370}
        maxWidth={370}
        selectedDrawerId="user-guide-request"
        anchor="right"
        onClose={() => {}}
      >
        <UserGuide
          closeDrawer={closeDrawer}
          title="User Guide Menu"
          referenceData={referenceData}
          taskStatusZero={taskStatusZero}
          taskStatusOne={taskStatusOne}
          progress={progress}
          refreshOnes={refreshOnes}
        />
      </DrawerBox>
    </Box>
  );
};

const styles: IStylesType = {
  menuListContainer: {
    width: "100%",
    overflowY: "auto",
  },
  menuItemButton: {
    gap: "0.5rem",
    borderRadius: "10px",
    backgroundColor: "#aeaeae29",
    "& .MuiListItemText-root > span": {
      color: "#fff !important",
    },
    "& .MuiListItemIcon-root .MuiSvgIcon-root": {
      color: "#fff !important",
    },
  },
  menuItemIcon: {
    minWidth: "0px",
    "&.MuiListItemIcon-root .MuiSvgIcon-root": {
      color: "#dfdfdf",
    },
  },
  menuItemText: {
    "& > span": {
      fontWeight: "500",
      color: "#dfdfdf",
    },
  },
};

export default UserInfo;
