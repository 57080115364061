import React from "react";
import "../../assets/css/CustomSpinner.css";
import ZimbarImage from "../../assets/images/zimba-z.png";

const CustomSpinner: React.FC = () => {
  return (
    <div className="loading-component">
      <span className="loader-liquid">
        <img
          src={ZimbarImage}
          alt="Description"
          style={{ width: "60px", height: "56px", marginLeft: "9px" }}
        />
      </span>
    </div>
  );
};

export default CustomSpinner;
