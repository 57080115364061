import { useState } from "react";
import FlexBox from "../../../FlexBox";
import { List, styled } from "@mui/material";
import MenuList, { IMenuItem } from "../../MenuList";
import { IsGranted } from "../../../../guards/IsGranted";
import { SideBarHeader } from "./SideBarHeader";
import { MenuItem } from "./MenuItem";
import { styles } from "../styles";

const ContentWrapper = styled(FlexBox)(() => ({
  width: "100%",
  height: "100%",
  padding: "10px",
  position: "relative",
  display: "flex",
  flexDirection: "column",
}));

const SideBarContent = () => {
  const [openCollapse, setOpenCollapse] = useState("");

  return (
    <ContentWrapper>
      <SideBarHeader />
      <List sx={styles.menuListContainer}>
        {MenuList.map((item: IMenuItem, index: number) => (
          <IsGranted
            key={`${item.title}-main-${index}`}
            permissions={item.permissions || []}
          >
            <MenuItem
              item={item}
              openMenu={openCollapse}
              setOpen={setOpenCollapse}
            />
          </IsGranted>
        ))}
      </List>
    </ContentWrapper>
  );
};

export default SideBarContent;
