import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { deepDerefrencer } from "../../../utils/form_factory";
import { Box, FormControl, FormLabel, Radio, RadioGroup } from "@mui/material";

interface IFormRadioBtnProps {
  name: string;
  label: any;
  type?: string;
  formControl?: any;
  isDisabled?: boolean;
  radioOptions?: Array<{ value: boolean; label: string }>;
}

const FormRadioBtn: React.FC<IFormRadioBtnProps> = (
  props: IFormRadioBtnProps,
) => {
  const [value, setValue] = React.useState(
    deepDerefrencer(props.formControl.values, props.name),
  );

  const handleRadioChange = ($event: any) => {
    props.formControl.setFieldValue(props.name, $event.target.value);
    setValue($event.target.value);
  };

  return (
    <FormControl>
      <FormLabel sx={styles.label} id="demo-row-radio-buttons-group-label">
        {props.label}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby={props.label}
        name={props.name}
        value={value}
        onChange={handleRadioChange}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {props.radioOptions?.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </Box>
      </RadioGroup>
    </FormControl>
  );
};

const styles = {
  label: {
    color: "#1c2437",
    fontWeight: 400,
  },
  hidden: {
    display: "none",
  },
};

export default FormRadioBtn;
