import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import SideBarContent from "./SideBarContent";
import UserInfo from "../../../../pages/UserGuide/UserInfo";
import { FC } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { USER_TYPES } from "../../../../constants/user-types";
import { styles } from "../styles";
import UserDailyOperations from "../../../../pages/UserGuide/UserDailyOperations";

const MobileSideBar: FC<{
  handleUserManual: () => void;
  closeMobileSideBar: any;
  showMobileSideBar: any;
  isRightUser: any;
}> = ({
  closeMobileSideBar,
  showMobileSideBar,
  handleUserManual,
  isRightUser,
}) => {
  return (
    <Drawer
      anchor="left"
      open={showMobileSideBar}
      onClose={closeMobileSideBar}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "300px",
          backgroundColor: "#44814E",
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#44814E",
        }}
      >
        <Box sx={{ height: "85vh" }}>
          <SideBarContent />
        </Box>

        <Box sx={{ padding: "0px 10px 10px 10px" }}>
          <List sx={styles.menuListContainer}>
            {!isRightUser([USER_TYPES.SYSTEM_USER]) && <UserInfo />}
            {!isRightUser([USER_TYPES.SYSTEM_USER]) && <UserDailyOperations />}

            {!isRightUser([USER_TYPES.SYSTEM_USER]) && (
              <ListItemButton
                onClick={handleUserManual}
                sx={styles.menuItemButton}
              >
                <ListItemIcon sx={styles.menuItemIcon}>
                  <HelpOutlineIcon />
                </ListItemIcon>
                <ListItemText
                  sx={styles.menuItemText}
                  primary={"User Manual"}
                />
              </ListItemButton>
            )}
          </List>
        </Box>
      </Box>
    </Drawer>
  );
};

export default MobileSideBar;
