import { Badge, IconButton } from "@mui/material";
import { FC, useEffect, useState } from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useDrawerContext } from "../../../contexts/DrawerContext";
import DrawerBox from "../../UI/DrawerBox";
import WorkflowTaskShort from "../../../pages/WorkflowTasks/WorkflowTaskShort";
import { WorkflowTaskService } from "../../../pages/WorkflowTasks/services/WorkflowTask.service";
import toast from "react-hot-toast";
import { poller } from "../../../utils/poller_wrapper";
import { useAuth } from "../../../hooks/useAuth";

const WorkflowTaskNotifications: FC = () => {
  const { user }: any = useAuth();
  const { showDrawer, setShowDrawer, setDrawerId } = useDrawerContext();
  const [taskCount, setTaskCount] = useState(0);

  useEffect(() => {
    const getTaskCount = async () => {
      try {
        const { count }: any = await WorkflowTaskService.getTaskCount({
          status: 0,
        });
        setTaskCount(count);
      } catch (error) {
        toast.error("Error while fetching task count");
      }
    };

    if (user.type && user.type !== "system-user") {
      poller(getTaskCount, 100000);
    }
  }, [user.type]);

  const handleOpenDrawer = () => {
    setDrawerId("workflow-tasks");
    setShowDrawer({ ...showDrawer, ...{ right: true } });
  };

  const closeDrawer = () => {
    setShowDrawer({ ...showDrawer, ...{ right: false } });
  };

  return (
    <>
      <IconButton onClick={handleOpenDrawer}>
        <Badge color="error" badgeContent={taskCount}>
          <TaskAltIcon fontSize="medium" sx={{ color: "text.disabled" }} />
        </Badge>
      </IconButton>

      <DrawerBox
        minWidth={500}
        selectedDrawerId="workflow-tasks"
        anchor="right"
        onClose={() => {}}
      >
        <WorkflowTaskShort closeDrawer={closeDrawer} />
      </DrawerBox>
    </>
  );
};

export default WorkflowTaskNotifications;
