import * as React from "react";
import { useTheme } from "@emotion/react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import moment, { Moment } from "moment";

import { deepDerefrencer } from "../../../utils/form_factory";
import { Small } from "../../Typography";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

interface IFormDateTimeInputProps {
  name: string;
  label: string;
  formControl: any;
  format?: string;
  maxDate?: Date;
  minDate?: Date;
  isDisabled?: boolean;
  isHidden?: boolean;
}

export const FormDateTimeInput: React.FC<IFormDateTimeInputProps> = ({
  name,
  label,
  formControl,
  format,
  maxDate,
  minDate,
}: IFormDateTimeInputProps) => {
  const theme = useTheme();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {
        <DateTimePicker
          sx={styles.customStyles(theme)}
          label={label}
          value={
            deepDerefrencer(formControl.values, name)
              ? moment(deepDerefrencer(formControl.values, name) || undefined)
              : null
          }
          maxDate={maxDate ? moment(maxDate) : undefined}
          minDate={minDate ? moment(minDate) : undefined}
          onChange={(date: Moment | null) =>
            formControl.setFieldValue(
              name,
              date?.format(format || "YYYY-MM-DDTHH:mm:ss") || null,
            )
          }
        />
      }

      {Boolean(
        deepDerefrencer(formControl.touched, name) &&
          deepDerefrencer(formControl.errors, name),
      ) && (
        <Small
          color="error.main"
          fontSize="0.75rem"
          fontWeight={"normal"}
          marginTop={1}
        >
          {deepDerefrencer(formControl.errors, name)}
        </Small>
      )}
    </LocalizationProvider>
  );
};

const styles = {
  customStyles: (theme: any) => ({
    width: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      border: "2px solid",
      borderColor:
        theme.palette.mode === "light"
          ? theme.palette.secondary[300]
          : theme.palette.divider,
    },

    "& input[type='file'].MuiInputBase-input": {
      marginLeft: "120px",
    },
  }),
};
