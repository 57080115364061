export const WORKFLOWTASKS_STATUS = [
  {
    name: "Pending",
    value: 0,
  },
  {
    name: "Approved",
    value: 2,
  },
  {
    name: "Declined",
    value: 3,
  },
  {
    name: "Closed",
    value: 4,
  },
];

export const T_TRANSACTION_REVERSAL = "transaction_reversal";
export const T_LOAN_WRITE_OFF = "loan_writeoff";
export const T_LOAN_DISBURSEMENT = "loan_disbursement";
export const T_LOAN_APPROVAL = "loan_approval";
export const T_SHARE_PURCHASE_REVERSAL = "share_purchase_reversal";
export const T_RECEIVABLE_REVERSAL = "receivable_reversal";
export const T_PAYABLE_REVERSAL = "payable_reversal";
export const T_LOAN_TOPUP = "loan_topup";

export const WORKFLOW_PROCESS = [
  {
    name: "Transaction Reversal",
    value: "transaction_reversal",
  },
  {
    name: "Loan Write Off",
    value: "loan_writeoff",
  },
  {
    name: "Loan Disbursement",
    value: "loan_disbursement",
  },
  {
    name: "Loan Approval",
    value: "loan_approval",
  },
  {
    name: "Loan Topup",
    value: "loan_topup",
  },
  {
    name: "Share Purchase Reversal",
    value: "share_purchase_reversal",
  },
  {
    name: "Receivable Reversal",
    value: "receivable_reversal",
  },
  {
    name: "Payable Reversal",
    value: "payable_reversal",
  },
];
