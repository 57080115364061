import React, {
  createContext,
  useContext,
  FC,
  useState,
  useEffect,
} from "react";
import { InstitutionService } from "../pages/Institution/services/Institution.service";
import { useAuth } from "../hooks/useAuth";
import InstitutionStore from "../store/institution.store";

export interface IInstitutionContext {
  business: any;
  setBusiness: (arg: any) => void;
}

export const InstitutionContext = createContext<
  IInstitutionContext | undefined
>(undefined);

export const useInstitutionContext = () => {
  const context = useContext(InstitutionContext);

  if (!context) {
    throw new Error(
      "useModalContext must be used within a Institution Context Provider",
    );
  }

  return context;
};

export const InstitutionProvider: FC<any> = ({ children }) => {
  const auth: any = useAuth();
  const [business, setBusiness] = useState<any>({});
  const Provider = InstitutionContext.Provider as any;

  useEffect(() => {
    const fetchInstitutionDetails = async () => {
      try {
        const results: any = await InstitutionService.getInstitutionDetails(
          auth?.user.extra_details.bank,
        );
        InstitutionStore.update(results);
      } catch (error) {
        InstitutionStore.update(null);
      }
    };

    if (auth?.user?.type !== "system-user") {
      fetchInstitutionDetails();
    }
  }, [auth]);

  return <Provider value={{ business, setBusiness }}>{children}</Provider>;
};
