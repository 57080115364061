export const NOTIFICATION_STATUS = [
  {
    name: "Unread",
    value: 3,
  },
  {
    name: "Read",
    value: 2,
  },
  {
    name: "All",
    value: 0,
  },
];

export const NOTIFICATION_SETTINGS = [
  {
    name: "System Notifications",
    value: "SYSTEM",
  },
  {
    name: "Email Notifications",
    value: "EMAIL",
  },
  {
    name: "SMS Notifications",
    value: "SMS",
  },
];
