import React, { useState } from "react";
import Typography from "@mui/material/Typography";

const TruncatedParagraph = ({ text, maxLines = 1 }: any) => {
  const [showFullText, setShowFullText] = useState(false);

  const handleClick = () => {
    setShowFullText(!showFullText);
  };

  return (
    <Typography
      onClick={handleClick}
      sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: showFullText ? "" : "-webkit-box",
        WebkitLineClamp: maxLines,
        WebkitBoxOrient: "vertical",
        cursor: "pointer",
        transition: "max-height 0.5s",
      }}
    >
      {text}
    </Typography>
  );
};

export default TruncatedParagraph;
