import { FC, useState, SyntheticEvent } from "react";
import { H1, H4, H6, Paragraph } from "../../components/Typography";
import { Box, Button, Tab, styled } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { UserGuideService } from "./services/UserGuide.Service";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { PERMISSIONS } from "../../constants/permissions";
import CircularProgressWithLabel from "./Circular";
import StatusOne from "./StatusOne";
import { StatusZero } from "./StatusZero";
import { useAuth } from "../../hooks/useAuth";
import { isGranted } from "../../utils/is_granted";
import { USER_TYPES } from "../../constants/user-types";

interface UserGuideDetailProps {
  closeDrawer: () => void;
  title: String;
  progress: number;
  taskStatusZero: {}[];
  taskStatusOne: {}[];
  referenceData: () => void;
  refreshOnes: () => Promise<void>;
}

const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: 13,
  color: theme.palette.text.primary,
}));

const StyledTabList = styled(TabList)(({ theme }) => ({
  [theme.breakpoints.down(780)]: {
    width: "100%",
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-around",
    },
    marginBottom: 20,
  },
  [theme.breakpoints.up("sm")]: {
    "& .MuiTabs-flexContainer": {
      minWidth: 200,
      justifyContent: "space-around",
    },
  },
}));

const StyledTabPanel = styled(TabPanel)(() => ({
  padding: 0,
}));

export const UserGuide: FC<UserGuideDetailProps> = ({
  title,
  closeDrawer,
  progress,
  taskStatusZero,
  taskStatusOne,
  referenceData,
  refreshOnes,
}) => {
  const navigate = useNavigate();
  const [tab, setTab] = useState<string>("tab1");
  const handleTabChange = (_: SyntheticEvent, newValue: string) =>
    setTab(newValue);
  const auth: any = useAuth();

  const navigateUrl = (url: string) => {
    navigate(`/${url}`);
    closeDrawer();
  };

  const MarkITAsComplete = async (values: any) => {
    const TodoTask = {
      url: values?.url,
      status: values?.status === 0 ? 1 : 0,
    };

    try {
      await UserGuideService.markItAsComplete(TodoTask, values?.id);
      toast.success("Task masked as complete sucessfully ");
      referenceData();
      refreshOnes();
    } catch (error: any) {
      toast.error("Failed to make a task as complete");
    }
  };

  return (
    <div>
      <TabContext value={tab}>
        <Box
          sx={{
            ...styles.UserGuideTitle,
            ...styles.guide,
            ...styles.displayFlex,
            position: "fixed",
            top: 0,
            width: "370px",
            zIndex: 1000,
            backgroundColor: "#fff",
          }}
        >
          <H4 fontWeight={"bold"}>{title}</H4>
          <Button
            onClick={closeDrawer}
            color="inherit"
            variant={"contained"}
            sx={{ ...styles.closeButton, marginLeft: "auto" }}
          >
            <CloseIcon />
          </Button>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "0.1fr 1fr",
            marginTop: "80px",
            gridGap: "1rem",
            marginLeft: "1rem",
            marginRight: "1rem",
          }}
        >
          <Box>
            <CircularProgressWithLabel
              value={progress}
              text={taskStatusZero.length}
              color={"primary"}
            />
          </Box>
          <Box sx={{}}>
            <H1 sx={{ fontSize: "16px", fontWeight: "bold" }}>Quick Start</H1>
            <Paragraph sx={{ fontSize: "11px" }}>
              Walk through the guide to get the most out of your Zimba core
              banking platform right away
            </Paragraph>
          </Box>
        </Box>
        <StyledTabList onChange={handleTabChange}>
          <StyledTab
            label="NEXT STEPS"
            value="tab1"
            disabled={
              !isGranted(
                auth.user,
                [PERMISSIONS.VIEW_TODOS],
                [USER_TYPES.INSTITUTION_ADMIN],
              )
            }
          />
          <StyledTab
            label="COMPLETED"
            value="tab2"
            disabled={
              !isGranted(
                auth.user,
                [PERMISSIONS.VIEW_TODOS],
                [USER_TYPES.INSTITUTION_ADMIN],
              )
            }
          />
        </StyledTabList>
        <Box marginTop={3} marginBottom={3}>
          <StyledTabPanel
            value="tab1"
            hidden={
              !isGranted(
                auth.user,
                [PERMISSIONS.VIEW_TODOS],
                [USER_TYPES.INSTITUTION_ADMIN],
              )
            }
          >
            <StatusZero
              taskStatusZero={taskStatusZero}
              navigateUrl={navigateUrl}
              MarkITAsComplete={MarkITAsComplete}
            />
          </StyledTabPanel>

          <StyledTabPanel
            value="tab2"
            hidden={
              !isGranted(
                auth.user,
                [PERMISSIONS.VIEW_TODOS],
                [USER_TYPES.INSTITUTION_ADMIN],
              )
            }
          >
            <StatusOne taskStatusOne={taskStatusOne} />
          </StyledTabPanel>
        </Box>
      </TabContext>
    </div>
  );
};

const styles = {
  displayFlex: {
    display: "flex",
    alignItems: "center",
  },
  UserGuideTitle: {
    justifyContent: "space-between",
  },
  guide: {
    padding: 2,
    borderBottom: "1px solid #e0e0e0",
  },

  closeButton: {
    borderRadius: "50%",
    padding: "0 !important",
    width: "2rem",
    height: "2rem",
    minWidth: "2rem",
    color: "black",
    backgroundColor: "white",
  },
};
