import React, { createContext, useContext, FC, useState } from "react";
import { Subscription } from "rxjs";

export interface IGlobalStoreContext {
  subscriptions: Subscription[];
  unsubscribeAll: () => void;
  addSubscription: (subscription: Subscription) => void;
}

export const GlobalStoreContext = createContext<
  IGlobalStoreContext | undefined
>(undefined);

export const useGlobalStoreContext = () => {
  const context = useContext(GlobalStoreContext);

  if (!context) {
    throw new Error(
      "useGlobalStore must be used within a ModalContextProvider",
    );
  }

  return context;
};

export const GlobalStoreProvider: FC<any> = ({ children }) => {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

  const unsubscribeAll = () => {
    subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    setSubscriptions([]);
  };

  const addSubscription = (subscription: Subscription) => {
    setSubscriptions([...subscriptions, subscription]);
  };

  const GlobalStoreContextProvider = GlobalStoreContext.Provider as any;
  return (
    <GlobalStoreContextProvider
      value={{
        subscriptions,
        setSubscriptions,
        unsubscribeAll,
        addSubscription,
      }}
    >
      {children}
    </GlobalStoreContextProvider>
  );
};
