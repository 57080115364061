import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FC } from "react";
import { IGroupMenu, IMenuItem } from "../../MenuList";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../hooks/useAuth";
import useInstitution from "../../../../hooks/useInstitution";
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material";
import { styles } from "../styles";
import { IsGranted } from "../../../../guards/IsGranted";
import { Span } from "../../../Typography";

export const MenuGroupItem: FC<{
  item: IGroupMenu;
  openMenu: string;
  setOpen: any;
}> = ({ openMenu, item, setOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user }: any = useAuth();
  const business: any = useInstitution();

  const toggleClick = (path: string) => {
    if (openMenu === path) {
      setOpen("");
    } else {
      setOpen(path);
    }
  };

  const isRightUser = (requiredUsers?: string[]) => {
    if (!requiredUsers) return true;
    return requiredUsers.includes(user?.type);
  };

  const handleClickLink = (menuItem: IMenuItem) => {
    navigate(menuItem.path);
  };

  const isActive = (path: string) => {
    return location?.pathname === path;
  };

  return (
    <>
      {item.isGroup ? (
        <>
          <ListItemButton
            sx={[
              styles.subMenuGroupButton,
              {
                display: isRightUser(item.users) ? "flex" : "none",
              },
            ]}
            onClick={() => toggleClick(item.path)}
          >
            <ListItemText sx={styles.menuItemText} primary={item.title} />
            {openMenu === item.path ? (
              <ExpandLessIcon sx={{ color: "#dfdfdf", fontSize: "16px" }} />
            ) : (
              <ExpandMoreIcon sx={{ color: "#dfdfdf", fontSize: "16px" }} />
            )}
          </ListItemButton>
          <Collapse
            sx={styles.groupCollapseContainer}
            in={openMenu === item.path}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {item.menuItems?.map((subItem: IGroupMenu, index: number) => (
                <IsGranted
                  key={`${subItem.title}sub-${index}`}
                  permissions={subItem?.permissions || []}
                  requiredUsers={subItem.users}
                >
                  <ListItemButton
                    className={isActive(subItem.path) ? "active" : ""}
                    sx={[
                      styles.groupItemButton,
                      {
                        display: isRightUser(item.users) ? "flex" : "none",
                      },
                    ]}
                    onClick={() => {
                      handleClickLink(subItem);
                    }}
                  >
                    <ListItemText
                      sx={styles.menuItemText}
                      primary={subItem.title}
                    />
                  </ListItemButton>
                </IsGranted>
              ))}
            </List>
          </Collapse>
        </>
      ) : (
        <ListItemButton
          className={isActive(item.path) ? "active" : ""}
          sx={[
            styles.submenuItemButton,
            { display: isRightUser(item.users) ? "flex" : "none" },
          ]}
          onClick={() => {
            handleClickLink(item);
          }}
        >
          <ListItemText sx={styles.menuItemText} primary={item.title} />
          {item.title === "Branches" &&
            !(business?.settings?.usage?.can_create_branches ?? true) && (
              <Span
                sx={{
                  marginLeft: "auto",
                  backgroundColor: "#FEC90D",
                  padding: "2px",
                  fontSize: "9px",
                  borderRadius: "3px",
                }}
              >
                Upgrade
              </Span>
            )}
        </ListItemButton>
      )}
    </>
  );
};
