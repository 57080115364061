import { Observer, Subject } from "rxjs";

const subject = new Subject();

const initialState = {
  logo: "/static/logo/zimba.png",
};

let state = initialState;

const LogoStore = {
  init: (logo: string) => subject.next({ ...state, logo }),
  subscribe: (setState: any) => subject.subscribe(setState),
  update: (payload: any) => {
    state = {
      ...state,
      ...payload,
    };
    subject.next(state);
  },
  initialState,
};

export default LogoStore;
