import React, { FC } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { deepDerefrencer } from "../../../utils/form_factory";
import uniqueId from "../../../utils/generateId";
import toast from "react-hot-toast";
import { debounce } from "lodash";

export interface FormTextAutoInputProps {
  name: string;
  label?: string;
  formControl: any;
  isDisabled?: boolean;
  isHidden?: boolean;
  options: any[];
}

const FormInputTextAutoCompletion: FC<FormTextAutoInputProps | any> = ({
  name,
  label,
  formControl,
  isDisabled,
  isHidden,
  options,
}) => {
  const handleTextChange = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target.value;
      if (/^\d+$/.test(input)) {
        toast.error("Attachment Name should be letters only.");
        return;
      }
      formControl.setFieldValue(name, input);
      formControl.handleChange({
        ...event,
        target: { ...event.target, value: input },
      });
    },
    1000,
  );

  const handleOptionChange = (
    event: React.ChangeEvent<{}>,
    value: string | null,
  ) => {
    formControl.setFieldValue(name, value || "");
  };

  return (
    <Autocomplete
      freeSolo
      options={options}
      getOptionLabel={(option) => option || ""}
      onChange={handleOptionChange}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          id={uniqueId()}
          label={label}
          variant="outlined"
          name={name}
          onChange={handleTextChange}
          value={deepDerefrencer(formControl.values, name)}
          error={Boolean(
            deepDerefrencer(formControl.touched, name) &&
              deepDerefrencer(formControl.errors, name),
          )}
          helperText={
            deepDerefrencer(formControl.touched, name) &&
            deepDerefrencer(formControl.errors, name)
          }
          onBlur={formControl.handleBlur}
          disabled={isDisabled}
          hidden={isHidden}
        />
      )}
    />
  );
};

export default FormInputTextAutoCompletion;
