import React, { FC } from "react";
import { H4, Paragraph } from "../../components/Typography";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { PERMISSIONS } from "../../constants/permissions";
import { IsGranted } from "../../guards/IsGranted";
import { useNavigate } from "react-router-dom";

interface StatusZeroInfo {
  taskStatusZero: any;
  navigateUrl: (url: string) => void;
  MarkITAsComplete: (values: any) => Promise<void>;
}
export const StatusZero: FC<StatusZeroInfo> = ({
  taskStatusZero,
  navigateUrl,
  MarkITAsComplete,
}) => {
  return (
    <Box sx={{ paddingBottom: "0.5rem" }}>
      {taskStatusZero.length > 0 ? (
        taskStatusZero.map((data: any) => {
          return (
            <Box
              sx={{
                border: "1px solid gainsboro",
                borderRadius: "10px",
                marginLeft: "25px",
                marginRight: "25px",
                marginBottom: "1rem",
              }}
              key={`zero${data?.id}`}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0.4rem 1rem 0rem 1rem",
                }}
              >
                <H4 sx={{ fontSize: "12", fontWeight: "bold" }}>
                  {data?.name}
                </H4>
                <IsGranted permissions={[PERMISSIONS.EDIT_TODOS]}>
                  <Tooltip
                    title="Click to Mark the Task as Complete"
                    arrow
                    placement="left"
                  >
                    <IconButton
                      color="primary"
                      aria-label="compelete"
                      onClick={() => MarkITAsComplete(data)}
                    >
                      <CheckCircleIcon fontSize="small" color="primary" />
                    </IconButton>
                  </Tooltip>
                </IsGranted>
              </Box>
              <Paragraph
                sx={{ padding: "0.1rem 1rem 0rem 1rem", fontSize: "11px" }}
              >
                {data?.description}
              </Paragraph>
              <Box
                sx={{
                  display: "flex",
                  padding: "0.4rem 1rem 0rem 1rem",
                  marginBottom: "1rem",
                }}
              >
                <Box></Box>
                <Button
                  variant="contained"
                  sx={{ marginLeft: "auto", padding: "3px" }}
                  onClick={() => navigateUrl(data?.url)}
                  size="small"
                >
                  Start
                </Button>
              </Box>
            </Box>
          );
        })
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {taskStatusZero.length === 0 && (
            <Paragraph sx={{ fontSize: "11px", marginLeft: "24px" }}>
              All Task are Complete
            </Paragraph>
          )}
        </Box>
      )}
    </Box>
  );
};
