import moment from "moment";

export const formatDateToDDMMYYYY = (date: string) => {
  if (!date) return "";
  const dateObj = new Date(date);
  const day = dateObj.getDate().toString().padStart(2, "0");
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObj.getFullYear().toString();
  return `${day}/${month}/${year}`;
};

export const formateDateToDDMMYYYYHHMM = (date: string) => {
  // note time is in 12 hour format with AM/PM
  if (!date) return "";
  const dateObj = new Date(date);
  const day = dateObj.getDate().toString().padStart(2, "0");
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObj.getFullYear().toString();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12;
  const hoursStr = hours12.toString().padStart(2, "0");
  const minutesStr = minutes.toString().padStart(2, "0");
  return `${day}/${month}/${year} ${hoursStr}:${minutesStr} ${ampm}`;
};

export const last7DaysDateRange = () => {
  const today = new Date();
  const last7Days = new Date(today.setDate(today.getDate() - 7));
  return [
    moment(last7Days).format("YYYY-MM-DD"),
    moment(new Date()).format("YYYY-MM-DD"),
  ];
};

export const last30DaysDateRange = () => {
  const today = new Date();
  const last30Days = new Date(today.setDate(today.getDate() - 30));
  return [
    moment(last30Days).format("YYYY-MM-DD"),
    moment(new Date()).format("YYYY-MM-DD"),
  ];
};

export const thisYearDateRange = () => {
  const today = new Date();
  const thisYear = new Date(today.getFullYear(), 0, 1);
  return [
    moment(thisYear).format("YYYY-MM-DD"),
    moment(new Date()).format("YYYY-MM-DD"),
  ];
};

export const lastYearDateRange = () => {
  const today = new Date();
  const lastYear = new Date(today.getFullYear() - 1, 0, 1);
  const lastYearEnd = new Date(today.getFullYear() - 1, 11, 31);
  return [
    moment(lastYear).format("YYYY-MM-DD"),
    moment(lastYearEnd).format("YYYY-MM-DD"),
  ];
};

export const todayDateRange = () => {
  const today = new Date();
  return [
    moment(today).format("YYYY-MM-DD"),
    moment(new Date()).format("YYYY-MM-DD"),
  ];
};

export const allTime = () => {
  return null;
};

export const getYearFromDate = (date: string) => {
  const dateObj = new Date(date);
  return dateObj.getFullYear();
};

export const formateDateToDDMMYYYYHHMMSS = (date: string) => {
  // note time is in 12 hour format with AM/PM
  if (!date) return "";
  const dateObj = new Date(date);
  const day = dateObj.getDate().toString().padStart(2, "0");
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObj.getFullYear().toString();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const seconds = dateObj.getSeconds();
  const ampm = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12;
  const hoursStr = hours12.toString().padStart(2, "0");
  const minutesStr = minutes.toString().padStart(2, "0");
  const secondsStr = seconds.toString().padStart(2, "0");
  return `${day}/${month}/${year} ${hoursStr}:${minutesStr}:${secondsStr} ${ampm}`;
};
