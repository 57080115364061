import React, { FC, useEffect, useState } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { Box, Button, Card, Stack } from "@mui/material";
import { useFormik } from "formik";
import { primary, success } from "../../../theme/themeColors";
import FlexBox from "../../../components/FlexBox";
import LightTextField from "../../../components/LightTextField";
import { H1, Small, Paragraph } from "../../../components/Typography";
import { PasswordResetService } from "./services/PasswordReset.service";
import { LoadingButton } from "@mui/lab";
import { ValidationSchema } from "./models/Validations";
import { AlertMessage } from "../../../components/UI/AlertMessage";
import { toast } from "react-hot-toast";
import ProgressIndicator from "../../../components/UI/ProgressIndicator";

const ResetPassword: FC = () => {
  const navigate = useNavigate();

  // read the token from the url query string from location.search
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");
  const email = new URLSearchParams(location.search).get("email");

  const [loading, setLoading] = useState(false);
  const [validating, setValidating] = useState(false);
  const [nonFieldErrors, setNonFieldErrors] = useState<any[]>([]);
  const [tokenValidated, setTokenValidated] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    const validatePasswordToken = async (token: string) => {
      try {
        setValidating(true);
        const response: any =
          await PasswordResetService.validatePasswordResetToken({
            token,
          });
        setTokenValidated(true);
        setValidating(false);
      } catch (error: any) {
        setValidating(false);
        setNonFieldErrors(["Token is invalid or has expired"]);
        setTokenValidated(false);
      }
    };

    if (token) {
      validatePasswordToken(token);
    } else {
      setNonFieldErrors(["Token not provided"]);
      setTokenValidated(false);
    }
  }, [token]);

  const initialValues = {
    newPassword: "",
    confirmPassword: "",
    submit: null,
  };

  const resetPasswordForm = useFormik({
    initialValues,
    validationSchema: ValidationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: async (values: any) => {
      handleFormSubmit(values);
    },
  });

  const handleFormSubmit = async (values: any) => {
    try {
      setLoading(true);
      setNonFieldErrors([]);
      await PasswordResetService.confirmPasswordReset({
        token,
        password: values.newPassword,
      });
      setLoading(false);
      toast.success("Password reset successfully");

      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error: any) {
      setLoading(false);
      const { non_field_errors, ...errors } = error.response.data;

      if (non_field_errors) {
        setNonFieldErrors(non_field_errors);
      }

      if (errors) {
        resetPasswordForm.setErrors(errors);
      }
    }
  };

  const handleRequestNewPasswordLink = async () => {
    try {
      setLoading(true);
      setNonFieldErrors([]);
      await PasswordResetService.passwordReset({ email });
      setLoading(false);
      setSuccessMessage(
        "Password reset link sent successfully, please check your email.",
      );
    } catch (error: any) {
      setLoading(false);
      const { non_field_errors } = error.response.data;

      if (non_field_errors) {
        setNonFieldErrors(non_field_errors);
      }
    }
  };

  const handleGoToForgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <FlexBox
      data-aos="fade-up"
      data-aos-duration="800"
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: { sm: "100%" },
      }}
    >
      <Card sx={{ padding: 4, maxWidth: 600, marginTop: 4, boxShadow: 1 }}>
        <Box sx={{ padding: 2 }}>
          <Stack spacing={1}>
            <H1 fontSize={28} fontWeight={800} mb={3}>
              <span style={{ color: primary.main }}>New</span> Password
            </H1>
            <Paragraph pb={3} fontSize={12}>
              Enter your new password and confirm it to reset your password.
            </Paragraph>
          </Stack>

          {nonFieldErrors.length > 0 && (
            <Box>
              <AlertMessage
                isOpen={nonFieldErrors.length > 0}
                message={
                  <ul style={{ padding: 0, margin: 0 }}>
                    {nonFieldErrors.map((error: string, index: number) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                }
                type="error"
                closeAlert={() => setNonFieldErrors([])}
              />
            </Box>
          )}

          {successMessage && (
            <Box>
              <AlertMessage
                isOpen={successMessage !== null}
                message={successMessage}
                type="success"
                closeAlert={() => setNonFieldErrors([])}
              />
            </Box>
          )}

          {tokenValidated && !validating && (
            <FlexBox justifyContent="space-between" flexWrap="wrap" my={2}>
              <form
                noValidate
                onSubmit={resetPasswordForm.handleSubmit}
                style={{ width: "100%" }}
              >
                <LightTextField
                  fullWidth
                  name="newPassword"
                  type="password"
                  label="New Password"
                  onBlur={resetPasswordForm.handleBlur}
                  onChange={resetPasswordForm.handleChange}
                  value={resetPasswordForm.values.newPassword || ""}
                  error={Boolean(
                    resetPasswordForm.touched.newPassword &&
                      resetPasswordForm.errors.newPassword,
                  )}
                  helperText={
                    resetPasswordForm.touched.newPassword &&
                    resetPasswordForm.errors.newPassword
                  }
                />

                <LightTextField
                  fullWidth
                  name="confirmPassword"
                  type="password"
                  label="Confirm Password"
                  onBlur={resetPasswordForm.handleBlur}
                  onChange={resetPasswordForm.handleChange}
                  value={resetPasswordForm.values.confirmPassword || ""}
                  error={Boolean(
                    resetPasswordForm.touched.confirmPassword &&
                      resetPasswordForm.errors.confirmPassword,
                  )}
                  helperText={
                    resetPasswordForm.touched.confirmPassword &&
                    resetPasswordForm.errors.confirmPassword
                  }
                  style={{ marginTop: "16px" }}
                />

                <Box sx={{ mt: 4 }}>
                  {loading ? (
                    <LoadingButton loading fullWidth variant="contained">
                      Reset Password
                    </LoadingButton>
                  ) : (
                    <Button
                      disabled={!resetPasswordForm.isValid}
                      fullWidth
                      type="submit"
                      variant="contained"
                    >
                      Reset Password
                    </Button>
                  )}
                </Box>
              </form>
            </FlexBox>
          )}

          {!tokenValidated && !validating && email && (
            <Box sx={{ mt: 2 }}>
              <Button
                onClick={handleRequestNewPasswordLink}
                fullWidth
                type="submit"
                variant="contained"
              >
                {loading ? (
                  <ProgressIndicator
                    color="inherit"
                    sx={{ color: "white !important" }}
                    size={20}
                  />
                ) : (
                  "Request New Password Link"
                )}
              </Button>
            </Box>
          )}

          {!tokenValidated && !validating && !email && (
            <Box sx={{ mt: 2 }}>
              <Button
                onClick={handleGoToForgotPassword}
                fullWidth
                type="submit"
                variant="contained"
              >
                Go to Forgot Password
              </Button>
            </Box>
          )}
        </Box>
      </Card>
    </FlexBox>
  );
};

export default ResetPassword;
