import { Paper, LinearProgress, styled } from "@mui/material";
import { FC } from "react";

const StyledPaper = styled(Paper)(() => ({
  position: "relative",
}));

interface IProgressPaperProps {
  loading?: boolean;
  elevation?: number;
  extraStyles?: any;
  children: any;
}

const ProgressPaper: FC<IProgressPaperProps> = ({
  children,
  loading,
  elevation = 0,
  extraStyles = {},
}) => {
  return (
    <StyledPaper sx={{ ...extraStyles }} elevation={elevation}>
      {loading && <LinearProgress />}
      {children}
      {loading && <FaintLayer />}
    </StyledPaper>
  );
};

const FaintLayer = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 100,

  backgroundColor: theme.palette.background.default,
  opacity: 0.5,
}));

export default ProgressPaper;
