import { FC } from "react";
import TransactionReversal from "./TransactionReversal";
import LoanWriteOff from "./LoanWriteOff";
import LoanDisbursement from "./LoanDisbursement";
import LoanApproval from "./LoanApproval";
import SharePurchaseReversal from "./SharePurchase";
import ReceivableReversal from "./ReceivableReversal";
import PayableReversal from "./PayableReversal";
import LoanTopup from "./LoanTopup";

interface ITaskDetailsInfo {
  entityType: string;
  entityDetails: any;
  extraData?: any;
}

const TaskDetailsInfo: FC<ITaskDetailsInfo> = ({
  entityDetails,
  entityType,
  extraData,
}) => {
  switch (entityType) {
    case "transaction_reversal":
      return <TransactionReversal entityDetails={entityDetails} />;
    case "loan_writeoff":
      return <LoanWriteOff entityDetails={entityDetails} />;
    case "loan_disbursement":
      return <LoanDisbursement entityDetails={entityDetails} />;
    case "loan_approval":
      return <LoanApproval entityDetails={entityDetails} />;
    case "loan_topup":
      return <LoanTopup entityDetails={entityDetails} extraData={extraData} />;
    case "share_purchase_reversal":
      return <SharePurchaseReversal entityDetails={entityDetails} />;
    case "receivable_reversal":
      return <ReceivableReversal entityDetails={entityDetails} />;
    case "payable_reversal":
      return <PayableReversal entityDetails={entityDetails} />;
    default:
      return <></>;
  }
};

export default TaskDetailsInfo;
