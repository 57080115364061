import { Badge, Box, ButtonBase, Divider, styled } from "@mui/material";
import { FC, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import FlexBox from "../../FlexBox";
import { H6, Small, Tiny } from "../../Typography";
import UkoAvatar from "../../UkoAvatar";
import PopoverLayout from "./PopoverLayout";
import UserProfileStore from "../../../store/user-profile.store";

const StyledSmall = styled(Small)(({ theme }) => ({
  display: "block",
  padding: "5px 1rem",
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.secondary.light
        : theme.palette.divider,
  },
}));

const ProfilePopover: FC = () => {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { logout, user }: any = useAuth();
  const [open, setOpen] = useState(false);

  const [photoState, setPhotoState] = useState(UserProfileStore.initialState);

  useLayoutEffect(() => {
    const subscription = UserProfileStore.subscribe(setPhotoState);
    UserProfileStore.init(user?.profilephoto);

    return () => {
      subscription.unsubscribe();
    };
  }, [user?.profilephoto]);

  const handleMenuItem = (path: string) => {
    navigate(path);
    setOpen(false);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <ButtonBase disableRipple ref={anchorRef} onClick={() => setOpen(true)}>
        <Badge
          overlap="circular"
          variant="dot"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          sx={{
            "& .MuiBadge-badge": {
              width: 11,
              height: 11,
              right: "7%",
              borderRadius: "50%",
              border: "2px solid #fff",
              backgroundColor: "success.main",
            },
          }}
        >
          <UkoAvatar
            src={photoState.photo}
            sx={{ width: 30, height: 30, ml: 1 }}
          />
        </Badge>
      </ButtonBase>

      <PopoverLayout
        hiddenViewButton
        maxWidth={230}
        minWidth={200}
        popoverOpen={open}
        anchorRef={anchorRef}
        popoverClose={() => setOpen(false)}
        title={
          <FlexBox alignItems="center">
            <UkoAvatar src={photoState.photo} sx={{ width: 35, height: 35 }} />

            <Box ml={1}>
              <H6>
                {user?.first_name} {user?.last_name}
              </H6>
              <Tiny display="block" fontWeight={500} color="text.disabled">
                {user?.email}
              </Tiny>
            </Box>
          </FlexBox>
        }
      >
        <Box pt={1}>
          <StyledSmall onClick={() => handleMenuItem("/account/profile")}>
            Profile & Account
          </StyledSmall>

          <Divider sx={{ my: 1 }} />

          <StyledSmall
            onClick={() => {
              handleLogout();
            }}
          >
            Sign Out
          </StyledSmall>
        </Box>
      </PopoverLayout>
    </>
  );
};

export default ProfilePopover;
