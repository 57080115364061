import "nprogress/nprogress.css";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "react-image-lightbox/style.css";
import "@fontsource/inter";

import { Provider } from "react-redux";

import { BrowserRouter } from "react-router-dom";
import "simplebar/dist/simplebar.min.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import App from "./App";

import "./assets/css/index.css";
import { AuthProvider } from "./contexts/JWTAuthContext";
import SettingsProvider from "./contexts/SettingsContext";
import TitleContextProvider from "./contexts/TitleContext";
import { InstitutionProvider } from "./contexts/InstitutionContext";
import { GlobalStoreProvider } from "./contexts/GlobalStoreContext";

import * as Sentry from "@sentry/react";
import { store } from "./store/store";

async function enableMocking() {
  if (process.env.NODE_ENV !== "test") {
    return;
  }

  const { worker } = await import("./__tests__/mocks/server");
  return worker.start();
}

const trackURLs: string[] =
  process.env.REACT_APP_SENTRY_TRACKING_URLS?.split(",") || [];

process.env.NODE_ENV === "production" &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: trackURLs,
      }),
      new Sentry.Replay({
        networkDetailAllowUrls: [""],
        networkRequestHeaders: ["X-Custom-Header"],
        networkResponseHeaders: ["X-Custom-Header"],
      }),
    ],

    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

const container: any = document.getElementById("root");
const root = createRoot(container);

enableMocking().then(() => {
  root.render(
    <StrictMode>
      <AuthProvider>
        <GlobalStoreProvider>
          <SettingsProvider>
            <TitleContextProvider>
              <InstitutionProvider>
                <BrowserRouter>
                  <Provider store={store}>
                    <App />
                  </Provider>
                </BrowserRouter>
              </InstitutionProvider>
            </TitleContextProvider>
          </SettingsProvider>
        </GlobalStoreProvider>
      </AuthProvider>
    </StrictMode>,
  );
});
