import { IFormField } from "../../../utils/form_factory";

export const WorkflowTaskFormFields = (status: string): IFormField[] => [
  {
    name: "status",
    initailValue: status,
    label: "Status",
    type: "text",
    uiBreakpoints: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    uiType: "text",
    initailValueReadPath: "status",
    isHidden: true,
  },
  {
    name: "reason",
    initailValue: "",
    label: "Reason",
    type: "textarea",
    uiBreakpoints: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    uiType: "text",
    initailValueReadPath: "reason",
    multiline: true,
    rows: 4,
  },
];
