import { Box, Button } from "@mui/material";
import { Span } from "../../components/Typography";
import { patchInitialValues } from "../../utils/form_factory";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import FormFactory from "../../components/UI/FormFactory";
import ProgressIndicator from "../../components/UI/ProgressIndicator";
import toast from "react-hot-toast";
import { WorkflowTaskFormValidations } from "./models/Validations";
import { AlertMessage } from "../../components/UI/AlertMessage";
import { WorkflowTaskFormFields } from "./models/WorkflowTaskFormFields";
import { WorkflowTaskService } from "./services/WorkflowTask.service";

interface IWorkflowTaskFormProps {
  taskId: string;
  taskStatus: string;
  onClose?: () => void;
  handleRefreshData?: () => void;
  handleCancel: () => void;
  setOpenSnack: (status: boolean) => void;
  setSnackMessage: (payload: any) => void;
}

const WorkflowTaskForm: FC<IWorkflowTaskFormProps> = ({
  onClose,
  handleRefreshData,
  taskId,
  taskStatus,
  handleCancel,
  setOpenSnack,
  setSnackMessage,
}) => {
  const [updatingTask, setUpdatingTask] = useState<boolean>(false);
  const [nonFieldErrors, setNonFieldErrors] = useState<any[]>([]);

  const levelFormFields = WorkflowTaskFormFields(taskStatus);
  const levelForm = useFormik({
    initialValues: {},
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: WorkflowTaskFormValidations,
    onSubmit: (values) => {
      handleSubmit({
        ...values,
      });
    },
  });

  useEffect(() => {
    if (taskStatus && taskId) {
      levelForm.setValues({
        ...patchInitialValues(levelFormFields)({
          status: taskStatus,
        }),
      });
    }
  }, [taskStatus, taskId]);

  const handleSubmit = async (form: any) => {
    try {
      setUpdatingTask(true);
      const response = await WorkflowTaskService.updateTask(form, taskId);
      setUpdatingTask(false);
      onClose && onClose();
      handleCancel && handleCancel();
      handleRefreshData && handleRefreshData();
      toast.success(`Task response has been submitted successfully`);

      // set snack message
      setSnackMessage(response);
      setOpenSnack(true);
    } catch (error: any) {
      setUpdatingTask(false);
      toast.error(`Error occurred while submitting task response`);

      const { non_field_errors, ...errors } = error.response.data;
      if (non_field_errors) {
        setNonFieldErrors(non_field_errors.length > 0 ? non_field_errors : []);
      }
      levelForm.setErrors(errors);
    }
  };

  return (
    <Box sx={{ display: "block" }}>
      <Box sx={{ minWidth: 320, maxHeight: 400, overflowY: "auto" }}>
        {nonFieldErrors.length > 0 && (
          <Box>
            <AlertMessage
              isOpen={nonFieldErrors.length > 0}
              message={
                <ul style={{ padding: 0, margin: 0 }}>
                  {nonFieldErrors.map((error: string, index: number) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              }
              type="error"
              closeAlert={() => setNonFieldErrors([])}
            />
          </Box>
        )}
        <form onSubmit={levelForm?.handleSubmit}>
          <Box sx={{ width: "100%", paddingY: 2 }}>
            <FormFactory
              others={{ sx: { marginBottom: "0rem" } }}
              formikInstance={levelForm}
              formFields={levelFormFields}
              validationSchema={WorkflowTaskFormValidations}
            />
          </Box>
        </form>
      </Box>

      <Box
        sx={{ paddingBottom: 2, display: "flex", gap: 2, alignItems: "center" }}
      >
        <Button
          onClick={handleCancel}
          color="error"
          size="small"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          disabled={!levelForm.isValid || updatingTask}
          onClick={levelForm.submitForm}
          color="primary"
          size="small"
          variant="contained"
        >
          {updatingTask ? (
            <>
              <ProgressIndicator color="inherit" size={20} />
              <Span style={{ marginLeft: "0.5rem" }} color="primary">
                Loading...
              </Span>
            </>
          ) : (
            `Submit`
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default WorkflowTaskForm;
