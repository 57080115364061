import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  styled,
  useMediaQuery,
} from "@mui/material";
import { FC } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import UserInfo from "../../../pages/UserGuide/UserInfo";
import { styles } from "./styles";
import MobileSideBar from "./components/MobileSideBar";
import SideBarContent from "./components/SideBarContent";
import { useAuth } from "../../../hooks/useAuth";
import { USER_TYPES } from "../../../constants/user-types";
import Icons from "../../../icons/sidebar";
import UserDailyOperations from "../../../pages/UserGuide/UserDailyOperations";

interface SideNavBarProps {
  showMobileSideBar: boolean;
  closeMobileSideBar: () => void;
}

const MainMenu = styled(Box)(({ theme }) => ({
  width: "20%",
  height: "100%",
  position: "fixed",
  boxShadow: theme.shadows[2],
  transition: "width 0.3s ease",
  zIndex: theme.zIndex.drawer + 11,
  backgroundColor: "#44814E",
  [theme.breakpoints.down("md")]: { width: 0 },
  "& .simplebar-track.simplebar-vertical": { width: 7 },
  "& .simplebar-scrollbar:before": {
    background: "#fff",
  },
}));

const DashboardSideBar: FC<SideNavBarProps> = ({
  showMobileSideBar,
  closeMobileSideBar,
}) => {
  const { user }: any = useAuth();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const isRightUser = (requiredUsers?: string[]) => {
    if (!requiredUsers) return true;
    return requiredUsers.includes(user?.type);
  };

  const handleUserManual = () => {
    window.open(
      "https://zimba.gitbook.io/user-manual/",
      "_blank",
      "noreferrer",
    );
  };

  if (downMd) {
    return (
      <MobileSideBar
        handleUserManual={handleUserManual}
        showMobileSideBar={showMobileSideBar}
        closeMobileSideBar={closeMobileSideBar}
        isRightUser={isRightUser}
      />
    );
  }

  return (
    <Drawer
      anchor={"left"}
      open={showMobileSideBar}
      onClose={closeMobileSideBar}
      variant={downMd ? "temporary" : "permanent"}
    >
      <MainMenu
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100vh",
          marginBottom: "20px",
        }}
      >
        <Box sx={{ height: "80vh" }}>
          <SideBarContent />
        </Box>

        <Box sx={{ padding: "0px 10px 10px 10px", height: "20vh" }}>
          <List
            sx={{
              ...styles.menuListContainer,
              height: "20vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {!isRightUser([USER_TYPES.SYSTEM_USER]) && <UserInfo />}
            {!isRightUser([USER_TYPES.SYSTEM_USER]) && <UserDailyOperations />}

            {!isRightUser([USER_TYPES.SYSTEM_USER]) && (
              <ListItemButton
                onClick={handleUserManual}
                sx={{
                  ...styles.menuItemButton,
                  flexGrow: 0,
                  marginTop: "auto",
                }}
              >
                <ListItemIcon sx={styles.menuItemIcon}>
                  <HelpOutlineIcon />
                </ListItemIcon>
                <ListItemText
                  sx={styles.menuItemText}
                  primary={"User Manual"}
                />
              </ListItemButton>
            )}
          </List>
        </Box>
      </MainMenu>
    </Drawer>
  );
};

export default DashboardSideBar;
