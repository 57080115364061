import { FC, ReactNode, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: any = ({ children }: AuthGuardProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isAuthenticated, user }: any = useAuth();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (user?.initial_password) {
      navigate("/change-password");
    }
  }, [user?.initial_password]);

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return pathname !== "/login" && <Navigate to="/login" />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
