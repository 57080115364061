import { configureStore } from "@reduxjs/toolkit";
import clientPaginationReducer from "../pages/Clients/slices/PaginationSlice";
import loanPaginationReducer from "../pages/Loans/slices/PaginationSlice";
import transactionPaginationReducer from "../pages/Savings/Transactions/slices/PaginationSlice";

export const store = configureStore({
  reducer: {
    ClientPagination: clientPaginationReducer,
    LoanPagination: loanPaginationReducer,
    TransactionPagination: transactionPaginationReducer,
  },
});
