import { createContext, ReactNode, useState } from "react";

export const TitleContext = createContext({
  title: "",
  setTitle: (arg: string) => {},
});

// props types for provider
type ProviderProps = {
  children: ReactNode;
};

const TitleContextProvider = ({ children }: ProviderProps) => {
  const [title, setTitle] = useState("");

  const Provider = TitleContext.Provider as any;
  return <Provider value={{ title, setTitle }}>{children}</Provider>;
};

export default TitleContextProvider;
