// utility function to poll a function after a given interval until it returns true
export const poller = async (
  fn: () => Promise<any>,
  interval: number,
): Promise<void> => {
  if (await fn()) {
    return;
  }

  while (true) {
    await new Promise((resolve) => {
      const timeoutId = setTimeout(resolve, interval);

      // push the time ids into an array in sessionStorage
      // so that we can clear them later
      const timeoutIds = sessionStorage.getItem("timeoutIds");
      if (timeoutIds) {
        sessionStorage.setItem(
          "timeoutIds",
          JSON.stringify([...JSON.parse(timeoutIds), timeoutId]),
        );
      } else {
        sessionStorage.setItem("timeoutIds", JSON.stringify([timeoutId]));
      }

      return timeoutId;
    });

    if (await fn()) {
      return;
    }
  }
};
