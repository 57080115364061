import { FC, Fragment, useEffect, useState } from "react";
import moment from "moment";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Snackbar,
  Tooltip,
} from "@mui/material";
import ProgressPaper from "../../components/UI/ProgressPaper";
import { H4, H6, Paragraph, Small, Span } from "../../components/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { WorkflowTaskService } from "./services/WorkflowTask.service";
import toast from "react-hot-toast";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import FilterListIcon from "@mui/icons-material/FilterList";
import StatusBtn from "../../components/UI/StatusBtn";
import CheckIcon from "@mui/icons-material/Check";
import WorkflowTaskForm from "./WorkflowTaskForm";
import { WORKFLOWTASKS_STATUS } from "../../constants/workflows";
import TaskDetailsInfo from "./TaskDetailsInfo";
import { ukoTheme } from "../../theme";

interface IWorkflowTaskShortProps {
  closeDrawer: () => void;
}

const statusColorMap = ["0", "2", "1", "6", "4", "5", "3"];

const WorkflowTaskShort: FC<IWorkflowTaskShortProps> = ({ closeDrawer }) => {
  const theme = ukoTheme();

  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tasks, setTasks] = useState<any[]>([]);
  const [taskTab, setTaskTab] = useState<number>(0);
  const [selectTask, setSelectTask] = useState<any>(null);
  const [selectedAction, setSelectedAction] = useState<any>(null);
  const [selectEntityDetails, setSelectEntityDetails] = useState<any>(null);

  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState<any>(undefined);

  useEffect(() => {
    const getTasks = async () => {
      try {
        setLoading(true);
        const { results }: any = await WorkflowTaskService.getTasks({
          detail: 1,
          status: taskTab,
        });
        setTasks(results);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Failed to fetch tasks");
      }
    };
    getTasks();
  }, [taskTab]);

  const handleRefreshData = async () => {
    try {
      setLoading(true);
      const { results }: any = await WorkflowTaskService.getTasks({
        detail: 1,
        status: taskTab,
      });
      setTasks(results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to fetch tasks");
    }
  };

  const handleAction = (status: any, taskId: any) => {
    setSelectTask(taskId);
    setSelectedAction(status);
  };

  const handleCloseAction = () => {
    setSelectTask(null);
    setSelectedAction(null);
  };

  const handleEntityDetails = (request: any, type: string) => {
    setShowDetails(true);
    setSelectEntityDetails({
      entity: request?.entity,
      extras: request?.extras,
      entityType: type,
    });
  };

  const handleCloseDetails = () => {
    setShowDetails(false);
    setSelectEntityDetails(null);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  return (
    <Box sx={{ height: "100%", display: "flex", overflowX: "hidden" }}>
      <Box
        sx={{
          width: 600,
          height: "100%",
          borderRight: "1px solid #e0e0e0",
          "&": {
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          },
        }}
      >
        <ProgressPaper extraStyles={{ height: "100%" }} loading={loading}>
          <Box
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: theme.palette.background.paper,
              zIndex: 100,
            }}
          >
            <Box sx={{ ...styles.workflow, ...styles.displayFlex }}>
              <H4 fontWeight={"bold"}>Tasks</H4>
              <Button
                onClick={closeDrawer}
                color="inherit"
                variant={"contained"}
                sx={{ ...styles.closeButton, marginLeft: "auto" }}
              >
                <CloseIcon />
              </Button>
            </Box>
            <Box
              sx={{
                ...styles.workflow,
                ...styles.displayFlex,
                gap: 1.5,
                "&": {
                  [theme.breakpoints.down("sm")]: {
                    overflowX: "auto",
                  },
                },
              }}
            >
              {WORKFLOWTASKS_STATUS.map((status, index) => (
                <Chip
                  key={index}
                  color={taskTab === status.value ? "primary" : "default"}
                  label={status.name}
                  onClick={() => {
                    setTaskTab(status.value);
                  }}
                />
              ))}

              <Tooltip arrow={true} placement="top" title="Task Filters">
                <Button
                  onClick={() => {}}
                  color="inherit"
                  variant={"contained"}
                  sx={{ ...styles.closeButton, marginLeft: "auto" }}
                >
                  <FilterListIcon />
                </Button>
              </Tooltip>
            </Box>
          </Box>

          <Box sx={{ width: "100%" }}>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {tasks.map((task, index) => (
                <Fragment key={`task-${index}`}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar sx={{ minWidth: "50px", marginTop: 0 }}>
                      <Avatar
                        sx={{ width: 40, height: 40 }}
                        src={task?.request?.createdby?.profilephoto}
                      />
                    </ListItemAvatar>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      <Box>
                        <Box>
                          <b>
                            {task?.request?.createdby?.first_name}{" "}
                            {task?.request?.createdby?.last_name}
                          </b>{" "}
                          is requesting for a
                          <Box sx={{ display: "inline", marginLeft: 1 }}>
                            <StatusBtn
                              title={task?.request?.process}
                              type={4}
                            />
                          </Box>
                        </Box>
                        <Box>
                          <Small color={theme.palette.text.disabled}>
                            {moment(task?.request?.createdat).calendar()}
                          </Small>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          background: "#eff0ef",
                          padding: "1rem",
                          borderRadius: "8px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <H6>Reason</H6>
                          <IconButton
                            onClick={() =>
                              handleEntityDetails(
                                task?.request,
                                task?.request?.process,
                              )
                            }
                            size="small"
                            sx={{
                              ...styles.btnGath,
                              marginLeft: 1,
                              fontSize: 12,
                              fontWeight: 500,
                            }}
                          >
                            More...
                          </IconButton>
                        </Box>
                        <Paragraph>{task?.request?.reason}</Paragraph>
                      </Box>
                      {task?.status === 0 && (
                        <Box sx={{ ...styles.displayFlex, gap: 1.5 }}>
                          <Chip
                            clickable={true}
                            label={
                              <Box sx={{ ...styles.displayFlex }}>
                                <CheckIcon
                                  sx={{ marginRight: 0.5, fontSize: 15 }}
                                />
                                <Span>Approve</Span>
                              </Box>
                            }
                            color={
                              selectedAction === 2 && selectTask === task?.id
                                ? "primary"
                                : "default"
                            }
                            variant={
                              selectedAction === 2 && selectTask === task?.id
                                ? "filled"
                                : "outlined"
                            }
                            onClick={() => {
                              handleAction(2, task?.id);
                            }}
                          />
                          <Chip
                            clickable={true}
                            label={
                              <Box sx={{ ...styles.displayFlex }}>
                                <CloseIcon
                                  sx={{ marginRight: 0.5, fontSize: 15 }}
                                />
                                <Span>Decline</Span>
                              </Box>
                            }
                            color={
                              selectedAction === 3 && selectTask === task?.id
                                ? "primary"
                                : "default"
                            }
                            variant={
                              selectedAction === 3 && selectTask === task?.id
                                ? "filled"
                                : "outlined"
                            }
                            onClick={() => {
                              handleAction(3, task?.id);
                            }}
                          />
                        </Box>
                      )}

                      {task?.status !== 0 && (
                        <Box
                          sx={{
                            ...styles.displayFlex,
                            gap: 1.5,
                            alignItems: "flex-start",
                          }}
                        >
                          <StatusBtn
                            title={
                              <Span sx={{ ...styles.displayFlex }}>
                                {task?.status === 2 && (
                                  <CheckIcon
                                    sx={{ marginRight: 0.5, fontSize: 14 }}
                                  />
                                )}
                                {task?.status === 3 && (
                                  <CloseIcon
                                    sx={{ marginRight: 0.5, fontSize: 14 }}
                                  />
                                )}
                                {task?.status_display}
                              </Span>
                            }
                            type={task?.status}
                            colorMap={statusColorMap}
                          />
                        </Box>
                      )}

                      <Box
                        sx={{
                          display:
                            selectTask === task?.id && selectedAction !== null
                              ? "block"
                              : "none",
                        }}
                      >
                        <WorkflowTaskForm
                          setOpenSnack={setOpenSnack}
                          setSnackMessage={setSnackMessage}
                          handleRefreshData={handleRefreshData}
                          handleCancel={handleCloseAction}
                          taskId={selectTask}
                          taskStatus={selectedAction}
                        />
                      </Box>
                    </Box>
                  </ListItem>
                  <Divider sx={{ marginY: 1 }} />
                </Fragment>
              ))}
            </List>
          </Box>
        </ProgressPaper>
      </Box>
      <Box
        sx={[
          styles.taskMoreInfo,
          showDetails ? styles.showTaskMoreInfo : styles.hideTaskMoreInfo,
        ]}
      >
        <Box sx={{ padding: 2, ...styles.displayFlex }}>
          <H4 fontWeight={"bold"}>Details</H4>
          <Button
            onClick={handleCloseDetails}
            color="inherit"
            variant={"contained"}
            sx={{ ...styles.closeButton, marginLeft: "auto" }}
          >
            <KeyboardDoubleArrowRightIcon />
          </Button>
        </Box>
        <Box sx={{ paddingX: 2, paddingBottom: 2 }}>
          <TaskDetailsInfo
            entityType={selectEntityDetails?.entityType}
            entityDetails={selectEntityDetails?.entity}
            extraData={selectEntityDetails?.extras}
          />
        </Box>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={openSnack}
        autoHideDuration={8000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackMessage?.success ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackMessage?.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const styles = {
  btnGath: {
    height: "auto !important",
    padding: "0.1rem 0.5rem !important",
    minWidth: "24px !important",
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
  },
  workflowTitle: {
    justifyContent: "space-between",
  },
  workflow: {
    padding: 2,
    borderBottom: "1px solid #e0e0e0",
  },
  workflowLevels: {
    padding: 2,
    borderBottom: "1px solid #e0e0e0",
  },
  closeButton: {
    borderRadius: "50%",
    padding: "0 !important",
    width: "2rem",
    height: "2rem",
    minWidth: "2rem",
    backgroundColor: "white",

    "&:hover": {
      backgroundColor: "inherit.main",
      color: "black",
    },
  },
  field: {
    display: "inline-block",
  },
  fieldLabel: {
    width: "25%",
    fontWeight: "500",
  },
  levelsContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    "& > ul": {
      width: "100%",
      margin: 0,
      padding: 0,
    },
    "& > ul > li": {
      position: "relative",
      width: "100%",
      listStyle: "none",
      display: "flex",
      gap: "1rem",
      alignItems: "flex-start",
    },
    "& > ul > li > span": {
      height: "2rem",
      width: "2rem",
      borderRadius: "50%",
      backgroundColor: "#eff0ef",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& > ul > li > div": {
      display: "flex",
      justifyContent: "space-between",
      borderRadius: "0.5rem",
      padding: "0 0.5rem 0.5rem 0.5rem",
      width: "calc(100% - 3rem)",
    },
    "& > ul > li + li": {
      marginTop: "1rem",
    },
    "& > ul > li::before": {
      content: '""',
      height: "100%",
      width: "2px",
      backgroundColor: "#eff0ef",
      position: "absolute",
      left: "0.9rem",
      marginTop: "2rem",
      marginBottom: "2rem",
    },
    "& > ul > li:last-child::before": {
      display: "none",
    },
  },
  levelStatus: {
    height: "2rem",
    width: "2rem",
    borderRadius: "50%",
    backgroundColor: "#eff0ef",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
  },
  clickable: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#eff0ef",
    },
  },
  taskContainer: {
    width: "100%",
    backgroundColor: "#eff0ef",
    borderRadius: "8px",
  },
  taskHeader: {
    padding: "0.5rem",
  },
  taskBody: {
    padding: "0.5rem",
    borderTop: "2px solid #ffffff",
    width: "100%",
  },
  taskMoreInfo: {
    height: "100%",
    background: "#f3f4f6",
    transition: "width 0.5s ease-in-out",
  },
  hideTaskMoreInfo: {
    width: 0,
    transition: "width 0.5s ease-in-out",
  },
  showTaskMoreInfo: {
    width: 400,
    transition: "width 0.5s ease-in-out",
  },
};

export default WorkflowTaskShort;
