import React, { FC } from "react";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { IFormField, deepDerefrencer } from "../../../utils/form_factory";
import uniqueId from "../../../utils/generateId";
import { ukoTheme } from "../../../theme";

export interface IFormSelectInputProps extends Partial<IFormField> {
  name: string;
  type?: string;
  options?: any[];
  selector?: {
    value: (option: any) => any;
    label: (option: any) => any;
  };
  formControl: any;
  isDisabled?: boolean;
  extraStyles?: any;
}

const FormSelectInput: FC<IFormSelectInputProps> = (
  props: IFormSelectInputProps,
) => {
  const theme = ukoTheme();

  const isFieldRequired = (fieldName: string) => {
    return String(props.formControl.getFieldMeta(props.name)?.error).match(
      /required/gi,
    )
      ? true
      : false;
  };

  return (
    <TextField
      sx={[
        styles.customStyles(
          theme,
          isFieldRequired(props.name) ? theme.palette.primary.main : undefined,
        ),
        props?.extraStyles,
      ]}
      fullWidth
      label={props.label}
      variant="outlined"
      name={props.name}
      id={uniqueId()}
      onChange={props.formControl.handleChange}
      value={deepDerefrencer(props.formControl.values, props.name)}
      error={Boolean(
        deepDerefrencer(props.formControl.touched, props.name) &&
          deepDerefrencer(props.formControl.errors, props.name),
      )}
      helperText={
        deepDerefrencer(props.formControl.touched, props.name) &&
        deepDerefrencer(props.formControl.errors, props.name)
      }
      onBlur={props.formControl.handleBlur}
      select={true}
      disabled={props.isDisabled}
    >
      <MenuItem sx={{ display: "none" }} value={""}>
        Clear
      </MenuItem>
      {props.options?.map((option: any, index) => {
        return (
          <MenuItem key={index} value={props.selector?.value(option)}>
            {props.selector?.label(option)}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

const styles = {
  customStyles: (theme: any, outlineColor?: string) => ({
    "& .MuiOutlinedInput-input": {
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      border: "2px solid",
      borderColor: outlineColor
        ? outlineColor
        : theme.palette.mode === "light"
        ? theme.palette.secondary[300]
        : theme.palette.divider,
    },
  }),
};

export default FormSelectInput;
