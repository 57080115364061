import React, { useState, useEffect } from "react";
import { Box, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import Badge from "@mui/material/Badge";
import styled from "styled-components";
import { keyframes } from "styled-components";
import { useDrawerContext } from "../../contexts/DrawerContext";
import { PERMISSIONS } from "../../constants/permissions";
import { UserGuideService } from "../../pages/UserGuide/services/UserGuide.Service";
import IsGranted from "../../guards/IsGranted";
import DrawerBox from "../../components/UI/DrawerBox";
import { isGranted } from "../../utils/is_granted";
import useAuth from "../../hooks/useAuth";
import { USER_TYPES } from "../../constants/user-types";
import Icons from "../../icons/sidebar";
import { IStylesType } from "../../components/Layouts/sidemenu/styles";
import { DailyTasks } from "./DailyTasks";

const bubble = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-2px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(2px); 
  }
`;

const BubblingBadge = styled(Badge)`
  & .MuiBadge-badge {
    animation: ${bubble} 1s ease-in-out infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -9px;
  }
`;

const UserDailyOperations: React.FC = () => {
  const auth: any = useAuth();
  const { showDrawer, setShowDrawer, setDrawerId } = useDrawerContext();
  const [dailyTaskListCount, setDailyTaskListCount] = useState<number>(0);
  const [dailyTotalTasks, setDailyTotalTasks] = useState(0);
  const [dailyOperationTaskStatusZero, setDailyOperationTaskStatusZero] =
    useState<{}[]>([]);
  const [dailyOperationTaskStatusOne, setDailyOperationTaskStatusOne] =
    useState<{}[]>([]);
  const [dailyProgressValue, setDailyProgressValue] = useState<number>(0);

  const [dailyEndTaskListCount, setDailyEndTaskListCount] = useState<number>(0);
  const [dailyEndTotalTasks, setEndDailyTotalTasks] = useState(0);
  const [dailyEndOperationTaskStatusZero, setDailyEndOperationTaskStatusZero] =
    useState<{}[]>([]);
  const [dailyEndOperationTaskStatusOne, setEndDailyOperationTaskStatusOne] =
    useState<{}[]>([]);
  const [dailyEndProgressValue, setDailyEndProgressValue] = useState<number>(0);

  const [dailyTotalTaskListCount, setDailyTotalTaskListCount] =
    useState<number>(0);

  const handleOpenDrawer = (drawerIdInfo: string) => {
    setDrawerId(drawerIdInfo);
    setShowDrawer({ ...showDrawer, ...{ right: true } });
  };

  const closeDrawer = () => {
    setDrawerId(" ");
    setShowDrawer({ ...showDrawer, ...{ right: false } });
  };

  const isRightUser = (requiredUsers?: string[]) => {
    if (!requiredUsers) return true;
    return requiredUsers.includes(auth?.user?.type);
  };

  useEffect(() => {
    const getDailyOperationsTasks = async () => {
      try {
        if (isGranted(auth?.user, [PERMISSIONS.VIEW_TODOS])) {
          const { count }: any = await UserGuideService.getListOfTasks({
            category: "daily_operation",
          });
          setDailyTotalTaskListCount(count);
        }
        return;
      } catch (error) {
        setDailyTotalTaskListCount(0);
      }
    };
    if (auth.user.type !== "system-user") {
      getDailyOperationsTasks();
    }
  }, [auth?.user]);

  useEffect(() => {
    const getDailyOperationsTasks = async () => {
      try {
        if (isGranted(auth.user, [PERMISSIONS.VIEW_TODOS])) {
          const { count, total }: any = await UserGuideService.getListOfTasks({
            category: "daily_operation",
            sub_category: "daily_operation_start",
          });
          setDailyTaskListCount(count);
          setDailyTotalTasks(total);
        }
        return;
      } catch (error) {
        setDailyTaskListCount(0);
      }
    };
    if (auth.auth?.user?.type !== "system-user") {
      getDailyOperationsTasks();
    }
  }, [auth.user]);

  useEffect(() => {
    const getDailyOperationsTasks = async () => {
      try {
        if (isGranted(auth.user, [PERMISSIONS.VIEW_TODOS])) {
          const { count, total }: any = await UserGuideService.getListOfTasks({
            category: "daily_operation",
            sub_category: "daily_operation_end",
          });
          setDailyEndTaskListCount(count);
          setEndDailyTotalTasks(total);
        }
        return;
      } catch (error) {
        setDailyEndTaskListCount(0);
      }
    };
    if (auth?.user?.type !== "system-user") {
      getDailyOperationsTasks();
    }
  }, [auth.user]);

  useEffect(() => {
    const dailyOperationsNonCompletedTodos = async () => {
      try {
        if (isGranted(auth.user, [PERMISSIONS.VIEW_TODOS])) {
          const { results }: any = await UserGuideService.getUserGuide({
            page_size: 50,
            status: 0,
            category: "daily_operation",
            sub_category: "daily_operation_start",
          });
          setDailyOperationTaskStatusZero(results);
        }
        return;
      } catch (error) {
        setDailyOperationTaskStatusZero([]);
      }
    };
    if (auth?.user?.type !== "system-user") {
      dailyOperationsNonCompletedTodos();
    }
  }, [auth.user]);

  useEffect(() => {
    const getAlistOfCompletedDailyTodos = async () => {
      try {
        if (isGranted(auth.user, [PERMISSIONS.VIEW_TODOS])) {
          const { results }: any = await UserGuideService.getUserGuide({
            page_size: 50,
            status: 1,
            category: "daily_operation",
            sub_category: "daily_operation_start",
          });
          setDailyOperationTaskStatusOne(results);
        }
        return;
      } catch (error) {
        setDailyOperationTaskStatusOne([]);
      }
    };
    if (auth?.user?.type !== "system-user") {
      getAlistOfCompletedDailyTodos();
    }
  }, [auth.user]);

  useEffect(() => {
    const dailyEndOperationsNonCompletedTodos = async () => {
      try {
        if (isGranted(auth.user, [PERMISSIONS.VIEW_TODOS])) {
          const { results }: any = await UserGuideService.getUserGuide({
            page_size: 50,
            status: 0,
            category: "daily_operation",
            sub_category: "daily_operation_end",
          });
          setDailyEndOperationTaskStatusZero(results);
        }
        return;
      } catch (error) {
        setDailyEndOperationTaskStatusZero([]);
      }
    };

    if (auth?.user?.type !== "system-user") {
      dailyEndOperationsNonCompletedTodos();
    }
  }, [auth.user]);

  useEffect(() => {
    const getAlistOfCompletedDailyTodos = async () => {
      try {
        if (isGranted(auth.user, [PERMISSIONS.VIEW_TODOS])) {
          const { results }: any = await UserGuideService.getUserGuide({
            page_size: 50,
            status: 1,
            category: "daily_operation",
            sub_category: "daily_operation_end",
          });
          setEndDailyOperationTaskStatusOne(results);
        }
        return;
      } catch (error) {
        setEndDailyOperationTaskStatusOne([]);
      }
    };

    if (auth?.user?.type !== "system-user") {
      getAlistOfCompletedDailyTodos();
    }
  }, [auth.user]);

  useEffect(() => {
    setDailyTaskListCount(dailyOperationTaskStatusZero.length);
  }, [dailyOperationTaskStatusZero.length, setDailyTaskListCount]);

  useEffect(() => {
    setDailyEndTaskListCount(dailyEndOperationTaskStatusZero.length);
  }, [dailyEndOperationTaskStatusZero.length, setDailyEndTaskListCount]);

  useEffect(() => {
    const calculateDailyProgressValue = (dailyTasksWithStatusOne: {}[]) => {
      const completedDailyTaskProgress =
        (dailyTasksWithStatusOne.length / dailyTotalTasks) * 100;
      setDailyProgressValue(completedDailyTaskProgress);
    };
    calculateDailyProgressValue(dailyOperationTaskStatusOne);
  }, [dailyTotalTasks, dailyOperationTaskStatusOne]);

  useEffect(() => {
    const calculateDailyEndProgressValue = (
      dailyEndTasksWithStatusOne: {}[],
    ) => {
      const completedDailyEndTaskProgress =
        (dailyEndTasksWithStatusOne.length / dailyEndTotalTasks) * 100;
      setDailyEndProgressValue(completedDailyEndTaskProgress);
    };
    calculateDailyEndProgressValue(dailyEndOperationTaskStatusOne);
  }, [dailyEndTotalTasks, dailyEndOperationTaskStatusOne]);

  useEffect(() => {
    setDailyProgressValue(dailyProgressValue);
  }, [dailyProgressValue, setDailyProgressValue]);

  useEffect(() => {
    setDailyEndProgressValue(dailyEndProgressValue);
  }, [dailyEndProgressValue, setDailyEndProgressValue]);

  const referenceDailyTasks = async () => {
    try {
      if (isGranted(auth.user, [PERMISSIONS.VIEW_TODOS])) {
        const { results }: any = await UserGuideService.getUserGuide({
          page_size: 50,
          status: 0,
          category: "daily_operation",
          sub_category: "daily_operation_start",
        });
        setDailyOperationTaskStatusZero(results);
      }
      return;
    } catch (error) {
      setDailyOperationTaskStatusZero([]);
    }
  };

  const refreshDailyTaskOnes = async () => {
    try {
      if (isGranted(auth.user, [PERMISSIONS.VIEW_TODOS])) {
        const { results }: any = await UserGuideService.getUserGuide({
          page_size: 50,
          status: 1,
          category: "daily_operation",
          sub_category: "daily_operation_start",
        });
        setDailyOperationTaskStatusOne(results);
      }
      return;
    } catch (error) {
      setDailyOperationTaskStatusOne([]);
    }
  };

  const referenceDailyEndTasks = async () => {
    try {
      if (isGranted(auth.user, [PERMISSIONS.VIEW_TODOS])) {
        const { results }: any = await UserGuideService.getUserGuide({
          page_size: 50,
          status: 0,
          category: "daily_operation",
          sub_category: "daily_operation_end",
        });
        setDailyEndOperationTaskStatusZero(results);
      }
      return;
    } catch (error) {
      setDailyEndOperationTaskStatusZero([]);
    }
  };

  const refreshDailyEndTaskOnes = async () => {
    try {
      if (isGranted(auth.user, [PERMISSIONS.VIEW_TODOS])) {
        const { results }: any = await UserGuideService.getUserGuide({
          page_size: 50,
          status: 1,
          category: "daily_operation",
          sub_category: "daily_operation_end",
        });
        setEndDailyOperationTaskStatusOne(results);
      }
      return;
    } catch (error) {
      setEndDailyOperationTaskStatusOne([]);
    }
  };

  const getTotalCountDailyOperationsTasks = async () => {
    try {
      if (isGranted(auth?.user, [PERMISSIONS.VIEW_TODOS])) {
        const { count }: any = await UserGuideService.getListOfTasks({
          category: "daily_operation",
        });
        setDailyTotalTaskListCount(count);
      }
      return;
    } catch (error) {
      setDailyTotalTaskListCount(0);
    }
  };

  return (
    <Box>
      <IsGranted permissions={[PERMISSIONS.VIEW_TODOS]}>
        {dailyTotalTaskListCount !== 0 &&
          !isRightUser([USER_TYPES.SYSTEM_USER]) && (
            <ListItemButton
              onClick={() => handleOpenDrawer("daily-user-guide-request")}
              sx={styles.menuItemButton}
            >
              <ListItemIcon sx={styles.menuItemIcon}>
                <Icons.TaskIcon />
              </ListItemIcon>
              <ListItemText sx={styles.menuItemText} primary={"Daily Tasks"} />
              <BubblingBadge
                badgeContent={dailyTotalTaskListCount}
                color="error"
              ></BubblingBadge>
            </ListItemButton>
          )}
      </IsGranted>
      <DrawerBox
        minWidth={370}
        maxWidth={370}
        selectedDrawerId="daily-user-guide-request"
        anchor="right"
        onClose={() => {}}
      >
        <DailyTasks
          closeDrawer={closeDrawer}
          title="Daily Tasks"
          referenceData={referenceDailyTasks}
          taskStatusZero={dailyOperationTaskStatusZero}
          taskStatusOne={dailyOperationTaskStatusOne}
          progress={dailyProgressValue}
          refreshOnes={refreshDailyTaskOnes}
          endDailyDatafunc={referenceDailyEndTasks}
          endTaskStatusZero={dailyEndOperationTaskStatusZero}
          endTaskStatusOne={dailyEndOperationTaskStatusOne}
          endProgress={dailyEndProgressValue}
          refreshEndOnes={refreshDailyEndTaskOnes}
          getTotalCountDailyOperationsTasks={getTotalCountDailyOperationsTasks}
          dailyTaskListCount={dailyTaskListCount}
          dailyEndTaskListCount={dailyEndTaskListCount}
        />
      </DrawerBox>
    </Box>
  );
};

const styles: IStylesType = {
  menuListContainer: {
    width: "100%",
    overflowY: "auto",
  },
  menuItemButton: {
    gap: "0.5rem",
    borderRadius: "10px",
    backgroundColor: "#aeaeae29",
    "& .MuiListItemText-root > span": {
      color: "#fff !important",
    },
    "& .MuiListItemIcon-root .MuiSvgIcon-root": {
      color: "#fff !important",
    },
  },
  menuItemIcon: {
    minWidth: "0px",
    "&.MuiListItemIcon-root .MuiSvgIcon-root": {
      color: "#dfdfdf",
    },
  },
  menuItemText: {
    "& > span": {
      fontWeight: "500",
      color: "#dfdfdf",
    },
  },

  badgeIcon: {
    margin: "0",
    // fontSize: "130px",
    letterSpacing: "10px",
    // color: "#fff",
    textAlign: "center",
    animation: "headShake 0.5s linear 1s infinite alternate",
  },
};

export default UserDailyOperations;
