import { useLayoutEffect, useState } from "react";
import { useAuth } from "../../../../hooks/useAuth";
import useInstitution from "../../../../hooks/useInstitution";
import LogoStore from "../../../../store/logo.store";
import { Avatar, Box } from "@mui/material";
import { styles } from "../styles";
import LogoWhite from "../../../../assets/images/logos/zimba-white";
import { USER_TYPES } from "../../../../constants/user-types";
import { H4, Small } from "../../../Typography";

export const SideBarHeader = () => {
  const { user }: any = useAuth();
  const business: any = useInstitution();
  const [logoState, setLogoState] = useState(LogoStore.initialState);

  useLayoutEffect(() => {
    const subscription = LogoStore.subscribe(setLogoState);
    LogoStore.init(business?.logo);

    return () => {
      subscription.unsubscribe();
    };
  }, [business?.logo]);

  const isRightUser = (requiredUsers?: string[]) => {
    if (!requiredUsers) return true;
    return requiredUsers.includes(user?.type);
  };

  return (
    <Box sx={styles.header}>
      <Box sx={styles.logoContainer}>
        <LogoWhite />
      </Box>
      {!isRightUser([USER_TYPES.SYSTEM_USER]) && (
        <Box sx={styles.institutionLogoContainer}>
          <Avatar
            alt="Institution Logo"
            src={logoState.logo}
            variant="rounded"
            sx={{
              width: 40,
              height: 40,
              backgroundColor: "white",
              padding: "2px",
            }}
          />
          <Box>
            <H4
              sx={{
                textTransform: "capitalize",
                fontWeight: "600",
                color: "#ffd700",
                lineHeight: 1.2,
              }}
            >
              {user?.first_name} {user?.last_name}
            </H4>
            <Small
              sx={{
                textTransform: "capitalize",
                fontSize: "11px",
                color: "#dfdfdf",
                fontWeight: "600",
              }}
            >
              {user?.extra_details?.positiontitle}
            </Small>
          </Box>
        </Box>
      )}
    </Box>
  );
};
