import instance from "../../../api";

export const NotificationService = {
  async getNotificationEvents(filters: any) {
    return instance
      .get("notification/events/", {
        params: filters,
      })
      .then((response) => response.data);
  },

  async getSubNotificationEvents(filters: any) {
    return instance
      .get("notification/subscriptions/", {
        params: filters,
      })
      .then((response) => response.data);
  },

  async updateSubNotificationEvents(payload: any) {
    return instance
      .post("notification/subscriptions/", payload)
      .then((response) => response.data);
  },

  async markAllAsRead(payload: any) {
    return instance
      .post("notification/me/mark-all-as-read/", payload)
      .then((response) => response.data);
  },

  async markSingleAsRead(payload: any, id: string) {
    return instance
      .patch(`notification/me/${id}/`, payload)
      .then((response) => response.data);
  },
};
