import { debounce } from "lodash";
import React, { FC, useState, useEffect } from "react";

import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListSubheader,
  TextField,
  InputAdornment,
  useTheme,
  Box,
  LinearProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { IFormField, deepDerefrencer } from "../../../utils/form_factory";
import uniqueId from "../../../utils/generateId";
import ProgressIndicator from "../ProgressIndicator";
import { Small, Span } from "../../Typography";
import { ukoTheme } from "../../../theme";

interface IFormSelectSearchProps extends Partial<IFormField> {
  name: string;
  type?: string;
  parseFilter?: (...params: any) => any;
  filterValues?: any;
  dataFetcher?: (
    search: string,
    setData: (results: any) => void,
    extraFilters?: any,
  ) => Promise<any> | any;
  selector?: {
    value: (option: any) => any;
    label: (option: any) => any;
  };
  formControl: any;
  isDisabled?: boolean;
}

const FormSelectSearchInput: FC<IFormSelectSearchProps> = (
  props: IFormSelectSearchProps,
) => {
  const theme = ukoTheme();

  const isFieldRequired = (fieldName: string) => {
    return String(props.formControl.getFieldMeta(props.name)?.error).match(
      /required/gi,
    )
      ? true
      : false;
  };

  const [optionList, setOptionList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const originalValue = deepDerefrencer(props.formControl.values, props.name);
    const filters = props.parseFilter ? props.parseFilter(originalValue) : {};

    const prefetch = async () => {
      try {
        if (
          props.dataFetcher &&
          deepDerefrencer(props.formControl.values, props.name)
        ) {
          setLoading(true);
          await props.dataFetcher(searchText, setOptionList, { ...filters });
          setLoading(false);
        } else {
          setOptionList([]);
        }
      } catch (error) {
        setLoading(false);
      }
    };
    if (Object.keys(filters).length > 0) {
      prefetch();
    }
  }, [deepDerefrencer(props.formControl.values, props.name), props.isDisabled]);

  useEffect(() => {
    const debounceSearch = debounce(async () => {
      const filters = props.parseFilter ? props.parseFilter() : {};
      if (props.dataFetcher) {
        setLoading(true);
        await props.dataFetcher(searchText, setOptionList, { ...filters });
        setLoading(false);
      }
    }, 500);

    if (searchText) {
      debounceSearch();
    }

    return () => {
      debounceSearch.cancel();
    };
  }, [searchText]);

  useEffect(() => {
    if (deepDerefrencer(props.formControl.values, props.name) === "") {
      setSearchText("");
    }
  }, [deepDerefrencer(props.formControl.values, props.name)]);

  const getOption = (value: any) => {
    return optionList.find(
      (option: any) => props.selector?.value(option) === value,
    );
  };

  const displayValue = (value: any) => {
    const opt = getOption(value);
    if (opt) {
      return props.selector?.label(opt);
    } else {
      return "";
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel
        sx={{
          color: "#94a5c4",
          fontWeight: "500",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
        }}
        id="search-select-label"
      >
        {loading && (
          <Span sx={{ marginRight: "4px" }}>
            <ProgressIndicator
              sx={{ display: "inline-block" }}
              color="primary"
              size={14}
            />
          </Span>
        )}
        <Span>{props.label}</Span>
      </InputLabel>
      <Select
        sx={styles.customStyles(
          theme,
          isFieldRequired(props.name) ? theme.palette.primary.main : undefined,
        )}
        fullWidth
        MenuProps={{ autoFocus: false }}
        labelId="search-select-label"
        id={uniqueId()}
        notched={undefined}
        label={props.label}
        variant="outlined"
        name={props.name}
        onChange={props.formControl.handleChange}
        value={deepDerefrencer(props.formControl.values, props.name)}
        onClose={() => setSearchText("")}
        onBlur={props.formControl.handleBlur}
        disabled={props.isDisabled || loading}
        renderValue={() =>
          displayValue(deepDerefrencer(props.formControl.values, props.name))
        }
        error={Boolean(
          deepDerefrencer(props.formControl.touched, props.name) &&
            deepDerefrencer(props.formControl.errors, props.name),
        )}
      >
        <ListSubheader sx={{ padding: "16px 20px" }}>
          <TextField
            size="small"
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <MenuItem value={""}>Clear</MenuItem>
        {optionList.map((option, i) => (
          <MenuItem
            sx={{ overflowWrap: "break-word" }}
            key={i}
            value={props.selector?.value(option)}
          >
            {props.selector?.label(option)}
          </MenuItem>
        ))}
      </Select>
      {Boolean(
        deepDerefrencer(props.formControl.touched, props.name) &&
          deepDerefrencer(props.formControl.errors, props.name),
      ) && (
        <Small
          color="error.main"
          fontSize="0.75rem"
          fontWeight={"normal"}
          marginTop={1}
        >
          {deepDerefrencer(props.formControl.errors, props.name)}
        </Small>
      )}
    </FormControl>
  );
};

const styles = {
  customStyles: (theme: any, outlineColor?: string) => ({
    "& .MuiOutlinedInput-input": {
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      border: "2px solid",
      borderColor: outlineColor
        ? outlineColor
        : theme.palette.mode === "light"
        ? theme.palette.secondary[300]
        : theme.palette.divider,
    },
  }),
};

export default FormSelectSearchInput;
