import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Theme,
  styled,
  useMediaQuery,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { debounce } from "lodash";
import UkoAvatar from "../../UkoAvatar";
import { H6, Small, Span } from "../../Typography";
import FormatFigure from "../FormatFigure";
import { useModalContext } from "../../../contexts/ModalDialogContext";
import { ClientService } from "../../../pages/Clients/services/Clients.service";
import { ModalDialog } from "../Modal/ModalDialog";
import uniqueId from "../../../utils/generateId";
import SearchInput from "../../SearchInput";
import { useFormik } from "formik";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { CLIENT_TYPE_INDIVIDUAL } from "../../../constants/client";
import { deepDerefrencer } from "../../../utils/form_factory";
import StatusBtn from "../StatusBtn";
import ProgressIndicator from "../ProgressIndicator";
import { ukoTheme } from "../../../theme";

interface IFormClientAccountProps {
  name: string;
  label: string;
  type?: string;
  formControl: any;
  multipleAccounts: boolean;
  isDisabled?: boolean;
  eligible_for_loan_application?: boolean;
  withdraw_enabled?: boolean;
  setClientAccountDetails?: (payload: any) => void;
}

interface IClientModalProps {
  handleClose: () => void;
  accountsList: any[];
  loading: boolean;
  searchControl: any;
  modalId: string;
  multipleAccounts: boolean;
  selectedAccounts: any[];
  setSelectedAccounts: (selectedAccounts: any[]) => void;
  callBack?: () => void;
}

export const StyledBoxWrapper = styled(Grid)(() => ({
  borderRadius: "8px !important",
  border: "1px solid #E5EAF2",
  padding: "1rem",
  position: "relative",
}));

const FormClientAccountModal: FC<IClientModalProps> = ({
  loading,
  handleClose,
  searchControl,
  multipleAccounts,
  accountsList,
  setSelectedAccounts,
  selectedAccounts,
  modalId,
}) => {
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const handleReset = () => {
    handleClose();
  };

  const handleClearAll = () => {
    setSelectedAccounts([]);
  };

  const onAccountSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    actualValue: any,
  ) => {
    if (multipleAccounts) {
      if (event.target.checked) {
        addSelectedValue(actualValue);
      } else {
        removeSelectedValue(actualValue);
      }
    } else {
      setSelectedAccounts([actualValue]);
    }
  };

  const addSelectedValue = (account: any) => {
    setSelectedAccounts([...selectedAccounts, account]);
  };

  const removeSelectedValue = (account: any) => {
    const newSelectedValue = selectedAccounts.filter(
      (account1: any) => account1.id !== account.id,
    );
    setSelectedAccounts(newSelectedValue);
  };

  const getSelectedValue = (id?: number): boolean => {
    if (multipleAccounts) {
      const m = selectedAccounts.map((account: any) => account.id).includes(id);
      return m;
    } else {
      return selectedAccounts[0]?.id;
    }
  };

  const ActionBtns: FC = () => {
    return (
      <>
        <Button
          onClick={() => {
            handleClearAll();
            handleClose();
          }}
        >
          Clear All
        </Button>
        <Button variant="contained" color="primary" onClick={handleClose}>
          OK
        </Button>
      </>
    );
  };

  return (
    <ModalDialog
      selectedModalId={modalId}
      minWidth={800}
      title={"Client Accounts"}
      onClose={handleReset}
      id={uniqueId()}
      ActionButtons={ActionBtns}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box sx={{ display: "flex" }}>
          <SearchInput
            sx={{ maxWidth: "100%" }}
            name="search"
            value={searchControl.values.search}
            onChange={searchControl.handleChange}
            type="text"
            placeholder="Search for client account ..."
          />
        </Box>
        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        {accountsList.length > 0 && (
          <StyledBoxWrapper>
            {multipleAccounts ? (
              <FormGroup
                sx={{
                  "& .MuiFormControlLabel-root": {
                    display: "flex !important",
                    flexDirection: "row-reverse",
                    "& .MuiFormControlLabel-label": { width: "100%" },
                  },
                  "& .MuiFormControlLabel-labelPlacementEnd": {
                    margin: "0px !important",
                  },
                }}
              >
                {accountsList.map((account: any, index: number) => (
                  <FormControlLabel
                    disabled={account?.status === 1 ? false : true}
                    key={index}
                    control={
                      <Checkbox
                        checked={Boolean(getSelectedValue(account.id))}
                        value={Boolean(getSelectedValue(account.id))}
                        onChange={(event: any) => {
                          onAccountSelect(event, account);
                        }}
                      />
                    }
                    label={
                      <StyledBoxWrapper
                        sx={{
                          display: "flex",
                          flexDirection: downSm ? "column" : "row",
                          padding: "0.5rem",
                          backgroundColor: getSelectedValue(account?.id)
                            ? "#d1dfd36b"
                            : "white",
                        }}
                      >
                        <UkoAvatar
                          src={
                            account?.user_details?.profilephoto ||
                            "/static/avatar/001-man.svg"
                          }
                          sx={{
                            border: 1,
                            width: 30,
                            height: 30,
                            borderColor: "background.paper",
                          }}
                        />
                        <Grid alignItems={"center"} container spacing={2}>
                          <Grid item xs={12} sm={6} md={4}>
                            <Box
                              sx={{
                                gap: 0.5,
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: downSm ? 0 : 2,
                              }}
                            >
                              <H6
                                lineHeight={1.2}
                                fontSize={downSm ? 14 : 10}
                                fontWeight={"bold"}
                              >
                                {account?.client_details?.client_type ===
                                CLIENT_TYPE_INDIVIDUAL
                                  ? `${account?.user_details?.first_name} ${account?.user_details?.last_name}`
                                  : account?.user_details?.shared_name}
                              </H6>
                              <H6
                                color="text.disabled"
                                lineHeight={1.2}
                                fontSize={10}
                              >
                                {account?.membership_no}
                              </H6>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} md={2}>
                            <Span sx={{ fontWeight: "bold" }}>Shares: </Span>
                            <FormatFigure
                              figure={account?.shares_held?.no_shares || 0}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Span sx={{ fontWeight: "bold" }}>Bal: </Span>
                            <FormatFigure
                              currency="UGX"
                              figure={account?.acc_balance || 0}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={2}>
                            <StatusBtn
                              title={account?.status_display}
                              type={account?.status}
                            />
                          </Grid>
                        </Grid>
                      </StyledBoxWrapper>
                    }
                  />
                ))}
              </FormGroup>
            ) : (
              <FormControl fullWidth>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  value={getSelectedValue() || ""}
                  sx={{
                    "& .MuiFormControlLabel-root": {
                      display: "flex !important",
                      flexDirection: "row-reverse",
                      "& .MuiFormControlLabel-label": { width: "100%" },
                    },
                    "& .MuiFormControlLabel-labelPlacementEnd": {
                      margin: "0px !important",
                    },
                  }}
                >
                  {accountsList.map((account: any, index: number) => (
                    <FormControlLabel
                      disabled={account?.status === 1 ? false : true}
                      onChange={(event: any) => {
                        onAccountSelect(event, account);
                      }}
                      key={index}
                      value={account?.id}
                      control={<Radio />}
                      label={
                        <StyledBoxWrapper
                          sx={{
                            display: "flex",
                            flexDirection: downSm ? "column" : "row",
                            padding: "0.5rem",
                            backgroundColor:
                              getSelectedValue() === account?.id
                                ? "#d1dfd36b"
                                : "white",
                          }}
                        >
                          <UkoAvatar
                            src={
                              account?.user_details?.profilephoto ||
                              "/static/avatar/001-man.svg"
                            }
                            sx={{
                              border: 1,
                              width: 30,
                              height: 30,
                              borderColor: "background.paper",
                            }}
                          />
                          <Grid
                            alignItems={"center"}
                            container
                            spacing={2}
                            justifyContent={"space-between"}
                          >
                            <Grid item xs={12} sm={6} md={4}>
                              <Box
                                sx={{
                                  gap: 0.5,
                                  display: "flex",
                                  flexDirection: "column",
                                  marginLeft: downSm ? 0 : 2,
                                }}
                              >
                                <H6
                                  lineHeight={1.2}
                                  fontSize={downSm ? 14 : 10}
                                  fontWeight={"bold"}
                                >
                                  {account?.client_details?.client_type ===
                                  CLIENT_TYPE_INDIVIDUAL
                                    ? `${account?.user_details?.first_name} ${account?.user_details?.last_name}`
                                    : account?.user_details?.shared_name}
                                </H6>
                                <H6
                                  color="text.disabled"
                                  lineHeight={1.2}
                                  fontSize={10}
                                >
                                  {account?.membership_no}
                                </H6>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={2}>
                              <Span sx={{ fontWeight: "bold" }}>Shares: </Span>
                              <FormatFigure
                                figure={account?.shares_held?.no_shares || 0}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <Span sx={{ fontWeight: "bold" }}>Bal: </Span>
                              <FormatFigure
                                currency="UGX"
                                figure={account?.acc_balance || 0}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={2}>
                              <StatusBtn
                                title={account?.status_display}
                                type={account?.status}
                              />
                            </Grid>
                          </Grid>
                        </StyledBoxWrapper>
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
          </StyledBoxWrapper>
        )}
      </Box>
    </ModalDialog>
  );
};

const FormClientAccount: FC<IFormClientAccountProps> = ({
  name,
  label,
  formControl,
  multipleAccounts,
  isDisabled,
  eligible_for_loan_application,
  withdraw_enabled,
  setClientAccountDetails,
}) => {
  const theme = ukoTheme();

  // should determine if field is required based on the formcontrol property
  const isFieldRequired = (fieldName: string) => {
    return String(formControl.getFieldMeta(name)?.error).match(/required/gi)
      ? true
      : false;
  };

  const { setShowModal, setModalId } = useModalContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [accounts, setAccounts] = useState<any[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<any[]>([]);

  useEffect(() => {
    const originalValue = deepDerefrencer(formControl.values, name);

    const ids = Array.isArray(originalValue)
      ? originalValue
      : originalValue
      ? [originalValue]
      : [];

    if (!ids.length) return setSelectedAccounts([]);

    const fetchAccountData = async () => {
      try {
        setLoading(true);
        const { results, count }: any = await ClientService.getClientAccounts({
          ids: JSON.stringify(ids),
          detail: 1,
        });
        setLoading(false);
        setSelectedAccounts(results);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchAccountData();
  }, []);

  const searchControl = useFormik({
    initialValues: { search: "" },
    onSubmit: (values) => {},
  });

  useEffect(() => {
    setSearchQuery(searchControl.values.search);
  }, [searchControl.values.search]);

  useEffect(() => {
    if (multipleAccounts) {
      selectedAccounts.length > 0 &&
        formControl.setFieldValue(
          name,
          selectedAccounts.map((account: any) => account?.id),
        );
      if (setClientAccountDetails) setClientAccountDetails(selectedAccounts);
    } else {
      selectedAccounts.length > 0 &&
        formControl.setFieldValue(name, selectedAccounts[0]?.id);
      if (setClientAccountDetails) setClientAccountDetails(selectedAccounts[0]);
    }
  }, [selectedAccounts]);

  useEffect(() => {
    if (!deepDerefrencer(formControl.values, name)) {
      setSelectedAccounts([]);
      setSearchQuery("");
    }
  }, [deepDerefrencer(formControl.values, name)]);

  useEffect(() => {
    const debouncedFetchData = debounce(async (query: string) => {
      try {
        setLoading(true);
        const searchQueryParam: {
          search: string;
          detail: number;
          eligible_for_loan_application?: boolean;
          withdraw_enabled?: boolean;
        } = {
          search: query,
          detail: 1,
        };
        if (eligible_for_loan_application) {
          searchQueryParam.eligible_for_loan_application = true;
        }
        if (withdraw_enabled) {
          searchQueryParam.withdraw_enabled = true;
        }
        const { results, count }: any =
          await ClientService.getClientAccounts(searchQueryParam);
        setAccounts(results);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }, 500);

    if (!searchQuery) return setAccounts([]);

    debouncedFetchData(searchQuery);

    return () => {
      debouncedFetchData.cancel();
    };
  }, [searchQuery]);

  const handleOpenModal = () => {
    setShowModal(true);
    setModalId(name);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Box
        onClick={handleOpenModal}
        sx={[
          styles.accountContainer(
            theme,
            isFieldRequired(name) ? theme.palette.primary.main : undefined,
          ),
          isDisabled ? styles.disableInput : {},
        ]}
      >
        {selectedAccounts.length > 0 ? (
          selectedAccounts.map((account: any, index: number) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                border: "solid 1px #e5eaf2",
                width: "100%",
                padding: 0.5,
                borderRadius: "8px",
              }}
            >
              <UkoAvatar
                src={
                  account?.user_details?.profilephoto ||
                  "/static/avatar/001-man.svg"
                }
                sx={{
                  border: 1,
                  width: 28,
                  height: 28,
                  borderColor: "background.paper",
                }}
              />
              <Grid alignItems={"center"} container spacing={2}>
                <Grid item xs={7}>
                  <Box
                    sx={{
                      gap: 0.5,
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: 2,
                    }}
                  >
                    <H6 lineHeight={1.2} fontSize={10}>
                      {account?.client_details?.client_type ===
                      CLIENT_TYPE_INDIVIDUAL
                        ? `${account?.user_details?.first_name} ${account?.user_details?.last_name}`
                        : account?.user_details?.shared_name}
                    </H6>
                    <H6 color="text.disabled" lineHeight={1.2} fontSize={10}>
                      {account?.membership_no}
                    </H6>
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  <FormatFigure
                    currency="UGX"
                    figure={account?.acc_balance || 0}
                  />
                </Grid>
              </Grid>
            </Box>
          ))
        ) : (
          <label
            style={{ ...styles.label, display: "flex", alignItems: "center" }}
          >
            {loading && (
              <Span sx={{ marginRight: "4px" }}>
                <ProgressIndicator
                  sx={{ display: "inline-block" }}
                  color="primary"
                  size={14}
                />
              </Span>
            )}
            <Span>{label}</Span>
          </label>
        )}
      </Box>
      {Boolean(
        deepDerefrencer(formControl.touched, name) &&
          deepDerefrencer(formControl.errors, name),
      ) && (
        <Small
          color="error.main"
          fontSize="0.75rem"
          fontWeight={"normal"}
          marginTop={1}
        >
          {deepDerefrencer(formControl.errors, name)}
        </Small>
      )}
      <FormClientAccountModal
        modalId={name}
        selectedAccounts={selectedAccounts}
        setSelectedAccounts={setSelectedAccounts}
        multipleAccounts={multipleAccounts}
        loading={loading}
        searchControl={searchControl}
        handleClose={handleCloseModal}
        accountsList={accounts}
      />
    </>
  );
};

const styles = {
  accountContainer: (theme: any, outlineColor?: string) => ({
    gap: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: "3.5rem",
    padding: "0.5rem",
    justifyContent: "center",
    width: "100%",
    height: "auto",
    border: "solid 2px",
    borderColor: outlineColor
      ? outlineColor
      : theme.palette.mode === "light"
      ? theme.palette.secondary[300]
      : theme.palette.divider,
    borderRadius: "8px",
    cursor: "pointer",
    position: "relative",

    "&:hover": {
      border: "solid 2px black",
    },
  }),
  label: {
    margin: "0 0.2rem",
    fontWeight: 500,
    color: "#94a5c4",
  },
  removeButton: {
    borderRadius: "10px",
    padding: "0 !important",
    width: "20px",
    height: "20px",
    backgroundColor: "white",
    minWidth: "0px",
    color: "error.main",

    "&:hover": {
      backgroundColor: "error.main",
      color: "white",
    },
  },
  disableInput: {
    pointerEvents: "none",
    opacity: 0.6,
  },
};

export default FormClientAccount;
