import React, { FC, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { deepDerefrencer } from "../../../utils/form_factory";
import uniqueId from "../../../utils/generateId";
import { ukoTheme } from "../../../theme";

interface IFormTextInputProps {
  name: string;
  label: string;
  type?: string;
  multiline?: boolean;
  rows?: number;
  formControl: any;
  isDisabled?: boolean;
  isHidden?: boolean;
  decimalPlaces?: number;
}

const FormIppnsInput: FC<IFormTextInputProps> = ({
  name,
  label,
  formControl,
  type,
  isDisabled,
  isHidden,
}) => {
  const theme = ukoTheme();

  const isFieldRequired = (fieldName: string) => {
    return String(formControl.getFieldMeta(name)?.error).match(/required/gi)
      ? true
      : false;
  };

  const formatNumber = (value: string) => {
    return value.replace(/[^0-9]/g, "");
  };

  const [formattedValue, setFormattedValue] =
    React.useState<string>("000000000000000");
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const formattedInput = formatNumber(inputValue);
    const newFormattedValue = formattedInput.slice(-15).padStart(15, "0");
    formControl.setFieldValue(name, newFormattedValue);
    formControl.handleChange({
      ...event,
      target: { ...event.target, value: formattedInput },
    });
    setFormattedValue(newFormattedValue);
  };

  useEffect(() => {
    setFormattedValue(
      formatNumber(
        deepDerefrencer(formControl.values, name) || "000000000000000",
      ),
    );
  }, [name, deepDerefrencer(formControl.values, name)]);

  return (
    <TextField
      sx={[
        styles.customStyles(
          theme,
          isFieldRequired(name) ? theme.palette.primary.main : undefined,
        ),
      ]}
      fullWidth
      id={uniqueId()}
      label={label}
      variant="outlined"
      name={name}
      type={type}
      onChange={handleInputChange}
      value={formattedValue}
      error={Boolean(
        deepDerefrencer(formControl.touched, name) &&
          deepDerefrencer(formControl.errors, name),
      )}
      helperText={
        deepDerefrencer(formControl.touched, name) &&
        deepDerefrencer(formControl.errors, name)
      }
      onBlur={formControl.handleBlur}
      disabled={isDisabled}
      hidden={isHidden}
    />
  );
};

const styles = {
  customStyles: (theme: any, outlineColor?: string) => ({
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      border: "2px solid",
      borderColor: outlineColor
        ? outlineColor
        : theme.palette.mode === "light"
        ? theme.palette.secondary[300]
        : theme.palette.divider,
    },

    "& input[type='file'].MuiInputBase-input": {
      marginLeft: "120px",
    },
  }),
};

export default FormIppnsInput;
