export const isGranted = (
  user: any,
  permissions: string[],
  requiredUsers?: string[],
) => {
  let canAccess: boolean = false;
  const isRightUser = () => {
    if (!requiredUsers || requiredUsers?.length === 0) return true;
    return requiredUsers.includes(user?.type);
  };

  if (!permissions || permissions?.length === 0) {
    canAccess = isRightUser();
  } else {
    const hasPermission = (permission: string) => {
      return user?.all_permissions?.includes(permission);
    };
    canAccess = permissions.some(hasPermission);
  }

  return canAccess;
};
