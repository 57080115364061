import React, { FC, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { deepDerefrencer } from "../../../utils/form_factory";
import uniqueId from "../../../utils/generateId";
import { ukoTheme } from "../../../theme";

interface IFormNinInputProps {
  name: string;
  label: string;
  formControl: any;
  customFormat?: string;
}

const FormNinInput: FC<IFormNinInputProps> = ({
  name,
  label,
  formControl,
  customFormat,
}) => {
  const theme = ukoTheme();

  const isFieldRequired = (fieldName: string) => {
    return String(formControl.getFieldMeta(fieldName)?.error).match(
      /required/gi,
    )
      ? true
      : false;
  };

  const formatNumber = (value: string) => {
    const stringToFormat = value || "";

    const numericValue = stringToFormat.replace(/\s/g, "");
    if (customFormat) {
      let formatted = "";
      let customIndex = 0;

      for (let i = 0; i < customFormat.length; i++) {
        if (customFormat[i] === "X") {
          formatted += numericValue[customIndex] || "";
          customIndex++;
        } else {
          formatted += customFormat[i];
        }
      }

      return formatted;
    }
    return numericValue.replace(/(\d{14})/, "$1");
  };

  const [formattedValue, setFormattedValue] = React.useState<string>("");

  useEffect(() => {
    setFormattedValue(formatNumber(deepDerefrencer(formControl.values, name)));
  }, [name, customFormat, deepDerefrencer(formControl.values, name)]);

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;

    const formattedInput = formatNumber(input);
    setFormattedValue(formattedInput);

    if (
      customFormat &&
      input.length > customFormat.replace(/[^X]/g, "").length
    ) {
      return;
    }

    const phoneInput = input.replace(/\s/g, "").replace(/-/g, "");
    formControl.setFieldValue(name, phoneInput);
  };

  return (
    <TextField
      sx={styles.customStyles(
        theme,
        isFieldRequired(name) ? theme.palette.primary.main : undefined,
      )}
      fullWidth
      id={uniqueId()}
      label={label}
      variant="outlined"
      name={name}
      type={"text"}
      onChange={handleNumberChange}
      value={formattedValue}
      error={Boolean(
        deepDerefrencer(formControl.touched, name) &&
          deepDerefrencer(formControl.errors, name),
      )}
      helperText={
        deepDerefrencer(formControl.touched, name) &&
        deepDerefrencer(formControl.errors, name)
      }
      onBlur={formControl.handleBlur}
    />
  );
};

const styles = {
  customStyles: (theme: any, outlineColor?: string) => ({
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      border: "2px solid",
      borderColor: outlineColor
        ? outlineColor
        : theme.palette.mode === "light"
        ? theme.palette.secondary[300]
        : theme.palette.divider,
    },

    "& input[type='file'].MuiInputBase-input": {
      marginLeft: "120px",
    },
  }),
};

export default FormNinInput;
