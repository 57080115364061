import { FC, useState, SyntheticEvent } from "react";
import { H4 } from "../../components/Typography";
import { Box, Button, Tab, styled, AppBar, Tabs } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { UserGuideService } from "./services/UserGuide.Service";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { PERMISSIONS } from "../../constants/permissions";
import useAuth from "../../hooks/useAuth";
import { isGranted } from "../../utils/is_granted";
import { USER_TYPES } from "../../constants/user-types";
import StartDailyTasks from "./StartDailyTasks";
import EndDailyTasks from "./EndDailyTasks";

interface UserGuideDetailProps {
  closeDrawer: () => void;
  title: String;
  progress: number;
  taskStatusZero: {}[];
  taskStatusOne: {}[];
  referenceData: () => void;
  refreshOnes: () => Promise<void>;
  endProgress: number;
  endTaskStatusZero: {}[];
  endTaskStatusOne: {}[];
  endDailyDatafunc: () => void;
  refreshEndOnes: () => Promise<void>;
  getTotalCountDailyOperationsTasks: () => void;
  dailyEndTaskListCount: number;
  dailyTaskListCount: number;
}

const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: 13,
  color: theme.palette.text.primary,
  "&.Mui-selected": {
    backgroundColor: "#44814E",
    color: "white",
  },
}));

const StyledDailyTab = styled(Tab)(({ theme }) => ({
  fontSize: 13,
  color: theme.palette.text.primary,
  gap: "1rem",
  height: "3rem",
  "&.Mui-selected": {
    backgroundColor: "#44814E",
    color: "white",
  },
}));

const StyledTabList = styled(TabList)(({ theme }) => ({
  [theme.breakpoints.down(780)]: {
    width: "100%",
    "& .MuiTabs-flexContainer": {
      display: "grid !important",
      gridTemplateColumns: "1fr 1fr !important",
    },
    marginBottom: 20,
  },
  [theme.breakpoints.up("sm")]: {
    "& .MuiTabs-flexContainer": {
      display: "grid !important",
      gridTemplateColumns: "1fr 1fr !important",
    },
  },
}));

const StyledTabPanel = styled(TabPanel)(() => ({
  padding: 0,
}));

export const DailyTasks: FC<UserGuideDetailProps> = ({
  title,
  closeDrawer,
  progress,
  taskStatusZero,
  taskStatusOne,
  referenceData,
  refreshOnes,
  endProgress,
  endTaskStatusZero,
  endTaskStatusOne,
  endDailyDatafunc,
  refreshEndOnes,
  getTotalCountDailyOperationsTasks,
  dailyEndTaskListCount,
  dailyTaskListCount,
}) => {
  const navigate = useNavigate();
  const [mainTab] = useState<string>("tab1");
  const [dailyTab, setDailyTab] = useState<string>("tab1");

  const handleDailyTabChange = (_: SyntheticEvent, newValue: string) =>
    setDailyTab(newValue);
  const auth: any = useAuth();

  const navigateUrl = (url: string) => {
    navigate(`/${url}`);
    // closeDrawer();
  };

  const MarkITAsComplete = async (values: any) => {
    const TodoTask = {
      url: values?.url,
      status: values?.status === 0 ? 1 : 0,
    };

    try {
      await UserGuideService.markItAsComplete(TodoTask, values?.id);
      toast.success("Task masked as complete sucessfully ");
      referenceData();
      endDailyDatafunc();
      refreshOnes();
      refreshEndOnes();
      getTotalCountDailyOperationsTasks();
    } catch (error: any) {
      toast.error("Failed to make a task as complete");
    }
  };

  return (
    <div>
      <TabContext value={mainTab}>
        <Box
          sx={{
            ...styles.UserGuideTitle,
            ...styles.guide,
            ...styles.displayFlex,
            position: "fixed",
            top: 0,
            width: "370px",
            zIndex: 1000,
            backgroundColor: "#fff",
          }}
        >
          <H4 fontWeight={"bold"}>{title}</H4>
          <Button
            onClick={closeDrawer}
            color="inherit"
            variant={"contained"}
            sx={{ ...styles.closeButton, marginLeft: "auto" }}
          >
            <CloseIcon />
          </Button>
        </Box>
        <Box
          sx={{
            height: "3rem",
            borderBottom: "1px solid #e0e0e0",
            marginTop: "4rem",
          }}
        >
          <TabContext value={dailyTab}>
            <StyledTabList onChange={handleDailyTabChange}>
              <StyledDailyTab
                label={`${dailyTaskListCount}  Start Tasks`}
                value="tab1"
                disabled={
                  !isGranted(
                    auth.user,
                    [PERMISSIONS.VIEW_TODOS],
                    [USER_TYPES.INSTITUTION_ADMIN],
                  )
                }
              ></StyledDailyTab>
              <StyledTab
                label={`${dailyEndTaskListCount}  End Tasks`}
                value="tab2"
                disabled={
                  !isGranted(
                    auth.user,
                    [PERMISSIONS.VIEW_TODOS],
                    [USER_TYPES.INSTITUTION_ADMIN],
                  )
                }
                sx={{ marginLeft: 0 }}
              />
            </StyledTabList>
            <StyledTabPanel value="tab1">
              <StartDailyTasks
                progress={progress}
                taskStatusZero={taskStatusZero}
                navigateUrl={navigateUrl}
                MarkITAsComplete={MarkITAsComplete}
                taskStatusOne={taskStatusOne}
              />
            </StyledTabPanel>
            <StyledTabPanel value="tab2">
              <EndDailyTasks
                progress={endProgress}
                taskStatusZero={endTaskStatusZero}
                navigateUrl={navigateUrl}
                MarkITAsComplete={MarkITAsComplete}
                taskStatusOne={endTaskStatusOne}
              />
            </StyledTabPanel>
          </TabContext>
        </Box>
      </TabContext>
    </div>
  );
};

const styles = {
  displayFlex: {
    display: "flex",
    alignItems: "center",
  },
  UserGuideTitle: {
    justifyContent: "space-between",
  },
  guide: {
    padding: 2,
    borderBottom: "1px solid #e0e0e0",
  },

  closeButton: {
    borderRadius: "50%",
    padding: "0 !important",
    width: "2rem",
    height: "2rem",
    minWidth: "2rem",
    color: "black",
    backgroundColor: "white",
  },
};
