import { FC, useState } from "react";
import { IGroupMenu, IMenuItem } from "../../MenuList";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../hooks/useAuth";
import useInstitution from "../../../../hooks/useInstitution";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { styles } from "../styles";
import { Span } from "../../../Typography";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MenuGroupItem } from "./MenuGroupItem";
import IsGranted from "../../../../guards/IsGranted";

export const MenuItem: FC<{
  item: IMenuItem;
  openMenu: string;
  setOpen: any;
}> = ({ openMenu, item, setOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user }: any = useAuth();
  const business: any = useInstitution();

  const [openCollapse, setOpenCollapse] = useState("");

  const toggleClick = (path: string) => {
    if (openMenu === path) {
      setOpen("");
    } else {
      setOpen(path);
    }
  };

  const isRightUser = (requiredUsers?: string[]) => {
    if (!requiredUsers) return true;
    return requiredUsers.includes(user?.type);
  };

  const handleClickLink = (menuItem: IMenuItem) => {
    navigate(menuItem.path);
  };

  const isActive = (path: string) => {
    return location?.pathname === path;
  };

  return (
    <>
      {!item.subMenu ? (
        <ListItemButton
          sx={[
            styles.menuItemButton,
            { display: isRightUser(item.users) ? "flex" : "none" },
          ]}
          className={isActive(item.path) ? "active" : ""}
          onClick={() => {
            handleClickLink(item);
            setOpen("");
          }}
        >
          <ListItemIcon sx={styles.menuItemIcon}>
            <item.Icon />
          </ListItemIcon>
          <ListItemText sx={styles.menuItemText} primary={item.title} />
        </ListItemButton>
      ) : (
        <>
          <ListItemButton
            sx={[
              styles.menuItemButton,
              { display: isRightUser(item.users) ? "flex" : "none" },
            ]}
            onClick={() => toggleClick(item.path)}
          >
            <ListItemIcon sx={styles.menuItemIcon}>
              <item.Icon />
            </ListItemIcon>
            <ListItemText sx={styles.menuItemText} primary={item.title} />

            {item.title === "Clients" &&
              !(business?.settings?.usage?.can_create_clients ?? true) && (
                <Span
                  sx={{
                    marginLeft: "auto",
                    backgroundColor: "#FEC90D",
                    padding: "2px",
                    fontSize: "9px",
                    borderRadius: "3px",
                  }}
                >
                  Upgrade
                </Span>
              )}
            {openMenu === item.path ? (
              <ExpandLessIcon sx={{ color: "#dfdfdf", fontSize: "16px" }} />
            ) : (
              <ExpandMoreIcon sx={{ color: "#dfdfdf", fontSize: "16px" }} />
            )}
          </ListItemButton>

          <Collapse
            sx={styles.collapseContainer}
            in={openMenu === item.path}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {item.subMenu?.map((subItem: IGroupMenu, index: number) => (
                <IsGranted
                  key={`${subItem.title}sub-${index}`}
                  permissions={subItem?.permissions || []}
                  requiredUsers={subItem.users}
                >
                  <MenuGroupItem
                    item={subItem}
                    openMenu={openCollapse}
                    setOpen={setOpenCollapse}
                  />
                </IsGranted>
              ))}
            </List>
          </Collapse>
        </>
      )}
    </>
  );
};
