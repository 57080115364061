import instance from "../../../api";
export const WorkflowTaskService = {
  async getTasks(filters: Object) {
    return instance
      .get("workflow-tasks/", {
        params: filters,
      })
      .then((response) => response.data);
  },

  async getTaskCount(filters: Object) {
    return instance
      .get("workflow-tasks/tasks-count/", {
        params: filters,
      })
      .then((response) => response.data);
  },

  async getTaskDetails(id: string) {
    return instance
      .get(`workflow-tasks/${id}/`)
      .then((response) => response.data);
  },

  async createTask(payload: Object) {
    return instance
      .post("workflow-tasks/", payload)
      .then((response) => response.data);
  },

  async updateTask(payload: Object, id: string) {
    return instance
      .patch(`workflow-tasks/${id}/`, payload)
      .then((response) => response.data);
  },
};
