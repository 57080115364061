import { Box, Button, List, ListItem, Chip } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { H4, H5, H6 } from "../../components/Typography";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { AlertMessage } from "../../components/UI/AlertMessage";
import React from "react";
import FormCheckbox from "../../components/UI/FormComponents/FormCheckBox";
import { NotificationService } from "./services/Notification.service";
import { useAuth } from "../../hooks/useAuth";
import ProgressIndicator from "../../components/UI/ProgressIndicator";
import { NOTIFICATION_SETTINGS } from "../../constants/notifications";

interface NotificationSettingsProps {
  handleClose: () => void;
  goBack: () => void;
}

const NotificationSettings: FC<NotificationSettingsProps> = ({
  handleClose,
  goBack,
}) => {
  const { user }: any = useAuth();
  const [nonFieldErrors, setNonFieldErrors] = useState<any[]>([
    "Read Notifications will automatically be delete after 7 days",
  ]);

  const [loading, setLoading] = useState(false);
  const [settingsTab, setSettingsTab] = useState<string>("SYSTEM");
  const [possibleNotifications, setPossibleNotifications] = useState([]);
  const [subscribedEvents, setSubscribedEvents] = useState([]);
  const [updatingId, setUpdatingId] = useState(null);

  useEffect(() => {
    const getAllEvents = async () => {
      setLoading(true);
      try {
        const response: any = await NotificationService.getNotificationEvents({
          page: 1,
          page_size: 20,
          channel: settingsTab,
        });
        setPossibleNotifications(response.results);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    getAllEvents();
  }, [settingsTab]);

  useEffect(() => {
    const getSubscriptions = async () => {
      try {
        const response: any =
          await NotificationService.getSubNotificationEvents({
            page: 1,
            page_size: 20,
            channel: settingsTab,
          });
        setSubscribedEvents(response.results);
      } catch (error) {}
    };
    getSubscriptions();
  }, [settingsTab]);

  const handleUpdateEvent = async (payload: any) => {
    setUpdatingId(payload.event);
    try {
      const response =
        await NotificationService.updateSubNotificationEvents(payload);
      setUpdatingId(null);
      updateSubscribed(response);
    } catch (error) {
      setUpdatingId(null);
    }
  };

  const onCheckChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: any,
  ) => {
    if (event.target.checked) {
      handleUpdateEvent({
        event: data.id,
        status: 1,
        user: user.uid,
      });
    } else {
      handleUpdateEvent({
        event: data.id,
        status: 0,
        user: user.uid,
      });
    }
  };

  const hasSubscribed = (event: any) => {
    return subscribedEvents
      .filter((data: any) => data.status)
      .map((data: any) => data.event)
      .includes(event.id);
  };

  const updateSubscribed = (data: any) => {
    if (data.status) {
      const filtered = [].concat(subscribedEvents, { ...data });
      setSubscribedEvents(filtered);
    } else {
      const filtered = subscribedEvents.filter(
        (t: any) => t.event !== data.event,
      );
      setSubscribedEvents(filtered);
    }
  };

  return (
    <>
      <Box sx={{ ...styles.displayFlex, ...styles.settingsHeader }}>
        <Button
          onClick={goBack}
          color="inherit"
          variant={"contained"}
          sx={{ ...styles.closeButton }}
        >
          <ArrowBackIosNewIcon />
        </Button>
        <H4 fontWeight={"bold"}>Notifications Settings</H4>

        <Button
          onClick={handleClose}
          color="inherit"
          variant={"contained"}
          sx={{ ...styles.closeButton, marginLeft: "auto" }}
        >
          <CloseIcon />
        </Button>
      </Box>
      <Box sx={{ paddingX: 2, paddingBottom: 2, height: "100%" }}>
        <Box sx={{ ...styles.workflow, ...styles.displayFlex, gap: 1.5 }}>
          {NOTIFICATION_SETTINGS.map((status, index) => (
            <Chip
              key={index}
              color={settingsTab === status.value ? "primary" : "default"}
              label={status.name}
              onClick={() => {
                setSettingsTab(status.value);
              }}
            />
          ))}
        </Box>
        <Box sx={{ marginX: -2 }}>
          <AlertMessage
            title="Important information"
            isOpen={true}
            message={
              <ul style={{ padding: 0, margin: 0 }}>
                {nonFieldErrors.map((error: string, index: number) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            }
            type="error"
            closeAlert={() => setNonFieldErrors([])}
          />
        </Box>
        {loading && (
          <Box sx={{ paddingY: 0.5, paddingX: 2 }}>
            <ProgressIndicator size={14} />
          </Box>
        )}

        <List dense={true}>
          {possibleNotifications.map((data: any, index: number) => (
            <ListItem key={index}>
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <FormCheckbox
                  isDisabled={false}
                  label={""}
                  name={data.key}
                  formControl={{
                    value: hasSubscribed(data),
                    handleChange: (
                      event: React.ChangeEvent<HTMLInputElement>,
                    ) => {
                      onCheckChange(event, data);
                    },
                  }}
                />
                <H6 mb={0} ml={-2}>
                  {data.key_display}
                </H6>
                <Box sx={{ marginLeft: "auto" }}>
                  {updatingId == data.id && <ProgressIndicator size={12} />}
                </Box>
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );
};

const styles = {
  settingsHeader: {
    paddingY: 2,
    paddingX: 0.5,
    borderBottom: "1px solid #e0e0e0",
    position: "sticky",
    top: 0,
    zIndex: 100,
    backgroundColor: "#fff",
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
  },
  closeButton: {
    borderRadius: "50%",
    padding: "0 !important",
    width: "2rem",
    height: "2rem",
    minWidth: "2rem",
    backgroundColor: "white",

    "&:hover": {
      backgroundColor: "inherit.main",
      color: "black",
    },
  },
  workflow: {
    padding: 2,
    marginX: -2,
    borderBottom: "1px solid #e0e0e0",
  },
};

export default NotificationSettings;
