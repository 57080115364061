import { FC, useEffect, useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { USER_TYPES } from "../../../constants/user-types";
import FormAsyncSelectSearch from "./FormAsyncSelectSearch";
import FormSelectSearchInput from "./FormSelectSearch";
import { isGranted } from "../../../utils/is_granted";
import { PERMISSIONS } from "../../../constants/permissions";

interface IFormBranchFieldProps {
  name: string;
  label: string;
  type?: string;
  parseFilter?: (...params: any) => any;
  filterValues?: any;
  dataFetcher?: (
    search: string,
    setData: (results: any) => void,
    extraFilters?: any,
  ) => Promise<any> | any;
  selector?: {
    value: (option: any) => any;
    label: (option: any) => any;
  };
  formControl: any;
  isDisabled?: boolean;
}

const FormBranchField: FC<IFormBranchFieldProps> = (
  props: IFormBranchFieldProps,
) => {
  const auth: any = useAuth();
  const [disableBranch, setDisableBranch] = useState(false);

  useEffect(() => {
    if (
      isGranted(auth.user, [PERMISSIONS.CROSS_BRANCH_ACCESS]) ||
      auth?.user?.branch_access
    ) {
      setDisableBranch(true);
    } else {
      setDisableBranch(false);
      setTimeout(() => {
        props.formControl.setFieldValue(props.name, {
          id: auth?.user?.extra_details?.branch_details?.id,
          name: auth?.user?.extra_details?.branch_details?.name,
        });
      });
    }
  }, [auth?.user?.type, auth?.user?.extra_details?.branch]);

  return <FormAsyncSelectSearch isDisabled={!disableBranch} {...props} />;
};

export default FormBranchField;
