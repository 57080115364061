import React, { FC, useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, styled } from "@mui/material";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./sidemenu";
import VersionUpdate from "../UI/VersionUpdate";
import { ModalProvider } from "../../contexts/ModalDialogContext";
import { DrawerProvider } from "../../contexts/DrawerContext";
import { TaskIndicatorProvider } from "../../contexts/TaskIndicatorContext";
import { ConsentProvider } from "../../contexts/ConsentBoxContext";

const Wrapper = styled(Box)(({ theme }) => ({
  maxWidth: `calc(100%)`,
  marginLeft: "20%",
  marginRight: 0,
  paddingLeft: "1rem",
  paddingRight: "1rem",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginLeft: 0,
  },
}));

const RouterOutlet: any = Outlet;

const DashboardLayout: FC<any> = ({ children }) => {
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);

  return (
    <>
      <DrawerProvider>
        <TaskIndicatorProvider>
          <ModalProvider>
            <ConsentProvider>
              <DashboardSidebar
                showMobileSideBar={showMobileSideBar}
                closeMobileSideBar={() => setShowMobileSideBar(false)}
              />
              <Wrapper>
                <DashboardNavbar
                  setShowMobileSideBar={() =>
                    setShowMobileSideBar((state) => !state)
                  }
                />
                {children || <RouterOutlet />}
              </Wrapper>
            </ConsentProvider>
          </ModalProvider>
          <VersionUpdate />
        </TaskIndicatorProvider>
      </DrawerProvider>
    </>
  );
};

export default DashboardLayout;
