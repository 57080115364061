import { FC, useEffect } from "react";
import { useTaskIndicatorContext } from "../../contexts/TaskIndicatorContext";
import { Box, Button } from "@mui/material";
import { H6, Paragraph } from "../Typography";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import { interval } from "rxjs";
import { distinctUntilChanged, map, startWith } from "rxjs/operators";
import Cookies from "universal-cookie";

const UpdateAlert: FC<{ closeAlert: Function; newVersion: string }> = ({
  closeAlert,
  newVersion,
}) => {
  const reloadPage = () => {
    localStorage.setItem("F_VERSIONGRT_Z", newVersion);
    window.location.reload();
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        color: "#1d2438",
        gap: 3,
      }}
    >
      <Box sx={styles.updateIcon}>
        <RestartAltIcon color="primary" fontSize="large" />
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", position: "relative" }}
      >
        <H6>Updates Available</H6>
        <Paragraph sx={{ marginBottom: 2, maxWidth: 350 }}>
          There are new updates available. Please refresh the page to get the
          latest version.
        </Paragraph>
        <Box>
          <Button
            onClick={reloadPage}
            size="small"
            color="primary"
            variant="outlined"
          >
            Refresh
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const VersionUpdate = () => {
  const taskIndicator = useTaskIndicatorContext();

  useEffect(() => {
    const cookies = new Cookies();

    const cookieChanges$ = interval(2000).pipe(
      startWith(0),
      map(() => cookies.get("F_VERSIONGRT_Z")),
      distinctUntilChanged(),
    );

    const subscription = cookieChanges$.subscribe((newCookieValue) => {
      const version = localStorage.getItem("F_VERSIONGRT_Z");
      if (newCookieValue !== version) {
        if (version !== "undefined" && version !== null) {
          showUpdates(newCookieValue);
        } else {
          localStorage.setItem("F_VERSIONGRT_Z", newCookieValue);
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const showUpdates = (newVersion: string) => {
    taskIndicator.setOpenTaskIndicator(true);
    taskIndicator.setPosition({ vertical: "bottom", horizontal: "left" });
    taskIndicator.setMessage(
      <UpdateAlert
        newVersion={newVersion}
        closeAlert={taskIndicator.setOpenTaskIndicator}
      />,
    );
  };

  return <></>;
};

const styles = {
  updateIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100px",
    width: "100px",
    borderRadius: "50px",
    backgroundColor: "#dbe6dc",
  },
  closeButton: {
    borderRadius: "50%",
    padding: "0 !important",
    width: "2rem",
    height: "2rem",
    minWidth: "2rem",
    backgroundColor: "white",

    "&:hover": {
      backgroundColor: "inherit.main",
      color: "black",
    },
  },
};

export default VersionUpdate;
