import { FC, useState, SyntheticEvent } from "react";
import { H1, Paragraph } from "../../components/Typography";
import { Box, Tab, styled } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { PERMISSIONS } from "../../constants/permissions";
import CircularProgressWithLabel from "./Circular";
import StatusOne from "./StatusOne";
import { StatusZero } from "./StatusZero";
import useAuth from "../../hooks/useAuth";
import { isGranted } from "../../utils/is_granted";
import { USER_TYPES } from "../../constants/user-types";

const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: 13,
  color: theme.palette.text.primary,
}));

const StyledTabList = styled(TabList)(({ theme }) => ({
  [theme.breakpoints.down(780)]: {
    width: "100%",
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-around",
    },
    marginBottom: 20,
  },
  [theme.breakpoints.up("sm")]: {
    "& .MuiTabs-flexContainer": {
      minWidth: 200,
      justifyContent: "space-around",
    },
  },
}));

const StyledTabPanel = styled(TabPanel)(() => ({
  padding: 0,
}));

type StartDailyTasksProps = {
  progress: number;
  taskStatusZero: {}[];
  navigateUrl: (url: string) => void;
  taskStatusOne: {}[];
  MarkITAsComplete: (values: any) => Promise<any>;
};

const StartDailyTasks: FC<StartDailyTasksProps> = ({
  progress,
  taskStatusZero,
  navigateUrl,
  MarkITAsComplete,
  taskStatusOne,
}) => {
  const auth: any = useAuth();
  const [tab, setTab] = useState<string>("tab1");

  const handleTabChange = (_: SyntheticEvent, newValue: string) =>
    setTab(newValue);

  return (
    <TabContext value={tab}>
      <div>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "0.1fr 1fr",
            marginTop: "25px",
            gridGap: "1rem",
            marginLeft: "1rem",
            marginRight: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Box>
            <CircularProgressWithLabel
              value={progress}
              text={taskStatusZero.length}
              color={"primary"}
            />
          </Box>
          <Box sx={{}}>
            <H1 sx={{ fontSize: "16px", fontWeight: "bold" }}>
              Daily Operations
            </H1>
            <Paragraph sx={{ fontSize: "11px" }}>
              Beginning of Day Operations Wizard
            </Paragraph>
          </Box>
        </Box>
        <StyledTabList onChange={handleTabChange}>
          <StyledTab
            label="NEXT STEPS"
            value="tab1"
            disabled={
              !isGranted(
                auth.user,
                [PERMISSIONS.VIEW_TODOS],
                [USER_TYPES.INSTITUTION_ADMIN],
              )
            }
          />
          <StyledTab
            label="COMPLETED"
            value="tab2"
            disabled={
              !isGranted(
                auth.user,
                [PERMISSIONS.VIEW_TODOS],
                [USER_TYPES.INSTITUTION_ADMIN],
              )
            }
          />
        </StyledTabList>
        <Box marginTop={3} marginBottom={3}>
          <StyledTabPanel value="tab1" hidden={tab !== "tab1"}>
            <StatusZero
              taskStatusZero={taskStatusZero}
              navigateUrl={navigateUrl}
              MarkITAsComplete={MarkITAsComplete}
            />
          </StyledTabPanel>

          <StyledTabPanel value="tab2" hidden={tab !== "tab2"}>
            <StatusOne taskStatusOne={taskStatusOne} />
          </StyledTabPanel>
        </Box>
      </div>
    </TabContext>
  );
};

export default StartDailyTasks;
