import { useContext, useEffect } from "react";
import { InstitutionContext } from "../contexts/InstitutionContext";
import InstitutionStore from "../store/institution.store";

const useInstitution = () => {
  const { business, setBusiness }: any = useContext(InstitutionContext);
  const subscription = InstitutionStore.subscribe(setBusiness);

  useEffect(() => {
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return business;
};

export default useInstitution;
