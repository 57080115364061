import { Subject } from "rxjs";

const subject = new Subject();

const initialState: any = {};

let state = initialState;

const InstitutionStore = {
  init: (data: any) => subject.next({ ...state, ...data }),
  subscribe: (setState: any) => subject.subscribe(setState),
  update: (payload: any) => {
    state = {
      ...state,
      ...payload,
    };
    subject.next(state);
  },
  initialState,
};

export default InstitutionStore;
