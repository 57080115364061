import React, { Fragment, useState } from "react";
import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import MoreVert from "@mui/icons-material/MoreVert";
import ProgressIndicator from "../../components/UI/ProgressIndicator";
import { Span } from "../../components/Typography";

export interface INotificationAction {
  label: string;
  onClick: (...args: any) => void;
  icon?: React.ReactNode | ((...args: any) => React.ReactNode);
  hidden?: boolean | ((...args: any) => boolean);
  disabled?: boolean | ((...args: any) => boolean);
  preventDefaultClick?: boolean;
}

const NotificationActionBtn: React.FC<{
  loading?: boolean;
  actions: INotificationAction[];
  metaData?: any;
  btnColor?: any;
}> = ({ actions, metaData, btnColor, loading = false }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleActionClick = (action: any, preventDefaultClick?: boolean) => {
    action && action(metaData);
    !preventDefaultClick && handleCloseMenu();
  };

  const isHidden = (action: any) => {
    if (!action?.hidden) return false;
    if (typeof action.hidden === "boolean") return action.hidden;
    return action?.hidden(metaData);
  };

  const isDisabled = (action: any) => {
    if (!action?.disabled) return false;
    if (typeof action.disabled === "boolean") return action.disabled;
    return action?.disabled(metaData);
  };

  return (
    <div>
      <Tooltip arrow={true} placement="top" title="Actions">
        <Button
          onClick={handleOpenMenu}
          color="inherit"
          variant={"contained"}
          sx={{ ...styles.closeButton }}
        >
          {loading ? (
            <ProgressIndicator size={20} />
          ) : (
            <MoreVert fontSize={"small"} />
          )}
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {actions.map((action: INotificationAction, index) => {
          return (
            <MenuItem
              disabled={isDisabled(action)}
              key={index}
              sx={{ display: !isHidden(action) ? "" : "none" }}
              onClick={() =>
                handleActionClick(action.onClick, action.preventDefaultClick)
              }
              hidden={isHidden(action)}
            >
              {typeof action.icon === "function" ? (
                <>{action.icon(metaData)}</>
              ) : (
                action.icon
              )}
              <Span sx={{ marginLeft: "0.5rem" }}>{action.label}</Span>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

const styles = {
  closeButton: {
    borderRadius: "50%",
    padding: "0 !important",
    width: "2rem",
    height: "2rem",
    minWidth: "2rem",
    backgroundColor: "white",

    "&:hover": {
      backgroundColor: "inherit.main",
      color: "black",
    },
  },
};

export default NotificationActionBtn;
