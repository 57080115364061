import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { formatDateToDDMMYYYY } from "../../../utils/date_formatter";
import { figureFormatter } from "../../../utils/figure_formatter";

interface IDetailsInfo {
  entityDetails: any;
}

const TransactionReversal: FC<IDetailsInfo> = ({ entityDetails }) => {
  return (
    <Card>
      <CardContent>
        <Typography
          sx={{ fontSize: 14, mb: 1.5, fontWeight: "bold" }}
          gutterBottom
        >
          Transaction Details
        </Typography>

        <Typography
          sx={{ fontSize: 14, mb: 1.5 }}
          color="text.disabled"
          gutterBottom
        >
          Transaction Type:
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
          {entityDetails?.type_display}
        </Typography>

        <Typography
          sx={{ fontSize: 14, mb: 1.5 }}
          color="text.disabled"
          gutterBottom
        >
          Date:
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
          {formatDateToDDMMYYYY(entityDetails?.createdat)}
        </Typography>

        <Typography
          sx={{ fontSize: 14, mb: 1.5 }}
          color="text.disabled"
          gutterBottom
        >
          Account:
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
          {entityDetails?.acc_name}
        </Typography>

        <Typography
          sx={{ fontSize: 14, mb: 1.5 }}
          color="text.disabled"
          gutterBottom
        >
          Reference:
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
          {entityDetails?.trxn_ref}
        </Typography>

        <Typography
          sx={{ fontSize: 14, mb: 1.5 }}
          color="text.disabled"
          gutterBottom
        >
          Amount:
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
          {figureFormatter({ figure: entityDetails?.amount })}
        </Typography>

        <Typography
          sx={{ fontSize: 14, mb: 1.5 }}
          color="text.disabled"
          gutterBottom
        >
          Description:
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
          {entityDetails?.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TransactionReversal;
