import { Box } from "@mui/material";
import React from "react";
import { Small } from "../../../components/Typography";

const PasswordResetMessage = () => {
  const containerStyle = {
    maxWidth: "500px",
    margin: "auto",
    padding: "20px",
    border: "1px solid #f1efef;",
    borderRadius: "8px",
    textAlign: "Justify",
    fontFamily: "Arial, sans-serif",
    fontSize: "18px",
  };

  const headingStyle = {
    fontSize: "2em",
    fontWeight: "bold",
    marginBottom: "10px",
  };

  const paragraphStyle = {
    marginBottom: "10px",
  };

  const signatureStyle = {
    fontStyle: "italic",
  };
  return (
    <Box sx={containerStyle}>
      <p style={headingStyle}>Hello there! 👋</p>
      <Small sx={paragraphStyle}>
        We received your request to reset your password. Great news! Help is on
        the way. Please keep an eye on your email for a password reset link. It
        should arrive shortly.
      </Small>
      <Small sx={paragraphStyle}>
        If you don't see it in your inbox, don't forget to check your spam
        folder—sometimes it likes to play hide and seek there.
      </Small>
      <Small sx={paragraphStyle}>Thanks for your patience</Small>
      <br />
      <Small sx={{ ...paragraphStyle, ...signatureStyle }}>
        Best, Zimba Technologies
      </Small>
    </Box>
  );
};

export default PasswordResetMessage;
