export const LOAN_STATUS_ACTIVE = "[2,3,4]";
export const LOAN_STATUS_DISBURSEMENT = "[1,8]";
export const LOAN_STATUS_APPLICATIONS = "[0,9]";
export const LOAN_STATUS_CLOSED = "5";
export const LOAN_STATUS_DECLINED = "6";
export const LOAN_STATUS_WRITTEN_OFF = "7";

export const LOAN_TYPES = [
  { name: "Active Loans", value: LOAN_STATUS_ACTIVE },
  { name: "Loan Disbursement", value: LOAN_STATUS_DISBURSEMENT },
  { name: "Loan Applications", value: LOAN_STATUS_APPLICATIONS },
  { name: "Closed Loans", value: LOAN_STATUS_CLOSED },
  { name: "Written Off Loans", value: LOAN_STATUS_WRITTEN_OFF },
  { name: "Declined Loans", value: LOAN_STATUS_DECLINED },
];

export const LOAN_COLLATERAL_TYPES = [
  {
    name: "Cash Collateral",
    value: "cash_collateral",
  },
  {
    name: "Electronics",
    value: "electronics",
  },
  {
    name: "Cars",
    value: "cars",
  },
  {
    name: "Land Titles",
    value: "land_titles",
  },
];
