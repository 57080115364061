import { FC } from "react";
import StatusBtn from "../../../components/UI/StatusBtn";
import { formatDateToDDMMYYYY } from "../../../utils/date_formatter";
import { CLIENT_TYPE_INDIVIDUAL } from "../../../constants/client";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IColumn } from "../../../components/UI/CustomTable/IColumn.interface";

export const styledTypography = {
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
    fontWeight: "bold",
  },
};

export const ClientDetailsLink = ({
  id,
  name,
}: {
  id: string;
  name: string;
}) => {
  const navigate = useNavigate();
  return (
    <Typography
      sx={styledTypography}
      color={"primary"}
      variant="h6"
      onClick={() => {
        navigate(`/clients/client-profile/${id}`);
      }}
    >
      {name}
    </Typography>
  );
};

const ClientColumnShape =
  (ViewAction: FC<{ id: string }>) =>
  (clientType: string): IColumn[] => [
    {
      Header: "Name",
      accessor: "name",
      columnKey: "name",
      minWidth: 200,
      Cell: ({ row }: any) => {
        const { user_details, id } = row.original;
        const name = getName(user_details, clientType);
        return <ClientDetailsLink id={id} name={name} />;
      },
      columnBgColor: ({ row }: any) =>
        row?.original?.is_dormant ? "#ffc5c18f" : "inherit",
    },
    {
      Header: "Email",
      accessor: "user_details.email",
      columnKey: "email",
      minWidth: 150,
      Cell: ({ row }: any) => {
        const { user_details } = row.original;
        return `${user_details.email || ""}`;
      },
      columnBgColor: ({ row }: any) =>
        row?.original?.is_dormant ? "#ffc5c18f" : "inherit",
    },
    {
      Header: "Branch",
      accessor: "branch_details.name",
      columnKey: "branch_details",
      minWidth: 150,
      Cell: ({ row }: any) => {
        return `${row.original?.branch_details?.name}`;
      },
      columnBgColor: ({ row }: any) =>
        row?.original?.is_dormant ? "#ffc5c18f" : "inherit",
    },
    {
      Header: "Status",
      accessor: "user_details.is_active",
      columnKey: "status",
      minWidth: 150,
      Cell: ({ row }: any) => {
        const { user_details } = row.original;
        return (
          <StatusBtn
            title={user_details.is_active ? "Active" : "Inactive"}
            type={user_details.is_active ? 1 : 0}
          />
        );
      },
      columnBgColor: ({ row }: any) =>
        row?.original?.is_dormant ? "#ffc5c18f" : "inherit",
    },
    {
      Header: "Membership",
      accessor: "is_member",
      columnKey: "is_member",
      minWidth: 150,
      Cell: ({ row }: any) => {
        const { is_member } = row.original;
        return (
          <StatusBtn title={is_member ? "Member" : "Not Member"} type={4} />
        );
      },
      columnBgColor: ({ row }: any) =>
        row?.original?.is_dormant ? "#ffc5c18f" : "inherit",
    },
    {
      Header: "Contact",
      accessor: "recommender",
      columnKey: "recommender",
      minWidth: 150,
      Cell: ({ row }: any) => {
        const { user_details } = row.original;
        return `${user_details.primarycellphone}`;
      },
      columnBgColor: ({ row }: any) =>
        row?.original?.is_dormant ? "#ffc5c18f" : "inherit",
    },
    {
      Header: "Joining Date",
      accessor: "date_joined",
      columnKey: "date_joined",
      minWidth: 150,
      Cell: ({ row }: any) => {
        const { user_details } = row.original;
        return formatDateToDDMMYYYY(`${user_details.date_joined}`);
      },
      columnBgColor: ({ row }: any) =>
        row?.original?.is_dormant ? "#ffc5c18f" : "inherit",
    },
    {
      Header: "Action",
      accessor: "action",
      columnKey: "action",
      minWidth: 100,
      maxWidth: 100,
      Cell: ({ row }: any) => {
        const data = row.original;
        return <ViewAction id={data.id} />;
      },
      columnBgColor: ({ row }: any) =>
        row?.original?.is_dormant ? "#ffc5c18f" : "inherit",
    },
  ];

export default ClientColumnShape;

export function getName(user_details: any, clientType: string) {
  if (clientType === CLIENT_TYPE_INDIVIDUAL) {
    return `${user_details?.first_name} ${user_details?.last_name}`;
  } else {
    return `${user_details?.shared_name}`;
  }
}
