import Slider from "react-slick";
import styled from "styled-components";
import { FPassword, ProfileSVG } from "../../assets/svg/SvgIcons";

const settings = {
  // fade: true,
  autoplay: true,
  speed: 1000,
  pauseOnHover: false,
  autoplaySpeed: 6000,
  slidesToShow: 1,
  arrows: false,
  dots: true,
};

function InfoTile({ data }: any) {
  const { title, desc, image, width } = data;
  return (
    <Wrapper className="whiteColor flexCenter flexColumn textCenter gap20">
      <SliderImg src={image} alt="image" width={width} />

      <div className="flexCenter flexColumn gap20" style={{ maxWidth: 500 }}>
        <h2>{title}</h2>
        <div className="font13">{desc}</div>
      </div>
    </Wrapper>
  );
}

const infoData = [
  {
    image: ProfileSVG,
    title: "Create Account",
    desc: (
      <p>
        To be able to use the Zimba system, open up a free account by clicking
        the <b> Register </b> button below the login form.
      </p>
    ),
  },
  {
    image: FPassword,
    title: "Forgot Your Password",
    desc: (
      <p>
        Have you forgotten your login Zimba password? Click on the
        <b> Forgotten Password </b> option or contact our{" "}
        <b> Help & Support </b>
        team for assistance.
      </p>
    ),
  },
  {
    image: ProfileSVG,
    title: "Create Account",
    desc: (
      <p>
        To be able to use the Zimba system, open up a free account by clicking
        the <b> Register </b> button below the login form.
      </p>
    ),
  },
  {
    image: FPassword,
    title: "Forgot Your Password",
    desc: (
      <p>
        Have you forgotten your login Zimba password? Click on the
        <b> Forgotten Password </b> option or contact our{" "}
        <b> Help & Support </b>
        team for assistance.
      </p>
    ),
  },
];

export default function InfoSlider() {
  return (
    <>
      {/* Slider */}
      <div
        data-aos="fade-up"
        data-aos-duration="1500"
        style={{ height: "100%" }}
      >
        <Slider {...settings}>
          {infoData.map((val, key) => {
            return <InfoTile data={val} key={key} />;
          })}
        </Slider>
      </div>
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 600px;
  padding: 40px;
`;

const SliderImg = styled.img`
  width: ${(props) => (props.width ? props.width : "350px")};
  marginbottom: 20px;
  @media (max-width: 760px) {
    width: ${(props) => (props.width ? props.width : "100%")};
  }
`;
