import React, { useState, FC } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Box, Button, Card, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FlexBox from "../../../components/FlexBox";
import LightTextField from "../../../components/LightTextField";
import { H1, Paragraph } from "../../../components/Typography";
import { PasswordResetService } from "./services/PasswordReset.service";
import { validationSchema } from "./models/Validations";
import { styled } from "styled-components";
import { primary } from "../../../theme/themeColors";
import { AlertMessage } from "../../../components/UI/AlertMessage";

const ForgotPassword: FC = () => {
  const [nonFieldErrors, setNonFieldErrors] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    email: "",
    submit: null,
  };

  const forgotPasswordForm = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: any) => {
      handleSubmit(values.email);
    },
  });
  const navigate = useNavigate();

  const handleSubmit = async (email: string) => {
    try {
      setLoading(true);
      await PasswordResetService.passwordReset({ email });
      navigate("/reset-password-page");
      setNonFieldErrors([]);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      const { non_field_errors, ...errors } = error.response.data;

      if (non_field_errors) {
        setNonFieldErrors(non_field_errors);
      }

      if (errors) {
        forgotPasswordForm.setErrors(errors);
      }
    }
  };

  return (
    <FlexBox
      data-aos="fade-up"
      data-aos-duration="800"
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: { sm: "100%" },
      }}
    >
      <Card sx={{ padding: 4, maxWidth: 600, marginTop: 4, boxShadow: 1 }}>
        <Box sx={{ padding: 2 }}>
          <Stack spacing={1}>
            <H1 fontSize={28} fontWeight={800} mb={3}>
              <span style={{ color: primary.main }}>Reset your</span> Password
            </H1>
            <Paragraph pb={3} fontSize={12}>
              Enter your email address, and we will send you a link to reset
              your password.
            </Paragraph>
          </Stack>

          <FlexBox justifyContent="space-between" flexWrap="wrap" my="1rem">
            {nonFieldErrors.length > 0 && (
              <Box>
                <AlertMessage
                  isOpen={nonFieldErrors.length > 0}
                  message={
                    <ul style={{ padding: 0, margin: 0 }}>
                      {nonFieldErrors.map((error: string, index: number) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  }
                  type="error"
                  closeAlert={() => setNonFieldErrors([])}
                />
              </Box>
            )}
            <form
              noValidate
              onSubmit={forgotPasswordForm.handleSubmit}
              style={{ width: "100%" }}
            >
              <Stack spacing={2}>
                <TextFieldWrapper>
                  <Paragraph fontWeight={600} mb={1}>
                    Email
                  </Paragraph>
                  <LightTextField
                    fullWidth
                    name="email"
                    type="email"
                    onBlur={forgotPasswordForm.handleBlur}
                    onChange={forgotPasswordForm.handleChange}
                    value={forgotPasswordForm.values.email || ""}
                    error={Boolean(
                      forgotPasswordForm.touched.email &&
                        forgotPasswordForm.errors.email,
                    )}
                    helperText={
                      forgotPasswordForm.touched.email &&
                      forgotPasswordForm.errors.email
                    }
                  />

                  <Box sx={{ mt: 4 }}>
                    {loading ? (
                      <LoadingButton loading fullWidth variant="contained">
                        Reset
                      </LoadingButton>
                    ) : (
                      <Button fullWidth type="submit" variant="contained">
                        Reset
                      </Button>
                    )}
                  </Box>
                </TextFieldWrapper>
              </Stack>
            </form>
          </FlexBox>
        </Box>
      </Card>
    </FlexBox>
  );
};

export default ForgotPassword;

const TextFieldWrapper = styled.div`
  width: 100%;
`;
