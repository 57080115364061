import { FC } from "react";
import {
  IFormatFigureProps,
  figureFormatter,
} from "../../utils/figure_formatter";
import { Typography } from "@mui/material";

const FormatFigure: FC<IFormatFigureProps> = (props) => {
  return (
    <Typography
      className={`${props?.className}`}
      display={"inline-block"}
      variant="h6"
      fontWeight={"bold"}
      fontSize={props.size}
      color={props.figure < 0 ? "error" : props.color || "primary"}
      sx={{ ...props?.extraStyles }}
    >
      {figureFormatter(props)}
    </Typography>
  );
};

export default FormatFigure;
