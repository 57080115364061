import React, { FC, useEffect, useRef, useState } from "react";
import uniqueId from "../../../utils/generateId";
import { Box, Button } from "@mui/material";
import CropOutlinedIcon from "@mui/icons-material/CropOutlined";
import "react-advanced-cropper/dist/style.css";
import { ModalDialog } from "../Modal/ModalDialog";
import { useModalContext } from "../../../contexts/ModalDialogContext";
import { deepDerefrencer } from "../../../utils/form_factory";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Small } from "../../Typography";
import ImageCropper from "../ImageCropper";

interface IFormImageCropperProps {
  name: string;
  label: string;
  formControl: any;
  accepts?: string; // comma separated values ['image/png', 'image/jpeg', 'image/jpg']
  preview?: string;
  isFixed?: boolean;
  stencilShape?: "circle" | "rectangle";
}

const FormImageCropper: FC<IFormImageCropperProps | any> = ({
  name,
  label,
  formControl,
  accepts,
  preview,
  stencilShape,
  isFixed = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [originalFile, setOriginalFile] = useState<File | null>(null);
  const [imageToCrop, setImageToCrop] = useState<string>();
  const { setShowModal, setModalId } = useModalContext();

  useEffect(() => {
    setImageToCrop(preview);
  }, [preview]);

  const openCropper = () => {
    setModalId(`croppie-${name}`);
    setShowModal(true);
  };

  const clearInput = () => {
    formControl.setFieldValue(name, null);
    setImageToCrop(undefined);
    setOriginalFile(null);

    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const ActionBtns: FC = () => {
    return (
      <>
        <Button
          onClick={() => {
            clearInput();
            setShowModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => setShowModal(false)}
          type="submit"
          variant="contained"
        >
          Crop
        </Button>
      </>
    );
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box
        sx={styles.fileContainer}
        onClick={
          deepDerefrencer(formControl.values, name) ? () => {} : openCropper
        }
      >
        {originalFile ? (
          ""
        ) : (
          <label
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "point",
            }}
          >
            <div style={{ marginRight: "10px" }}>
              <CropOutlinedIcon sx={{ fontSize: "15px" }} />
            </div>
            <div>{label}</div>
          </label>
        )}
        {deepDerefrencer(formControl.values, name) && (
          <div className="uploaded-list">
            <ul>
              <li
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                key={deepDerefrencer(formControl.values, name)?.name}
              >
                <span>{deepDerefrencer(formControl.values, name)?.name}</span>
                <Button
                  onClick={clearInput}
                  sx={styles.fileBtn}
                  variant="contained"
                  size="small"
                  color="error"
                >
                  <DeleteOutlinedIcon
                    color="inherit"
                    sx={{ fontSize: "12px" }}
                  />
                </Button>
              </li>
            </ul>
          </div>
        )}
      </Box>
      {Boolean(
        deepDerefrencer(formControl.touched, name) &&
          deepDerefrencer(formControl.errors, name),
      ) && (
        <Small
          color="error.main"
          fontSize="0.75rem"
          fontWeight={"normal"}
          marginTop={1}
        >
          {deepDerefrencer(formControl.errors, name)}
        </Small>
      )}

      <ModalDialog
        minWidth={600}
        selectedModalId={`croppie-${name}`}
        title={label}
        onClose={() => {}}
        id={uniqueId()}
        ActionButtons={ActionBtns}
      >
        <ImageCropper
          accepts={accepts}
          fieldName={name}
          originalFile={originalFile}
          setOriginalFile={setOriginalFile}
          setFieldValue={formControl.setFieldValue}
          setFieldTouched={formControl.setFieldTouched}
          inputRef={inputRef}
          imageToCrop={imageToCrop}
          setImageToCrop={setImageToCrop}
          isFixed={isFixed}
          stencilShape={stencilShape}
        />
      </ModalDialog>
    </Box>
  );
};

const styles = {
  fileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    minHeight: "3.5rem",
    width: "100%",
    height: "100%",
    border: "dotted 3px #e5eaf2",
    borderRadius: "8px",
    cursor: "pointer",
    position: "relative",
    backgroundColor: "#f5f5f5",

    "& .uploaded-list": {
      width: "100%",
      padding: "8px 20px",
      margin: 0,
      listSytle: "none !important",
      zIndex: 1000,
    },
    "& .uploaded-list ul": {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
    "& label": {
      width: "100%",
      textAlign: "center",
      alignSelf: "center",
      justifySelf: "center",
    },
    "& label div": {
      color: "#a9a9a9",
    },
  },
  fileBtn: {
    borderRadius: "50%",
    padding: "0 !important",
    width: "2rem",
    height: "2rem",
    minWidth: "2rem",
    backgroundColor: "error.main",
    color: "white",

    "&:hover": {
      backgroundColor: "white",
      color: "error.main",
    },
  },
};

export default FormImageCropper;
