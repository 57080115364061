import React, { FC, useEffect } from "react";
import uniqueId from "../../../utils/generateId";
import { Box, Button } from "@mui/material";
import { deepDerefrencer } from "../../../utils/form_factory";
import { Small } from "../../Typography";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

interface IFormFileInputProps {
  name: string;
  label: string;
  formControl: any;
  accepts?: string; // comma separated string eg. 'image/png, image/jpeg'
  multipleFiles?: boolean;
}

const FormFileInput: FC<IFormFileInputProps> = ({
  name,
  label,
  formControl,
  accepts,
  multipleFiles = false,
}) => {
  const [files, setFiles] = React.useState<File[]>([]);

  useEffect(() => {
    if (!deepDerefrencer(formControl.values, name)) {
      setFiles([]);
    }
  }, [deepDerefrencer(formControl.values, name)]);

  const handleChange = (newValue: FileList) => {
    setFiles([...Array.from(newValue)]);
    if (!multipleFiles) {
      formControl.setFieldValue(name, Array.from(newValue)[0]);
    } else {
      formControl.setFieldValue(name, Array.from(newValue));
    }
  };

  const removeFile = (index: number) => {
    formControl.setFieldValue(name, null);
    setFiles([]);
  };

  return (
    <Box>
      <Box sx={styles.fileContainer}>
        <input
          accept={accepts || "image/*"}
          style={{ width: "100%", height: "100%" }}
          id={uniqueId()}
          multiple={multipleFiles}
          type="file"
          onChange={(e: any) => handleChange(e.target.files)}
        />
        {files.length <= 0 && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {files.length > 0 && (
          <div className="uploaded-list">
            <ul>
              {files.map((file: File, index) => (
                <li
                  key={"file-" + index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span>{file?.name}</span>
                  <Button
                    onClick={() => removeFile(index)}
                    sx={styles.fileBtn}
                    variant="contained"
                    size="small"
                    color="error"
                  >
                    <DeleteOutlinedIcon
                      color="inherit"
                      sx={{ fontSize: "12px" }}
                    />
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </Box>
      {Boolean(
        deepDerefrencer(formControl.touched, name) &&
          deepDerefrencer(formControl.errors, name),
      ) && (
        <Small
          color="error.main"
          fontSize="0.75rem"
          fontWeight={"normal"}
          marginTop={1}
        >
          {deepDerefrencer(formControl.errors, name)}
        </Small>
      )}
    </Box>
  );
};

const styles = {
  fileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    minHeight: "3.5rem",
    width: "100%",
    height: "100%",
    border: "dotted 3px #e5eaf2",
    borderRadius: "8px",
    cursor: "pointer",
    position: "relative",
    backgroundColor: "#f5f5f5",

    "& input[type=file]::file-selector-button": {
      display: "none",
    },

    // for webkit/blink browsers
    "& input[type=file]::-webkit-file-upload-button": {
      display: "none",
    },

    "& input[type=file]": {
      position: "absolute",
      backgroundColor: "red",
      cursor: "pointer",
      opacity: 0,
    },
    "& .uploaded-list": {
      width: "100%",
      padding: "8px 20px",
      margin: 0,
      listSytle: "none !important",
      zIndex: 1000,
    },
    "& .uploaded-list ul": {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
    "& label": {
      width: "100%",
      textAlign: "center",
      alignSelf: "center",
      justifySelf: "center",
    },
    "& label span": {
      color: "#a9a9a9",
    },
  },
  fileBtn: {
    borderRadius: "50%",
    padding: "0 !important",
    width: "2rem",
    height: "2rem",
    minWidth: "2rem",
    backgroundColor: "error.main",
    color: "white",

    "&:hover": {
      backgroundColor: "white",
      color: "error.main",
    },
  },
};

export default FormFileInput;
