import { CssBaseline, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { FC, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useRoutes } from "react-router-dom";
import routes from "./routes/routes";
import { ukoTheme } from "./theme";
import InternetConnection from "./components/UI/InternetConnection";
import { firebaseAuthentication } from "./utils/firebase";
import { useAuth } from "./hooks/useAuth";

const App: FC = () => {
  const { fbtoken }: any = useAuth();
  const allPages = useRoutes(routes);

  // App theme
  const appTheme = ukoTheme();

  // toaster options
  const toasterOptions = {
    style: {
      fontWeight: 500,
      fontFamily: "'Montserrat', sans-serif",
    },
  };

  useEffect(() => {
    const firebaseAuth = async () => {
      await firebaseAuthentication(fbtoken);
    };

    if (fbtoken) {
      firebaseAuth();
    }
  }, [fbtoken]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <InternetConnection />
        <Toaster toastOptions={toasterOptions} />
        {allPages}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
