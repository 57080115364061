import * as React from "react";
import { debounce } from "lodash";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { ukoTheme } from "../../../theme";
import { FC } from "react";
import { deepDerefrencer } from "../../../utils/form_factory";
import { Chip } from "@mui/material";

interface IFormMultiSelectInputProps {
  name: string;
  label: string;
  type?: string;
  options?: any[];
  parseFilter?: (...params: any) => any;
  filterCriteria?: string;
  filterValues?: any;
  selector?: {
    value: (option: any) => any;
    label: (option: any) => any;
  };
  dataFetcher?: (
    search: string,
    setData: (results: any) => void,
    extraFilters?: any,
  ) => Promise<any> | any;
  formControl: any;
  isDisabled?: boolean;
  isHidden?: boolean;
}

const FormMultiSelectSearchInput: FC<IFormMultiSelectInputProps> = (
  props: IFormMultiSelectInputProps,
) => {
  const theme = ukoTheme();

  const [searchText, setSearchText] = React.useState("");
  const [optionsList, setOptionsList] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);

  const isFieldRequired = (fieldName: string) => {
    return String(props.formControl.getFieldMeta(fieldName)?.error).match(
      /required/gi,
    )
      ? true
      : false;
  };

  const handleOptionList = (options: any[]) => {
    setOptionsList((prev: any[]) => {
      return [
        ...deepDerefrencer(props.formControl.values, props.name),
        ...options,
      ];
    });
  };

  React.useEffect(() => {
    const debounceSearch = debounce(async () => {
      const filters = props.parseFilter ? props.parseFilter() : {};
      if (props.dataFetcher) {
        setLoading(true);
        await props.dataFetcher(searchText, handleOptionList, { ...filters });
        setLoading(false);
      }
    }, 500);

    if (searchText) {
      debounceSearch();
    }

    return () => {
      debounceSearch.cancel();
    };
  }, [searchText]);

  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      onChange={(_, newValue: any[]) => {
        const uniqueOptions = Array.from(new Set(newValue));
        props.formControl.getFieldProps(props.name).onChange({
          target: {
            name: props.name,
            value: uniqueOptions,
          },
        });
      }}
      autoComplete={false}
      loading={loading}
      options={optionsList}
      getOptionLabel={(option) => props.selector?.label(option)}
      value={deepDerefrencer(props.formControl.values, props.name)}
      defaultValue={deepDerefrencer(props.formControl.values, props.name)}
      filterSelectedOptions={true}
      filterOptions={(options: any[], state: any) => {
        const displayOptions: any[] = options;
        const selectedOptions: any[] = deepDerefrencer(
          props.formControl.values,
          props.name,
        );

        const filteredOptions = displayOptions.filter((option: any) => {
          return !selectedOptions
            .map((selected: any) =>
              deepDerefrencer(selected, props.filterCriteria),
            )
            .includes(deepDerefrencer(option, props.filterCriteria));
        });
        return filteredOptions;
      }}
      renderTags={(value: any, getTagProps) => {
        return value.map((option: any, index: number) => {
          return (
            <Chip
              {...getTagProps({ index })}
              label={props.selector?.label(option)}
            />
          );
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={[
            styles.customStyles(
              theme,
              isFieldRequired(props.name)
                ? theme.palette.primary.main
                : undefined,
            ),
          ]}
          label={props.label}
          placeholder="Search..."
          disabled={props.isDisabled}
          hidden={props.isHidden}
          error={Boolean(
            deepDerefrencer(props.formControl.touched, props.name) &&
              deepDerefrencer(props.formControl.errors, props.name),
          )}
          InputProps={{
            ...params.InputProps,
            value: searchText,
            onChange: (e) => setSearchText(e.target.value),
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          helperText={
            deepDerefrencer(props.formControl.touched, props.name) &&
            deepDerefrencer(props.formControl.errors, props.name)
          }
        />
      )}
    />
  );
};

export default FormMultiSelectSearchInput;

const styles = {
  customStyles: (theme: any, outlineColor?: string) => ({
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      border: "2px solid",
      borderColor: outlineColor
        ? outlineColor
        : theme.palette.mode === "light"
        ? theme.palette.secondary[300]
        : theme.palette.divider,
    },

    "& input[type='file'].MuiInputBase-input": {
      marginLeft: "120px",
    },
  }),
};
