export const CLIENT_TYPE_INDIVIDUAL = "individual";
export const CLIENT_TYPE_INSTITUTION = "institution";
export const CLIENT_TYPE_GROUP = "group";

export const CLIENT_MEMEBERSHIP_TYPE = [
  {
    name: "Member",
    value: true,
  },
  {
    name: "Non-Member",
    value: false,
  },
];

export const CLIENT_TYPES = [
  { name: "Individual", value: CLIENT_TYPE_INDIVIDUAL },
  { name: "Institution", value: CLIENT_TYPE_INSTITUTION },
  { name: "Group", value: CLIENT_TYPE_GROUP },
];

export const BUSINESS_TYPES = [
  {
    name: "Sole Proprietor",
    value: "sole_proprietor",
  },
  {
    name: "Cooperative (saccos, credit unions etc)",
    value: "cooperative",
  },
  {
    name: "General Partnership",
    value: "general_partnership",
  },
  {
    name: "Limited Partnership",
    value: "limited_partnership",
  },
  {
    name: "Limited Liability Partnership",
    value: "limited_liability_partnership",
  },
  {
    name: "Limited Liability Company",
    value: "limited_liability_company",
  },
  {
    name: "Corporation",
    value: "corporation",
  },
  {
    name: "Unincorporated Associations (Clubs, Civic Groups etc.)",
    value: "unincorporated",
  },
];
