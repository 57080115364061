import * as React from "react";
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    id="Layer_1"
    width={160}
    height={48}
    x={0}
    y={0}
    viewBox="0 0 160 153"
    {...props}
  >
    <defs id="defs14">
      <linearGradient
        xlinkHref="#SVGID_1_"
        id="linearGradient36"
        x1={5.032}
        x2={120.448}
        y1={89.598}
        y2={22.962}
        gradientTransform="translate(-54.393)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        xlinkHref="#SVGID_1_"
        id="linearGradient40"
        x1={5.032}
        x2={120.448}
        y1={89.598}
        y2={22.962}
        gradientTransform="translate(-54.393)"
        gradientUnits="userSpaceOnUse"
      />
    </defs>
    <style id="style1" type="text/css">
      {".st0{fill:url(#SVGID_1_)}.st1{fill:#357d57}"}
    </style>
    <g id="g14" transform="translate(20.845 -47.922)">
      <linearGradient
        id="SVGID_1_"
        x1={5.032}
        x2={120.448}
        y1={89.598}
        y2={22.962}
        gradientTransform="translate(-54.393)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="stop1"
          offset={0}
          style={{
            stopColor: "#e5cc9c",
          }}
        />
        <stop
          id="stop2"
          offset={0.319}
          style={{
            stopColor: "#e7cc98",
          }}
        />
        <stop
          id="stop3"
          offset={0.5}
          style={{
            stopColor: "#ebcb92",
          }}
        />
        <stop
          id="stop4"
          offset={0.622}
          style={{
            stopColor: "#d1af7a",
          }}
        />
        <stop
          id="stop5"
          offset={0.769}
          style={{
            stopColor: "#b79463",
          }}
        />
        <stop
          id="stop6"
          offset={0.9}
          style={{
            stopColor: "#a78455",
          }}
        />
        <stop
          id="stop7"
          offset={1}
          style={{
            stopColor: "#a27e50",
          }}
        />
      </linearGradient>
      <g id="g16" transform="translate(15.923 628.978)">
        <path
          id="path7"
          d="M10.307 0c2.1.6 4.4.3 6.6.6 13.1 1.7 24.7 6.8 34.6 15.5 10.6 9.3 17.2 20.9 20.2 34.6 1.2 5.6 1.4 11.2 1.2 16.9-.1 2.3.2 2.5 2.5 2.7 5.8.3 11.7.7 17.5 1 .5 0 1-.1 1.5.7-35.7 6.5-70.8 15.4-105.9 24.6 19.4-23.2 38.3-46.8 56.9-70.8-4.1 0-63.3 12.6-68.8 14.7 15.9-.5 31.5-1.9 47.1-3.2.3.7-.2 1-.5 1.2-10 10.5-19.6 21.4-29.2 32.2-10 11.4-20 22.9-29.8 34.5-1.4 1.7-1.7 1.7-3.1 0-9-10.3-14.2-22.4-15.3-36-1.3-17.1 3.3-32.5 14.3-45.8 10.5-12.7 24-20.2 40.3-22.7 2.6-.4 5.2-.5 7.9-.8.7.1 1.3.1 2 .1z"
          className="st0"
          style={{
            fill: "url(#linearGradient40)",
          }}
        />
        <path
          id="path11"
          d="M-37.28 119.3c2.9-.9 5.3-1.6 7.8-2.3 20.7-6.1 41.3-12.4 61.8-19 14.4-4.7 28.8-9.6 43-14.8.6-.2 1.2-.7 1.7-.3.6.5 0 1.3-.2 1.8-9.4 24.4-27 38.7-53.1 42.3-12.6 1.7-24.7-.7-36.1-6.4-.9-.5-1.9-.5-2.9-.5-6.6-.1-13.2-.2-19.9-.3-.4-.1-1.1 0-2.1-.5z"
          className="st1"
        />
        <g id="g15" transform="translate(-317.55 140.565)">
          <path
            id="path8"
            d="M500 98.1c-3.7 0-7.4 0-11.1.1-1.1 0-1.6-.4-1.6-1.5.1-1.4 0-2.8 0-4.7-3 3.6-6.6 5.6-10.8 6.3-7.1 1.3-14 1.1-20.3-3-5.1-3.3-7.6-8.2-7.2-14.3.5-6.8 4.2-11.3 10.5-13.7 4.1-1.5 8.4-1.8 12.7-1.9 4.1-.1 8.2-.1 12.3 0 1.4 0 1.8-.4 1.8-1.8-.1-5.5-4.1-9.7-9.9-10.3-5-.6-9.9-.2-14.9-.3h-6.8c-1.1 0-1.6-.4-1.6-1.6v-8.1c0-.9.3-1.4 1.4-1.4 8.7.2 17.4-.6 26 .4 7.7.9 14 4.3 17.3 11.9 1.1 2.5 1.7 5.1 1.9 7.8 0 .6 0 1.2.3 1.8v34.3zm-13.6-19.8v-1.6c0-2.3 0-2.3-2.3-2.3H473c-1.8 0-3.6.2-5.4.7-3.3.9-5.2 3-5.4 5.9-.2 3.3 1.4 6 4.7 7.1 5.1 1.8 10 1.3 14.6-1.5 3.3-1.9 5.4-4.6 4.9-8.3z"
            className="st1"
          />
          <path
            id="path9"
            d="M285.7 48.4c.8 0 1.1-.5 1.4-.8 7.9-7.2 22.6-7.8 31.1-1.3 1.2.9 2.4 2 3.2 3.3.8 1.2 1.2 1 2.1.1 8-8.7 22.8-9.7 31.4-5.3 5.1 2.6 8.3 6.7 9.9 12 .9 3 1.3 6.1 1.3 9.3v30.4c0 2.1-.1 2.2-2.1 2.2-3.1 0-6.2-.1-9.3 0-1.5 0-2-.5-2-2 .1-9.1 0-18.3 0-27.4 0-2.4-.2-4.8-.9-7.2-1.3-4.5-4.2-7.1-8.8-7.9-8.6-1.5-15.7 3.9-16.7 12.6-.5 4.2-.2 8.4-.3 12.5-.1 5.7 0 11.5 0 17.2 0 2.2 0 2.2-2.2 2.2-3 0-6-.1-8.9 0-1.7.1-2.2-.5-2.2-2.2.1-9.7 0-19.4 0-29.1 0-1.4-.2-2.9-.5-4.3-1.7-7.5-7.3-10.2-14.9-8.9-6.7 1.2-10.9 6.4-11 13.7-.1 9.3-.1 18.6-.1 27.9 0 2.8 0 2.8-2.7 2.8h-8.4c-2.4 0-2.4 0-2.4-2.4V44.2c-.1-1.2.4-1.8 1.7-1.8 3.1.1 6.3.1 9.4 0 1.4 0 1.9.6 1.8 1.9 0 1.4.1 2.8.1 4.1z"
            className="st1"
          />
          <path
            id="path10"
            d="M395.7 48.8c3.6-3.3 7.3-5.5 11.7-6.4 18-3.4 34 7.7 34.3 27.1.1 8.3-2.2 15.8-8.3 21.7-4.7 4.7-10.5 7-17.1 7.5-5.4.4-10.7-.2-15.6-2.8-2-1.1-3.7-2.6-5.5-4.2-.5 1.5-.3 2.9-.2 4.3.1 1.5-.4 2.2-2 2.1-2.9-.1-5.7-.1-8.6 0-1.6.1-2.1-.5-2.1-2.1.1-10.5 0-20.9 0-31.4V23c0-2.3 0-2.3 2.3-2.3 3 0 6.1.1 9.1 0 1.5 0 2 .5 2 2-.1 7.9 0 15.9 0 23.8v2.3zm0 21.4c0 .8-.1 1.5 0 2.3.7 6.6 3.8 11.6 10.1 13.9 6.3 2.3 12.3 1.5 17.4-3.3 4.2-3.9 5.1-8.9 4.9-14.3-.3-10-9.1-17.1-19-15.3-8.4 1.4-13.4 7.7-13.4 16.7z"
            className="st1"
          />
          <path
            id="path12"
            d="M194 86.3c.6.4 1.2.2 1.8.2h28.5c1.6 0 2.3.4 2.2 2.1-.1 2.4 0 4.7 0 7.1 0 2.5 0 2.5-2.4 2.5h-47.2c-1.3 0-1.8-.4-1.8-1.7.1-2.1.1-4.2 0-6.3 0-1.1.5-1.9 1.2-2.6 5.8-6.7 11.5-13.3 17.3-20 3.6-4.2 7.2-8.3 10.8-12.5.3-.4.9-.7.7-1.2-.2-.5-.9-.3-1.4-.3h-25.8c-2.4 0-2.4 0-2.4-2.5v-7.4c0-1.2.4-1.8 1.7-1.8h45.4c1.2 0 1.7.4 1.7 1.6-.1 2.1 0 4.3 0 6.4 0 1-.4 1.7-1 2.4-9.3 10.7-18.6 21.5-27.9 32.2-.6.6-1.2 1-1.4 1.8z"
            className="st1"
          />
          <path
            id="path13"
            d="M256.2 70.4V96c0 2.1-.1 2.2-2.3 2.2h-8.8c-2.3 0-2.3 0-2.3-2.3V54.6c0-3.4.1-6.8 0-10.2 0-1.5.5-1.9 1.9-1.9 3.2.1 6.4.1 9.6 0 1.5 0 1.9.6 1.9 2v25.9z"
            className="st1"
          />
          <path
            id="path14"
            d="M242.2 28.2c0-4 3.3-7.3 7.2-7.3 4.1 0 7.5 3.4 7.5 7.4s-3.5 7.4-7.4 7.3c-4.1-.1-7.3-3.4-7.3-7.4z"
            className="st1"
          />
        </g>
      </g>
      <g id="g24" transform="translate(520.728 3.195)">
        <path
          id="path16"
          d="M10.307 0c2.1.6 4.4.3 6.6.6 13.1 1.7 24.7 6.8 34.6 15.5 10.6 9.3 17.2 20.9 20.2 34.6 1.2 5.6 1.4 11.2 1.2 16.9-.1 2.3.2 2.5 2.5 2.7 5.8.3 11.7.7 17.5 1 .5 0 1-.1 1.5.7-35.7 6.5-70.8 15.4-105.9 24.6 19.4-23.2 38.3-46.8 56.9-70.8-4.1 0-63.3 12.6-68.8 14.7 15.9-.5 31.5-1.9 47.1-3.2.3.7-.2 1-.5 1.2-10 10.5-19.6 21.4-29.2 32.2-10 11.4-20 22.9-29.8 34.5-1.4 1.7-1.7 1.7-3.1 0-9-10.3-14.2-22.4-15.3-36-1.3-17.1 3.3-32.5 14.3-45.8 10.5-12.7 24-20.2 40.3-22.7 2.6-.4 5.2-.5 7.9-.8.7.1 1.3.1 2 .1z"
          className="st0"
          style={{
            fill: "#fff",
            fillOpacity: 1,
          }}
        />
        <path
          id="path17"
          d="M-37.28 119.3c2.9-.9 5.3-1.6 7.8-2.3 20.7-6.1 41.3-12.4 61.8-19 14.4-4.7 28.8-9.6 43-14.8.6-.2 1.2-.7 1.7-.3.6.5 0 1.3-.2 1.8-9.4 24.4-27 38.7-53.1 42.3-12.6 1.7-24.7-.7-36.1-6.4-.9-.5-1.9-.5-2.9-.5-6.6-.1-13.2-.2-19.9-.3-.4-.1-1.1 0-2.1-.5z"
          className="st1"
          style={{
            fill: "#fff",
            fillOpacity: 1,
          }}
        />
        <g
          id="g23"
          style={{
            fill: "#fff",
            fillOpacity: 1,
          }}
          transform="translate(-317.55 140.565)"
        >
          <path
            id="path18"
            d="M500 98.1c-3.7 0-7.4 0-11.1.1-1.1 0-1.6-.4-1.6-1.5.1-1.4 0-2.8 0-4.7-3 3.6-6.6 5.6-10.8 6.3-7.1 1.3-14 1.1-20.3-3-5.1-3.3-7.6-8.2-7.2-14.3.5-6.8 4.2-11.3 10.5-13.7 4.1-1.5 8.4-1.8 12.7-1.9 4.1-.1 8.2-.1 12.3 0 1.4 0 1.8-.4 1.8-1.8-.1-5.5-4.1-9.7-9.9-10.3-5-.6-9.9-.2-14.9-.3h-6.8c-1.1 0-1.6-.4-1.6-1.6v-8.1c0-.9.3-1.4 1.4-1.4 8.7.2 17.4-.6 26 .4 7.7.9 14 4.3 17.3 11.9 1.1 2.5 1.7 5.1 1.9 7.8 0 .6 0 1.2.3 1.8v34.3zm-13.6-19.8v-1.6c0-2.3 0-2.3-2.3-2.3H473c-1.8 0-3.6.2-5.4.7-3.3.9-5.2 3-5.4 5.9-.2 3.3 1.4 6 4.7 7.1 5.1 1.8 10 1.3 14.6-1.5 3.3-1.9 5.4-4.6 4.9-8.3z"
            className="st1"
            style={{
              fill: "#fff",
              fillOpacity: 1,
            }}
          />
          <path
            id="path19"
            d="M285.7 48.4c.8 0 1.1-.5 1.4-.8 7.9-7.2 22.6-7.8 31.1-1.3 1.2.9 2.4 2 3.2 3.3.8 1.2 1.2 1 2.1.1 8-8.7 22.8-9.7 31.4-5.3 5.1 2.6 8.3 6.7 9.9 12 .9 3 1.3 6.1 1.3 9.3v30.4c0 2.1-.1 2.2-2.1 2.2-3.1 0-6.2-.1-9.3 0-1.5 0-2-.5-2-2 .1-9.1 0-18.3 0-27.4 0-2.4-.2-4.8-.9-7.2-1.3-4.5-4.2-7.1-8.8-7.9-8.6-1.5-15.7 3.9-16.7 12.6-.5 4.2-.2 8.4-.3 12.5-.1 5.7 0 11.5 0 17.2 0 2.2 0 2.2-2.2 2.2-3 0-6-.1-8.9 0-1.7.1-2.2-.5-2.2-2.2.1-9.7 0-19.4 0-29.1 0-1.4-.2-2.9-.5-4.3-1.7-7.5-7.3-10.2-14.9-8.9-6.7 1.2-10.9 6.4-11 13.7-.1 9.3-.1 18.6-.1 27.9 0 2.8 0 2.8-2.7 2.8h-8.4c-2.4 0-2.4 0-2.4-2.4V44.2c-.1-1.2.4-1.8 1.7-1.8 3.1.1 6.3.1 9.4 0 1.4 0 1.9.6 1.8 1.9 0 1.4.1 2.8.1 4.1z"
            className="st1"
            style={{
              fill: "#fff",
              fillOpacity: 1,
            }}
          />
          <path
            id="path20"
            d="M395.7 48.8c3.6-3.3 7.3-5.5 11.7-6.4 18-3.4 34 7.7 34.3 27.1.1 8.3-2.2 15.8-8.3 21.7-4.7 4.7-10.5 7-17.1 7.5-5.4.4-10.7-.2-15.6-2.8-2-1.1-3.7-2.6-5.5-4.2-.5 1.5-.3 2.9-.2 4.3.1 1.5-.4 2.2-2 2.1-2.9-.1-5.7-.1-8.6 0-1.6.1-2.1-.5-2.1-2.1.1-10.5 0-20.9 0-31.4V23c0-2.3 0-2.3 2.3-2.3 3 0 6.1.1 9.1 0 1.5 0 2 .5 2 2-.1 7.9 0 15.9 0 23.8v2.3zm0 21.4c0 .8-.1 1.5 0 2.3.7 6.6 3.8 11.6 10.1 13.9 6.3 2.3 12.3 1.5 17.4-3.3 4.2-3.9 5.1-8.9 4.9-14.3-.3-10-9.1-17.1-19-15.3-8.4 1.4-13.4 7.7-13.4 16.7z"
            className="st1"
            style={{
              fill: "#fff",
              fillOpacity: 1,
            }}
          />
          <path
            id="path21"
            d="M194 86.3c.6.4 1.2.2 1.8.2h28.5c1.6 0 2.3.4 2.2 2.1-.1 2.4 0 4.7 0 7.1 0 2.5 0 2.5-2.4 2.5h-47.2c-1.3 0-1.8-.4-1.8-1.7.1-2.1.1-4.2 0-6.3 0-1.1.5-1.9 1.2-2.6 5.8-6.7 11.5-13.3 17.3-20 3.6-4.2 7.2-8.3 10.8-12.5.3-.4.9-.7.7-1.2-.2-.5-.9-.3-1.4-.3h-25.8c-2.4 0-2.4 0-2.4-2.5v-7.4c0-1.2.4-1.8 1.7-1.8h45.4c1.2 0 1.7.4 1.7 1.6-.1 2.1 0 4.3 0 6.4 0 1-.4 1.7-1 2.4-9.3 10.7-18.6 21.5-27.9 32.2-.6.6-1.2 1-1.4 1.8z"
            className="st1"
            style={{
              fill: "#fff",
              fillOpacity: 1,
            }}
          />
          <path
            id="path22"
            d="M256.2 70.4V96c0 2.1-.1 2.2-2.3 2.2h-8.8c-2.3 0-2.3 0-2.3-2.3V54.6c0-3.4.1-6.8 0-10.2 0-1.5.5-1.9 1.9-1.9 3.2.1 6.4.1 9.6 0 1.5 0 1.9.6 1.9 2v25.9z"
            className="st1"
            style={{
              fill: "#fff",
              fillOpacity: 1,
            }}
          />
          <path
            id="path23"
            d="M242.2 28.2c0-4 3.3-7.3 7.2-7.3 4.1 0 7.5 3.4 7.5 7.4s-3.5 7.4-7.4 7.3c-4.1-.1-7.3-3.4-7.3-7.4z"
            className="st1"
            style={{
              fill: "#fff",
              fillOpacity: 1,
            }}
          />
        </g>
      </g>
      <g id="g26" transform="translate(167.273 709.15)">
        <g id="g28" transform="translate(389.753 -288.916)">
          <g id="g27">
            <path
              id="path1"
              d="M10.307 0c2.1.6 4.4.3 6.6.6 13.1 1.7 24.7 6.8 34.6 15.5 10.6 9.3 17.2 20.9 20.2 34.6 1.2 5.6 1.4 11.2 1.2 16.9-.1 2.3.2 2.5 2.5 2.7 5.8.3 11.7.7 17.5 1 .5 0 1-.1 1.5.7-35.7 6.5-70.8 15.4-105.9 24.6 19.4-23.2 38.3-46.8 56.9-70.8-4.1 0-63.3 12.6-68.8 14.7 15.9-.5 31.5-1.9 47.1-3.2.3.7-.2 1-.5 1.2-10 10.5-19.6 21.4-29.2 32.2-10 11.4-20 22.9-29.8 34.5-1.4 1.7-1.7 1.7-3.1 0-9-10.3-14.2-22.4-15.3-36-1.3-17.1 3.3-32.5 14.3-45.8 10.5-12.7 24-20.2 40.3-22.7 2.6-.4 5.2-.5 7.9-.8.7.1 1.3.1 2 .1z"
              className="st0"
              style={{
                fill: "#fff",
                fillOpacity: 1,
              }}
            />
            <path
              id="path2"
              d="M-37.28 119.3c2.9-.9 5.3-1.6 7.8-2.3 20.7-6.1 41.3-12.4 61.8-19 14.4-4.7 28.8-9.6 43-14.8.6-.2 1.2-.7 1.7-.3.6.5 0 1.3-.2 1.8-9.4 24.4-27 38.7-53.1 42.3-12.6 1.7-24.7-.7-36.1-6.4-.9-.5-1.9-.5-2.9-.5-6.6-.1-13.2-.2-19.9-.3-.4-.1-1.1 0-2.1-.5z"
              className="st1"
              style={{
                fill: "#fff",
                fillOpacity: 1,
              }}
            />
          </g>
          <g
            id="g25"
            style={{
              fill: "#fff",
              fillOpacity: 1,
            }}
            transform="translate(-67.156 3.862)"
          >
            <path
              id="path3"
              d="M500 98.1c-3.7 0-7.4 0-11.1.1-1.1 0-1.6-.4-1.6-1.5.1-1.4 0-2.8 0-4.7-3 3.6-6.6 5.6-10.8 6.3-7.1 1.3-14 1.1-20.3-3-5.1-3.3-7.6-8.2-7.2-14.3.5-6.8 4.2-11.3 10.5-13.7 4.1-1.5 8.4-1.8 12.7-1.9 4.1-.1 8.2-.1 12.3 0 1.4 0 1.8-.4 1.8-1.8-.1-5.5-4.1-9.7-9.9-10.3-5-.6-9.9-.2-14.9-.3h-6.8c-1.1 0-1.6-.4-1.6-1.6v-8.1c0-.9.3-1.4 1.4-1.4 8.7.2 17.4-.6 26 .4 7.7.9 14 4.3 17.3 11.9 1.1 2.5 1.7 5.1 1.9 7.8 0 .6 0 1.2.3 1.8v34.3zm-13.6-19.8v-1.6c0-2.3 0-2.3-2.3-2.3H473c-1.8 0-3.6.2-5.4.7-3.3.9-5.2 3-5.4 5.9-.2 3.3 1.4 6 4.7 7.1 5.1 1.8 10 1.3 14.6-1.5 3.3-1.9 5.4-4.6 4.9-8.3z"
              className="st1"
              style={{
                fill: "#fff",
                fillOpacity: 1,
              }}
            />
            <path
              id="path4"
              d="M285.7 48.4c.8 0 1.1-.5 1.4-.8 7.9-7.2 22.6-7.8 31.1-1.3 1.2.9 2.4 2 3.2 3.3.8 1.2 1.2 1 2.1.1 8-8.7 22.8-9.7 31.4-5.3 5.1 2.6 8.3 6.7 9.9 12 .9 3 1.3 6.1 1.3 9.3v30.4c0 2.1-.1 2.2-2.1 2.2-3.1 0-6.2-.1-9.3 0-1.5 0-2-.5-2-2 .1-9.1 0-18.3 0-27.4 0-2.4-.2-4.8-.9-7.2-1.3-4.5-4.2-7.1-8.8-7.9-8.6-1.5-15.7 3.9-16.7 12.6-.5 4.2-.2 8.4-.3 12.5-.1 5.7 0 11.5 0 17.2 0 2.2 0 2.2-2.2 2.2-3 0-6-.1-8.9 0-1.7.1-2.2-.5-2.2-2.2.1-9.7 0-19.4 0-29.1 0-1.4-.2-2.9-.5-4.3-1.7-7.5-7.3-10.2-14.9-8.9-6.7 1.2-10.9 6.4-11 13.7-.1 9.3-.1 18.6-.1 27.9 0 2.8 0 2.8-2.7 2.8h-8.4c-2.4 0-2.4 0-2.4-2.4V44.2c-.1-1.2.4-1.8 1.7-1.8 3.1.1 6.3.1 9.4 0 1.4 0 1.9.6 1.8 1.9 0 1.4.1 2.8.1 4.1z"
              className="st1"
              style={{
                fill: "#fff",
                fillOpacity: 1,
              }}
            />
            <path
              id="path5"
              d="M395.7 48.8c3.6-3.3 7.3-5.5 11.7-6.4 18-3.4 34 7.7 34.3 27.1.1 8.3-2.2 15.8-8.3 21.7-4.7 4.7-10.5 7-17.1 7.5-5.4.4-10.7-.2-15.6-2.8-2-1.1-3.7-2.6-5.5-4.2-.5 1.5-.3 2.9-.2 4.3.1 1.5-.4 2.2-2 2.1-2.9-.1-5.7-.1-8.6 0-1.6.1-2.1-.5-2.1-2.1.1-10.5 0-20.9 0-31.4V23c0-2.3 0-2.3 2.3-2.3 3 0 6.1.1 9.1 0 1.5 0 2 .5 2 2-.1 7.9 0 15.9 0 23.8v2.3zm0 21.4c0 .8-.1 1.5 0 2.3.7 6.6 3.8 11.6 10.1 13.9 6.3 2.3 12.3 1.5 17.4-3.3 4.2-3.9 5.1-8.9 4.9-14.3-.3-10-9.1-17.1-19-15.3-8.4 1.4-13.4 7.7-13.4 16.7z"
              className="st1"
              style={{
                fill: "#fff",
                fillOpacity: 1,
              }}
            />
            <path
              id="path6"
              d="M194 86.3c.6.4 1.2.2 1.8.2h28.5c1.6 0 2.3.4 2.2 2.1-.1 2.4 0 4.7 0 7.1 0 2.5 0 2.5-2.4 2.5h-47.2c-1.3 0-1.8-.4-1.8-1.7.1-2.1.1-4.2 0-6.3 0-1.1.5-1.9 1.2-2.6 5.8-6.7 11.5-13.3 17.3-20 3.6-4.2 7.2-8.3 10.8-12.5.3-.4.9-.7.7-1.2-.2-.5-.9-.3-1.4-.3h-25.8c-2.4 0-2.4 0-2.4-2.5v-7.4c0-1.2.4-1.8 1.7-1.8h45.4c1.2 0 1.7.4 1.7 1.6-.1 2.1 0 4.3 0 6.4 0 1-.4 1.7-1 2.4-9.3 10.7-18.6 21.5-27.9 32.2-.6.6-1.2 1-1.4 1.8z"
              className="st1"
              style={{
                fill: "#fff",
                fillOpacity: 1,
              }}
            />
            <path
              id="path15"
              d="M256.2 70.4V96c0 2.1-.1 2.2-2.3 2.2h-8.8c-2.3 0-2.3 0-2.3-2.3V54.6c0-3.4.1-6.8 0-10.2 0-1.5.5-1.9 1.9-1.9 3.2.1 6.4.1 9.6 0 1.5 0 1.9.6 1.9 2v25.9z"
              className="st1"
              style={{
                fill: "#fff",
                fillOpacity: 1,
              }}
            />
            <path
              id="path24"
              d="M242.2 28.2c0-4 3.3-7.3 7.2-7.3 4.1 0 7.5 3.4 7.5 7.4s-3.5 7.4-7.4 7.3c-4.1-.1-7.3-3.4-7.3-7.4z"
              className="st1"
              style={{
                fill: "#fff",
                fillOpacity: 1,
              }}
            />
          </g>
        </g>
      </g>
      <g id="g36" transform="translate(-11.943 382.164)">
        <g id="g40" transform="translate(-77.627 39.809)">
          <g id="g39">
            <g id="g38">
              <path
                id="path28"
                d="M10.307 0c2.1.6 4.4.3 6.6.6 13.1 1.7 24.7 6.8 34.6 15.5 10.6 9.3 17.2 20.9 20.2 34.6 1.2 5.6 1.4 11.2 1.2 16.9-.1 2.3.2 2.5 2.5 2.7 5.8.3 11.7.7 17.5 1 .5 0 1-.1 1.5.7-35.7 6.5-70.8 15.4-105.9 24.6 19.4-23.2 38.3-46.8 56.9-70.8-4.1 0-63.3 12.6-68.8 14.7 15.9-.5 31.5-1.9 47.1-3.2.3.7-.2 1-.5 1.2-10 10.5-19.6 21.4-29.2 32.2-10 11.4-20 22.9-29.8 34.5-1.4 1.7-1.7 1.7-3.1 0-9-10.3-14.2-22.4-15.3-36-1.3-17.1 3.3-32.5 14.3-45.8 10.5-12.7 24-20.2 40.3-22.7 2.6-.4 5.2-.5 7.9-.8.7.1 1.3.1 2 .1z"
                className="st0"
                style={{
                  fill: "url(#linearGradient36)",
                }}
              />
            </g>
            <path
              id="path29"
              d="M-37.28 119.3c2.9-.9 5.3-1.6 7.8-2.3 20.7-6.1 41.3-12.4 61.8-19 14.4-4.7 28.8-9.6 43-14.8.6-.2 1.2-.7 1.7-.3.6.5 0 1.3-.2 1.8-9.4 24.4-27 38.7-53.1 42.3-12.6 1.7-24.7-.7-36.1-6.4-.9-.5-1.9-.5-2.9-.5-6.6-.1-13.2-.2-19.9-.3-.4-.1-1.1 0-2.1-.5z"
              className="st1"
            />
          </g>
          <g id="g35" transform="translate(-317.55 137.221)">
            <g id="g37" transform="translate(250.795 -133.36)">
              <path
                id="path30"
                d="M500 98.1c-3.7 0-7.4 0-11.1.1-1.1 0-1.6-.4-1.6-1.5.1-1.4 0-2.8 0-4.7-3 3.6-6.6 5.6-10.8 6.3-7.1 1.3-14 1.1-20.3-3-5.1-3.3-7.6-8.2-7.2-14.3.5-6.8 4.2-11.3 10.5-13.7 4.1-1.5 8.4-1.8 12.7-1.9 4.1-.1 8.2-.1 12.3 0 1.4 0 1.8-.4 1.8-1.8-.1-5.5-4.1-9.7-9.9-10.3-5-.6-9.9-.2-14.9-.3h-6.8c-1.1 0-1.6-.4-1.6-1.6v-8.1c0-.9.3-1.4 1.4-1.4 8.7.2 17.4-.6 26 .4 7.7.9 14 4.3 17.3 11.9 1.1 2.5 1.7 5.1 1.9 7.8 0 .6 0 1.2.3 1.8v34.3zm-13.6-19.8v-1.6c0-2.3 0-2.3-2.3-2.3H473c-1.8 0-3.6.2-5.4.7-3.3.9-5.2 3-5.4 5.9-.2 3.3 1.4 6 4.7 7.1 5.1 1.8 10 1.3 14.6-1.5 3.3-1.9 5.4-4.6 4.9-8.3z"
                className="st1"
              />
              <path
                id="path31"
                d="M285.7 48.4c.8 0 1.1-.5 1.4-.8 7.9-7.2 22.6-7.8 31.1-1.3 1.2.9 2.4 2 3.2 3.3.8 1.2 1.2 1 2.1.1 8-8.7 22.8-9.7 31.4-5.3 5.1 2.6 8.3 6.7 9.9 12 .9 3 1.3 6.1 1.3 9.3v30.4c0 2.1-.1 2.2-2.1 2.2-3.1 0-6.2-.1-9.3 0-1.5 0-2-.5-2-2 .1-9.1 0-18.3 0-27.4 0-2.4-.2-4.8-.9-7.2-1.3-4.5-4.2-7.1-8.8-7.9-8.6-1.5-15.7 3.9-16.7 12.6-.5 4.2-.2 8.4-.3 12.5-.1 5.7 0 11.5 0 17.2 0 2.2 0 2.2-2.2 2.2-3 0-6-.1-8.9 0-1.7.1-2.2-.5-2.2-2.2.1-9.7 0-19.4 0-29.1 0-1.4-.2-2.9-.5-4.3-1.7-7.5-7.3-10.2-14.9-8.9-6.7 1.2-10.9 6.4-11 13.7-.1 9.3-.1 18.6-.1 27.9 0 2.8 0 2.8-2.7 2.8h-8.4c-2.4 0-2.4 0-2.4-2.4V44.2c-.1-1.2.4-1.8 1.7-1.8 3.1.1 6.3.1 9.4 0 1.4 0 1.9.6 1.8 1.9 0 1.4.1 2.8.1 4.1z"
                className="st1"
              />
              <path
                id="path32"
                d="M395.7 48.8c3.6-3.3 7.3-5.5 11.7-6.4 18-3.4 34 7.7 34.3 27.1.1 8.3-2.2 15.8-8.3 21.7-4.7 4.7-10.5 7-17.1 7.5-5.4.4-10.7-.2-15.6-2.8-2-1.1-3.7-2.6-5.5-4.2-.5 1.5-.3 2.9-.2 4.3.1 1.5-.4 2.2-2 2.1-2.9-.1-5.7-.1-8.6 0-1.6.1-2.1-.5-2.1-2.1.1-10.5 0-20.9 0-31.4V23c0-2.3 0-2.3 2.3-2.3 3 0 6.1.1 9.1 0 1.5 0 2 .5 2 2-.1 7.9 0 15.9 0 23.8v2.3zm0 21.4c0 .8-.1 1.5 0 2.3.7 6.6 3.8 11.6 10.1 13.9 6.3 2.3 12.3 1.5 17.4-3.3 4.2-3.9 5.1-8.9 4.9-14.3-.3-10-9.1-17.1-19-15.3-8.4 1.4-13.4 7.7-13.4 16.7z"
                className="st1"
              />
              <path
                id="path33"
                d="M194 86.3c.6.4 1.2.2 1.8.2h28.5c1.6 0 2.3.4 2.2 2.1-.1 2.4 0 4.7 0 7.1 0 2.5 0 2.5-2.4 2.5h-47.2c-1.3 0-1.8-.4-1.8-1.7.1-2.1.1-4.2 0-6.3 0-1.1.5-1.9 1.2-2.6 5.8-6.7 11.5-13.3 17.3-20 3.6-4.2 7.2-8.3 10.8-12.5.3-.4.9-.7.7-1.2-.2-.5-.9-.3-1.4-.3h-25.8c-2.4 0-2.4 0-2.4-2.5v-7.4c0-1.2.4-1.8 1.7-1.8h45.4c1.2 0 1.7.4 1.7 1.6-.1 2.1 0 4.3 0 6.4 0 1-.4 1.7-1 2.4-9.3 10.7-18.6 21.5-27.9 32.2-.6.6-1.2 1-1.4 1.8z"
                className="st1"
              />
              <path
                id="path34"
                d="M256.2 70.4V96c0 2.1-.1 2.2-2.3 2.2h-8.8c-2.3 0-2.3 0-2.3-2.3V54.6c0-3.4.1-6.8 0-10.2 0-1.5.5-1.9 1.9-1.9 3.2.1 6.4.1 9.6 0 1.5 0 1.9.6 1.9 2v25.9z"
                className="st1"
              />
              <path
                id="path35"
                d="M242.2 28.2c0-4 3.3-7.3 7.2-7.3 4.1 0 7.5 3.4 7.5 7.4s-3.5 7.4-7.4 7.3c-4.1-.1-7.3-3.4-7.3-7.4z"
                className="st1"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="g49" transform="translate(-519.816 349.62)">
        <g id="g48" transform="translate(389.753 -288.916)">
          <g id="g41">
            <path
              id="path40"
              d="M10.307 0c2.1.6 4.4.3 6.6.6 13.1 1.7 24.7 6.8 34.6 15.5 10.6 9.3 17.2 20.9 20.2 34.6 1.2 5.6 1.4 11.2 1.2 16.9-.1 2.3.2 2.5 2.5 2.7 5.8.3 11.7.7 17.5 1 .5 0 1-.1 1.5.7-35.7 6.5-70.8 15.4-105.9 24.6 19.4-23.2 38.3-46.8 56.9-70.8-4.1 0-63.3 12.6-68.8 14.7 15.9-.5 31.5-1.9 47.1-3.2.3.7-.2 1-.5 1.2-10 10.5-19.6 21.4-29.2 32.2-10 11.4-20 22.9-29.8 34.5-1.4 1.7-1.7 1.7-3.1 0-9-10.3-14.2-22.4-15.3-36-1.3-17.1 3.3-32.5 14.3-45.8 10.5-12.7 24-20.2 40.3-22.7 2.6-.4 5.2-.5 7.9-.8.7.1 1.3.1 2 .1z"
              className="st0"
              style={{
                fill: "#fff",
                fillOpacity: 1,
              }}
            />
            <path
              id="path41"
              d="M-37.28 119.3c2.9-.9 5.3-1.6 7.8-2.3 20.7-6.1 41.3-12.4 61.8-19 14.4-4.7 28.8-9.6 43-14.8.6-.2 1.2-.7 1.7-.3.6.5 0 1.3-.2 1.8-9.4 24.4-27 38.7-53.1 42.3-12.6 1.7-24.7-.7-36.1-6.4-.9-.5-1.9-.5-2.9-.5-6.6-.1-13.2-.2-19.9-.3-.4-.1-1.1 0-2.1-.5z"
              className="st1"
              style={{
                fill: "#fff",
                fillOpacity: 1,
              }}
            />
          </g>
          <g
            id="g47"
            style={{
              fill: "#fff",
              fillOpacity: 1,
            }}
            transform="translate(-67.156 3.862)"
          >
            <path
              id="path42"
              d="M500 98.1c-3.7 0-7.4 0-11.1.1-1.1 0-1.6-.4-1.6-1.5.1-1.4 0-2.8 0-4.7-3 3.6-6.6 5.6-10.8 6.3-7.1 1.3-14 1.1-20.3-3-5.1-3.3-7.6-8.2-7.2-14.3.5-6.8 4.2-11.3 10.5-13.7 4.1-1.5 8.4-1.8 12.7-1.9 4.1-.1 8.2-.1 12.3 0 1.4 0 1.8-.4 1.8-1.8-.1-5.5-4.1-9.7-9.9-10.3-5-.6-9.9-.2-14.9-.3h-6.8c-1.1 0-1.6-.4-1.6-1.6v-8.1c0-.9.3-1.4 1.4-1.4 8.7.2 17.4-.6 26 .4 7.7.9 14 4.3 17.3 11.9 1.1 2.5 1.7 5.1 1.9 7.8 0 .6 0 1.2.3 1.8v34.3zm-13.6-19.8v-1.6c0-2.3 0-2.3-2.3-2.3H473c-1.8 0-3.6.2-5.4.7-3.3.9-5.2 3-5.4 5.9-.2 3.3 1.4 6 4.7 7.1 5.1 1.8 10 1.3 14.6-1.5 3.3-1.9 5.4-4.6 4.9-8.3z"
              className="st1"
              style={{
                fill: "#fff",
                fillOpacity: 1,
              }}
            />
            <path
              id="path43"
              d="M285.7 48.4c.8 0 1.1-.5 1.4-.8 7.9-7.2 22.6-7.8 31.1-1.3 1.2.9 2.4 2 3.2 3.3.8 1.2 1.2 1 2.1.1 8-8.7 22.8-9.7 31.4-5.3 5.1 2.6 8.3 6.7 9.9 12 .9 3 1.3 6.1 1.3 9.3v30.4c0 2.1-.1 2.2-2.1 2.2-3.1 0-6.2-.1-9.3 0-1.5 0-2-.5-2-2 .1-9.1 0-18.3 0-27.4 0-2.4-.2-4.8-.9-7.2-1.3-4.5-4.2-7.1-8.8-7.9-8.6-1.5-15.7 3.9-16.7 12.6-.5 4.2-.2 8.4-.3 12.5-.1 5.7 0 11.5 0 17.2 0 2.2 0 2.2-2.2 2.2-3 0-6-.1-8.9 0-1.7.1-2.2-.5-2.2-2.2.1-9.7 0-19.4 0-29.1 0-1.4-.2-2.9-.5-4.3-1.7-7.5-7.3-10.2-14.9-8.9-6.7 1.2-10.9 6.4-11 13.7-.1 9.3-.1 18.6-.1 27.9 0 2.8 0 2.8-2.7 2.8h-8.4c-2.4 0-2.4 0-2.4-2.4V44.2c-.1-1.2.4-1.8 1.7-1.8 3.1.1 6.3.1 9.4 0 1.4 0 1.9.6 1.8 1.9 0 1.4.1 2.8.1 4.1z"
              className="st1"
              style={{
                fill: "#fff",
                fillOpacity: 1,
              }}
            />
            <path
              id="path44"
              d="M395.7 48.8c3.6-3.3 7.3-5.5 11.7-6.4 18-3.4 34 7.7 34.3 27.1.1 8.3-2.2 15.8-8.3 21.7-4.7 4.7-10.5 7-17.1 7.5-5.4.4-10.7-.2-15.6-2.8-2-1.1-3.7-2.6-5.5-4.2-.5 1.5-.3 2.9-.2 4.3.1 1.5-.4 2.2-2 2.1-2.9-.1-5.7-.1-8.6 0-1.6.1-2.1-.5-2.1-2.1.1-10.5 0-20.9 0-31.4V23c0-2.3 0-2.3 2.3-2.3 3 0 6.1.1 9.1 0 1.5 0 2 .5 2 2-.1 7.9 0 15.9 0 23.8v2.3zm0 21.4c0 .8-.1 1.5 0 2.3.7 6.6 3.8 11.6 10.1 13.9 6.3 2.3 12.3 1.5 17.4-3.3 4.2-3.9 5.1-8.9 4.9-14.3-.3-10-9.1-17.1-19-15.3-8.4 1.4-13.4 7.7-13.4 16.7z"
              className="st1"
              style={{
                fill: "#fff",
                fillOpacity: 1,
              }}
            />
            <path
              id="path45"
              d="M194 86.3c.6.4 1.2.2 1.8.2h28.5c1.6 0 2.3.4 2.2 2.1-.1 2.4 0 4.7 0 7.1 0 2.5 0 2.5-2.4 2.5h-47.2c-1.3 0-1.8-.4-1.8-1.7.1-2.1.1-4.2 0-6.3 0-1.1.5-1.9 1.2-2.6 5.8-6.7 11.5-13.3 17.3-20 3.6-4.2 7.2-8.3 10.8-12.5.3-.4.9-.7.7-1.2-.2-.5-.9-.3-1.4-.3h-25.8c-2.4 0-2.4 0-2.4-2.5v-7.4c0-1.2.4-1.8 1.7-1.8h45.4c1.2 0 1.7.4 1.7 1.6-.1 2.1 0 4.3 0 6.4 0 1-.4 1.7-1 2.4-9.3 10.7-18.6 21.5-27.9 32.2-.6.6-1.2 1-1.4 1.8z"
              className="st1"
              style={{
                fill: "#fff",
                fillOpacity: 1,
              }}
            />
            <path
              id="path46"
              d="M256.2 70.4V96c0 2.1-.1 2.2-2.3 2.2h-8.8c-2.3 0-2.3 0-2.3-2.3V54.6c0-3.4.1-6.8 0-10.2 0-1.5.5-1.9 1.9-1.9 3.2.1 6.4.1 9.6 0 1.5 0 1.9.6 1.9 2v25.9z"
              className="st1"
              style={{
                fill: "#fff",
                fillOpacity: 1,
              }}
            />
            <path
              id="path47"
              d="M242.2 28.2c0-4 3.3-7.3 7.2-7.3 4.1 0 7.5 3.4 7.5 7.4s-3.5 7.4-7.4 7.3c-4.1-.1-7.3-3.4-7.3-7.4z"
              className="st1"
              style={{
                fill: "#fff",
                fillOpacity: 1,
              }}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default SvgComponent;
