import { Box } from "@mui/material";
import React, { FC } from "react";
import { H4, Paragraph } from "../../components/Typography";
import TaskIcon from "@mui/icons-material/Task";
import moment from "moment";

interface StatusOneInfo {
  taskStatusOne: any;
}

const StatusOne: FC<StatusOneInfo> = ({ taskStatusOne }) => {
  return (
    <Box sx={{ paddingBottom: "0.5rem" }}>
      {taskStatusOne.length > 0 ? (
        taskStatusOne.map((data: any) => {
          return (
            <Box
              sx={{
                border: "1px solid gainsboro",
                borderRadius: "10px",
                marginLeft: "25px",
                marginRight: "25px",
                marginBottom: "1rem",
              }}
              key={`ones${data?.id}`}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 4fr ",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1rem",
                }}
              >
                <Box>
                  <TaskIcon fontSize="small" color="primary" />
                </Box>
                <Box
                  sx={{
                    marginLeft: "-23px",
                  }}
                >
                  <H4 sx={{ fontSize: "12px" }}>{data?.name}</H4>
                  <Box sx={{ fontSize: "11px" }}>
                    {moment(data?.updatedat).calendar()}
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {taskStatusOne.length === 0 && (
            <Paragraph sx={{ fontSize: "11px", marginLeft: "24px" }}>
              No Completed Tasks Yet
            </Paragraph>
          )}
        </Box>
      )}
    </Box>
  );
};

export default StatusOne;
