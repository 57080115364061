import * as React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";

export default function CircularProgressWithLabel(
  props: CircularProgressProps & {
    value: number;
    text: number | React.ReactNode;
    color: string;
    colorNonCovered?: string;
    size?: number;
    thickness?: number;
  },
) {
  const coveredPercentage = Math.min(Math.max(props.value, 0), 100);
  const nonCoveredPercentage = 100;

  const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
    position: "relative",
    display: "inline-block",
    "& .MuiCircularProgress-circle": {
      strokeLinecap: "round",
    },
    "& .MuiCircularProgress-svg": {
      transform: "rotate(-0deg)",
    },
    "&.covered": {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 2,
      color:
        props.color === "secondary"
          ? "white"
          : props.color || "rgba(68, 129, 78, 0.85)",
    },
    "&.nonCovered": {
      position: "relative",
      zIndex: 1,
      color: props.colorNonCovered || "lightGrey",
    },
  }));

  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <StyledCircularProgress
        size={props.size}
        thickness={props.thickness}
        variant="determinate"
        value={coveredPercentage}
        color={props.color}
        className="covered"
      />
      <StyledCircularProgress
        size={props.size}
        variant="determinate"
        value={nonCoveredPercentage}
        className="nonCovered"
        thickness={props.thickness}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color={props.color === "secondary" ? "white" : "black"}
        >
          {props.text}
        </Typography>
      </Box>
    </Box>
  );
}
