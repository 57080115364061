import React, { FC, useEffect } from "react";
import { TextField, InputAdornment, Select, MenuItem } from "@mui/material";
import { ukoTheme } from "../../../theme";
import { IFormTextInputProps } from "./FormTextInput";
import { IFormSelectInputProps } from "./FormSelectInput";
import { IFormField, deepDerefrencer } from "../../../utils/form_factory";
import uniqueId from "../../../utils/generateId";

interface IConcatenatedTextSelectProps extends Partial<IFormField> {
  parentInput: IFormTextInputProps;
  secondaryInput: IFormSelectInputProps;
  formControl: any;
}

const ConcatenatedTextSelect: FC<IConcatenatedTextSelectProps> = ({
  parentInput,
  secondaryInput,
  formControl,
  isDisabled,
  isHidden,
}) => {
  const theme = ukoTheme();

  const formatNumber = (value: string, isPeriod: boolean) => {
    const floatingNumber = value.toString().split(".");
    let decimalPlaces = parentInput.decimalPlaces || 2;

    if (floatingNumber.length > 1) {
      const decimalPart = floatingNumber[1];
      if (decimalPart.length > decimalPlaces) {
        floatingNumber[1] = decimalPart.slice(0, decimalPlaces);
      }
    }

    return floatingNumber[0]
      .toString()
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      .concat(isPeriod ? "." + floatingNumber[1].replace(/\D/g, "") : "");
  };

  const [formattedValue, setFormattedValue] = React.useState<string>("");

  useEffect(() => {
    if (
      parentInput.type === "number" &&
      deepDerefrencer(formControl.values, parentInput.name) !== undefined &&
      deepDerefrencer(formControl.values, parentInput.name) !== null
    ) {
      const periodIndex = deepDerefrencer(formControl.values, parentInput.name)
        .toString()
        .indexOf(".");
      setFormattedValue(
        formatNumber(
          deepDerefrencer(formControl.values, parentInput.name),
          periodIndex > -1,
        ),
      );
    }
  }, [formControl.values, parentInput.name, parentInput.type]);

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const numericInput =
      input.toString().replace(/,/g, "") === ""
        ? ""
        : input.toString().replace(/,/g, "");

    // handle max and min
    if (
      parentInput.max !== undefined &&
      parseFloat(numericInput) > parentInput.max
    ) {
      return;
    }

    if (
      parentInput.min !== undefined &&
      parseFloat(numericInput) < parentInput.min
    ) {
      return;
    }

    // allow only one decimal point
    const decimalCount = (numericInput.match(/\./g) || []).length;
    if (decimalCount > 1) {
      return;
    }

    // handle floating point numbers
    const decimalIndex = numericInput.indexOf(".");
    if (decimalIndex !== -1) {
      const decimalPart = numericInput.slice(decimalIndex + 1);
      if (decimalPart.length > (parentInput.decimalPlaces || 2)) {
        return;
      }
    }

    const floatNumber = numericInput.split(".");

    // sets value in formik and removes non numeric characters
    const rawValue = parseFloat(
      floatNumber[0]
        .replace(/\D/g, "")
        .concat(
          decimalIndex > -1 ? "." + floatNumber[1].replace(/\D/g, "") : "",
        ),
    );
    formControl.setFieldValue(parentInput.name, rawValue);
    formControl.handleChange({
      ...event,
      target: { ...event.target, value: rawValue },
    });

    const formattedInput = formatNumber(numericInput, decimalIndex > -1);
    setFormattedValue(formattedInput);
  };

  return (
    <TextField
      sx={{ ...styles.input(theme) }}
      id={uniqueId()}
      label={parentInput.label}
      variant="outlined"
      placeholder={parentInput.placeHolder}
      name={parentInput.name}
      type={parentInput.type === "number" ? "text" : parentInput.type}
      onChange={
        parentInput.type === "number"
          ? handleNumberChange
          : formControl.handleChange
      }
      value={
        parentInput.type === "number"
          ? formattedValue
          : deepDerefrencer(formControl.values, parentInput.name)
      }
      error={Boolean(
        deepDerefrencer(formControl.touched, parentInput.name) &&
          deepDerefrencer(formControl.errors, parentInput.name),
      )}
      onBlur={formControl.handleBlur}
      disabled={isDisabled}
      hidden={isHidden}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Select
              label={secondaryInput.label}
              variant="outlined"
              displayEmpty
              name={secondaryInput.name}
              notched={undefined}
              id={uniqueId()}
              onChange={formControl.handleChange}
              value={deepDerefrencer(formControl.values, secondaryInput.name)}
              onBlur={formControl.handleBlur}
              disabled={secondaryInput.isDisabled}
            >
              <MenuItem disabled value={""}>
                {secondaryInput.placeHolder}
              </MenuItem>
              {secondaryInput.options?.map((option: any, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={secondaryInput.selector?.value(option)}
                  >
                    {secondaryInput.selector?.label(option)}
                  </MenuItem>
                );
              })}
            </Select>
          </InputAdornment>
        ),
      }}
    />
  );
};

const styles = {
  input: (theme: any, outlineColor?: string) => ({
    width: "100%",
    "& .MuiInputBase-root": {
      width: "100%",
      paddingLeft: "0px !important",

      "& .MuiInputAdornment-root": {
        width: "50%",

        "& .MuiInputBase-root": {
          width: "100%",

          "& .MuiOutlinedInput-notchedOutline": {
            borderTopRightRadius: "0px !important",
            borderBottomRightRadius: "0px !important",
          },
        },

        "& .MuiInputBase-root .MuiSelect-select": {
          width: "100%",
        },
      },

      "& .MuiInputBase-input": {
        width: "50%",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      border: "2px solid",
      borderColor: outlineColor
        ? outlineColor
        : theme.palette.mode === "light"
        ? theme.palette.secondary[300]
        : theme.palette.divider,
    },
  }),
};

export default ConcatenatedTextSelect;
